export const wallTypeDescriptionFilters = [
  "solidWallsWithRender",
  "solidWallsWithRenderAndBrickwork",
  "solidWallsWithRenderAndStonework",
  "solidWallsBrickworkAndOrStonework",
  "solidWallsToPropCavityExtensionRender",
  "solidWallsToPropCavityExtensionRenderAndBrickwork",
  "solidWallsToPropCavityExtensionRenderAndStonework",
  "solidWallsToPropCavityExtensionBrickworkAndOrStonework",
  "cavityWallsWithRender",
  "cavityWallsWithRenderAndBrickwork",
  "cavityWallsWithRenderAndStonework",
  "cavityWallsWithBrickworkAndOrStonework",
  "solidWalls",
  "cavityWalls",
  "solidCavityWalls",
  "mainWallsSolid"
];

export const storeysConfigFilters = [
  'groundFloorSolid',
  'groundFloorCavity',
  'firstFloorSolid',
  'firstFloorCavity',
]

export const ventilationFilters = [
  "solidWallConcreteSlab",
  "solidWallConcreteSlabAndSusTimber",
  "solidWallConcreteSlabAndSusConcrete",
  "solidWallSusTimber",
  "solidWallSusConcrete",
  "solidCavityWallConcreteSlab",
  "solidCavityWallConcreteSlabAndSusTimber",
  "solidCavityWallConcreteSlabAndSusConcrete",
  "solidCavityWallSusTimber",
  "solidCavityWallSusConcrete",
  "cavityWallConcreteSlab",
  "cavityWallConcreteSlabAndSusTimber",
  "cavityWallConcreteSlabAndSusConcrete",
  "cavityWallSusTimber",
  "cavityWallSusConcrete",
  "susFloor",
  "slabFloor"
];

export const feltFilters = ["noFeltPresent", "nonBreathableFelt", "breathableFelt", "feltPresent"];

export const structuralCrackingFilters = [
  "exRenderIntPlaster",
  "exRenderOnly",
  "exMasonryIntPlaster",
  "exMasonry",
  "intPlaster",
];

export const dpcFilters = [
  "feltCavity",
  "feltBitumenCavity",
  "bitumenCavity",
  "olderProperty",
  "olderPropertyBitumen",
  "olderPropertyFelt",
];

export const dpmFilters = [
  "grndSlabOnly",
  "grndSlabSusTimber",
  "grndSlabSusConcrete",
  "susTimberOnly",
  "susConcreteOnly",
];

export const roofConfigurationFilters = [
  'timberTruss',
  'primSecHorizPurlins',
  'raftersHorizPurlins',
  'raftersOnly'
]

export const extStoreysFilters = [
  'firstFloorExt',
  'gfExt',
]

export const nonStructuralItemsFilters = [
  'windows',
  'doors',
  'roofDrainage',
  "joinery",
  "insulation",
  'noWindows'
]

const removeStoreysConfigFilters = (filters) =>
  filters.filter((filter) => !storeysConfigFilters.includes(filter));

const removeNonStructuralItemsFilters = (filters) =>
  filters.filter((filter) => !nonStructuralItemsFilters.includes(filter));

const removeExtStoreysFilters = (filters) =>
  filters.filter((filter) => !extStoreysFilters.includes(filter));

const removeWallDescriptionFilters = (filters) =>
  filters.filter((filter) => !wallTypeDescriptionFilters.includes(filter));

const removeVentilationFilters = (filters) =>
  filters.filter((filter) => !ventilationFilters.includes(filter));

const removeFeltFilters = (filters) =>
  filters.filter((filter) => !feltFilters.includes(filter));

const removeStructuralCrackingFilters = (filters) =>
  filters.filter((filter) => !structuralCrackingFilters.includes(filter));

const removeDpcFilters = (filters) =>
  filters.filter((filter) => !dpcFilters.includes(filter));

const removeDpmFilters = (filters) =>
  filters.filter((filter) => !dpmFilters.includes(filter));

const removeRoofConfigurationFilters = (filters) =>
  filters.filter((filter) => !roofConfigurationFilters.includes(filter));

const removeMaterialFilter = (filters, number) =>
  filters.filter((filter) => !filter.includes("Chimney" + number));

const removeAllMaterialFilters = (filters) =>
  filters.filter((filter) => !filter.includes("Chimney"));

const removeAllChimneyNumberFilters = (filters) =>
  filters.filter((filter) => !filter.includes("chimney")); // && !filter.includes('Chimney')

const removeAllChimneyFilters = (filters) =>
  filters.filter(
    (filter) => !filter.includes("chimney") && !filter.includes("Chimney")
  ); //

const getActiveChimneySectionFilters = (filters) =>
  filters.filter((filter) => filter.includes("Chimney")); //

const getActiveChimneyNumberFilters = (filters) =>
  filters.filter((filter) => filter.includes("chimney")); //

const chimMatFilterKeys = {
  renderedChimney: "Rendered",
  brickworkChimney: "Brickwork",
  brickworkRenderChimney: "Brickwork/Rendered",
  stoneworkChimney: "Stonework",
};

export const numberOfChimneysChanged = (
  filters,
  numberOfChimneys,
  prevNumberOfChimneys,
  chimneyMaterialsAnswers
) => {
  if (numberOfChimneys !== prevNumberOfChimneys) {
    filters = removeAllChimneyFilters(filters); // clear current chiney filters
    const updatedFilters = [];
    for (let i = 0; i < numberOfChimneys; i++) {
      updatedFilters.push("chimney" + (i + 1));
    }
    if (numberOfChimneys > 0) {
      updatedFilters.push('hasChimney');
    } else {
      ////filters = removeAllChimneyFilters(filters);
    }

    if (numberOfChimneys < prevNumberOfChimneys) {
      // console.log("filters before remove: ", filters, prevNumberOfChimneys);
      filters = removeChimneySections(filters, numberOfChimneys);
    } else if (numberOfChimneys > prevNumberOfChimneys) {
      // console.log("filters before add: ", filters, prevNumberOfChimneys);
      filters = addChimneySections(
        filters,
        numberOfChimneys,
        chimneyMaterialsAnswers
      );
    }

    // console.log("filters after remove: ", filters);
    filters.push(...updatedFilters); // move this up to before add/remove?
    // // console.log('chim num filters finish: ', updatedFilters)
  }

  return filters;
};

const addChimneySections = (
  filters,
  numberOfChimneys,
  chimneyMaterialsAnswers
) => {
  for (let i = 0; i < numberOfChimneys; i++) {
    // console.log("get mat answers for new filters: ", chimneyMaterialsAnswers);
    filters = changeChimneyMaterials(
      filters,
      chimneyMaterialsAnswers[i],
      i + 1
    );
  }
  return filters;
};

const removeChimneySections = (filters, numberOfChimneys) => {
  let activeChimneySectionFilters = getActiveChimneySectionFilters(filters);
  filters = removeAllMaterialFilters(filters);
  activeChimneySectionFilters = activeChimneySectionFilters
    ?.filter((filter) => {
      const filterNum = parseInt(filter.charAt(filter.length - 1));
      return filterNum <= numberOfChimneys;
    })
    .filter((v) => !!v);
  filters.push(...activeChimneySectionFilters);
  //// console.log('activeChimneySectionFilters after: ', activeChimneySectionFilters);
  return filters;
};

export const changeChimneyMaterials = (
  filters,
  material,
  chimneyIndex,
  numberOfChimneys
) => {
  // console.log("changeChimneyMaterials: ", material, chimneyIndex);
  // console.log("mat filters before: ", filters);
  filters = removeMaterialFilter(filters, chimneyIndex); // clear current chimney material filters
  // // console.log('mat filters after clear: ', filters)
  switch (material) {
    case "Rendered":
      filters.push("renderedChimney" + chimneyIndex);
      break;

    case "Brickwork":
      filters.push("brickworkChimney" + chimneyIndex);
      break;

    case "Brickwork/Rendered":
      filters.push("brickworkRenderChimney" + chimneyIndex);
      break;

    case "Stonework":
      filters.push("stoneworkChimney" + chimneyIndex);
      break;

    default:
      break;
  }

  return filters;
};

export const changeStoreysConfiguration = (filters) => {
  console.log('change config filters before: ', filters);
  const firstFloor = filters.includes('firstFloor');
  const solidWalls = filters.includes('solidWalls');
  const cavityWalls = filters.includes('cavityWalls');
  const hasExtension = filters.includes('hasExtension');
  const mainWallsSolid = filters.includes('mainWallsSolid');
  const gfExt = filters.includes('gfExt');
  const firstFloorExt = filters.includes('firstFloorExt');

  filters = removeStoreysConfigFilters(filters);
  //const constructionType
  if (solidWalls) {
    filters.push('groundFloorSolid');
  }
  if (cavityWalls) {
    filters.push('groundFloorCavity');
  }
  if (firstFloor) {
    if (solidWalls) {
      filters.push('firstFloorSolid');
    }
    if (firstFloorExt || (cavityWalls && !hasExtension)) {
      filters.push('firstFloorCavity');
    }
  }
  console.log('change config filters after: ', filters);
  return filters;
}

export const changeExtStoreys = (
  filters,
  answer,
) => {

  filters = removeExtStoreysFilters(filters, answer); // clear current chimney material filters
  // // console.log('mat filters after clear: ', filters)
  switch (answer) {
    case "2":
      filters.push("firstFloorExt");
      break;

    default:
      filters.push("gfExt");
      break;
  }

  return changeStoreysConfiguration(filters);
};

export const changeStoreys = (
  filters,
  answer,
) => {

  filters = filters.filter(filter => filter !== 'firstFloor'); // clear current chimney material filters
  // // console.log('mat filters after clear: ', filters)
  switch (answer) {
    case "2":
    case "3":
      filters.push("firstFloor");
      break;

    default:
      break;
  }

  return changeStoreysConfiguration(filters);
};

/*


*/
export const changeWallDescription = (filters, answer) => {//SOLID WALLS THROUGHOUT WITH BRICKWORK AND/ OR STONEWORK FINISHES
  filters = removeWallDescriptionFilters(filters);
  switch (answer) {
    case "SOLID WALLS THROUGHOUT WITH RENDERED FINISHES ONLY":
      filters.push("solidWallsWithRender", "solidWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS THROUGHOUT WITH RENDERED AND BRICKWORK FINISHES":
      filters.push("solidWallsWithRenderAndBrickwork", "solidWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS THROUGHOUT WITH RENDERED AND STONEWORK FINISHES":
      filters.push("solidWallsWithRenderAndStonework", "solidWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS THROUGHOUT WITH BRICKWORK AND/OR STONEWORK FINISHES":
      filters.push("solidWallsBrickworkAndOrStonework", "solidWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH RENDERED FINISHES ONLY":
      filters.push("solidWallsToPropCavityExtensionRender", "solidWalls", "cavityWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH RENDERED AND BRICKWORK FINISHES":
      filters.push("solidWallsToPropCavityExtensionRenderAndBrickwork", "solidWalls", "cavityWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH RENDERED AND STONEWORK FINISHES":
      filters.push("solidWallsToPropCavityExtensionRenderAndStonework", "solidWalls", "cavityWalls", "mainWallsSolid");
      break;

    case "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH BRICKWORK AND/OR STONEWORK FINISHES":
      filters.push("solidWallsToPropCavityExtensionBrickworkAndOrStonework", "solidWalls", "cavityWalls", "mainWallsSolid");
      break;

    case "CAVITY WALLS THROUGHOUT WITH RENDERED FINISHES ONLY":
      filters.push("cavityWallsWithRender", "cavityWalls");
      break;
    case "CAVITY WALLS THROUGHOUT WITH RENDERED AND BRICKWORK FINISHES":
      filters.push("cavityWallsWithRenderAndBrickwork", "cavityWalls");
      break;

    case "CAVITY WALLS THROUGHOUT WITH RENDERED AND STONEWORK FINISHES":
      filters.push("cavityWallsWithRenderAndStonework", "cavityWalls");
      break;

    case "CAVITY WALLS THROUGHOUT WITH BRICKWORK AND/OR STONEWORK FINISHES":
      filters.push("cavityWallsWithBrickworkAndOrStonework");
      break;

    default:
      break;
  }

  return changeStoreysConfiguration(filters);
};
/*
["AIR BRICKS TO BASE OF THE SOLID WALL > CONCRETE GROUND SLAB THROUGHOUT",
"AIR BRICKS TO BASE OF THE SOLID WALL > CONCRETE GROUND SLAB AND SUSPENDED TIMBER FLOOR",
"AIR BRICKS TO BASE OF THE SOLID WALL > CONCRETE GROUND SLAB AND SUSPENDED CONCRETE FLOOR",
"AIR BRICKS TO BASE OF THE SOLID WALL > SUSPENDED TIMBER FLOOR THROUGHOUT",
"AIR BRICKS TO BASE OF THE SOLID WALL > SUSPENDED CONCRETE FLOOR THROUGHOUT",
"AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > CONCRETE GROUND SLAB THROUGHOUT",
"AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED TIMBER FLOOR",
"AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED CONCRETE FLOOR",
"AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > SUSPENDED TIMBER FLOOR THROUGHOUT",
"AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > SUSPENDED CONCRETE FLOOR THROUGHOUT",
"AIR BRICKS TO BASE OF THE CAVITY WALL > CONCRETE GROUND SLAB THROUGHOUT",
"AIR BRICKS TO BASE OF THE CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED TIMBER FLOOR",
"AIR BRICKS TO BASE OF THE CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED CONCRETE FLOOR",
"AIR BRICKS TO BASE OF THE CAVITY WALL > SUSPENDED TIMBER FLOOR THROUGHOUT",
"AIR BRICKS TO BASE OF THE CAVITY WALL > SUSPENDED CONCRETE FLOOR THROUGHOUT"]


*/

export const changeVentilationDescription = (filters, answer) => {
  filters = removeVentilationFilters(filters);
  switch (answer) {
    case "AIR BRICKS TO BASE OF THE SOLID WALL > CONCRETE GROUND SLAB THROUGHOUT":
      filters.push("solidWallConcreteSlab", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID WALL > CONCRETE GROUND SLAB AND SUSPENDED TIMBER FLOOR":
      filters.push("solidWallConcreteSlabAndSusTimber", "susFloor", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID WALL > CONCRETE GROUND SLAB AND SUSPENDED CONCRETE FLOOR":
      filters.push("solidWallConcreteSlabAndSusConcrete", "susFloor", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID WALL > SUSPENDED TIMBER FLOOR THROUGHOUT":
      filters.push("solidWallSusTimber", "susFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID WALL > SUSPENDED CONCRETE FLOOR THROUGHOUT":
      filters.push("solidWallSusConcrete", "susFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > CONCRETE GROUND SLAB THROUGHOUT":
      filters.push("solidCavityWallConcreteSlab", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED TIMBER FLOOR":
      filters.push("solidCavityWallConcreteSlabAndSusTimber", "susFloor", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED CONCRETE FLOOR":
      filters.push("solidCavityWallConcreteSlabAndSusConcrete", "susFloor", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > SUSPENDED TIMBER FLOOR THROUGHOUT":
      filters.push("solidCavityWallSusTimber", "susFloor");
      break;

    case "AIR BRICKS TO BASE OF THE SOLID/CAVITY WALL > SUSPENDED CONCRETE FLOOR THROUGHOUT":
      filters.push("solidCavityWallSusConcrete", "susFloor");
      break;

    case "AIR BRICKS TO BASE OF THE CAVITY WALL > CONCRETE GROUND SLAB THROUGHOUT":
      filters.push("cavityWallConcreteSlab", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED TIMBER FLOOR":
      filters.push("cavityWallConcreteSlabAndSusTimber", "susFloor", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE CAVITY WALL > CONCRETE GROUND SLAB AND SUSPENDED CONCRETE FLOOR":
      filters.push("cavityWallConcreteSlabAndSusConcrete", "susFloor", "slabFloor");
      break;

    case "AIR BRICKS TO BASE OF THE CAVITY WALL > SUSPENDED TIMBER FLOOR THROUGHOUT":
      filters.push("cavityWallSusTimber", "susFloor");
      break;

    case "AIR BRICKS TO BASE OF THE CAVITY WALL > SUSPENDED CONCRETE FLOOR THROUGHOUT":
      filters.push("cavityWallSusConcrete", "susFloor");
      break;

    default:
      break;
  }
  return filters;
};

export const changeFeltType = (filters, answer) => {
  filters = removeFeltFilters(filters);
  switch (answer) {
    case "No roofing felt is present below tiles":
      filters.push("noFeltPresent");
      break;
    case "Non-breathable roofing felt is present below tiles":
      filters.push("nonBreathableFelt", "feltPresent");
      break;
    case "Breathable roofing felt is present below tiles":
      filters.push("breathableFelt", "feltPresent");
      break;
    default:
      break;
  }
  return filters;
};

export const changeStructuralCrackingType = (filters, answer) => {
  filters = removeStructuralCrackingFilters(filters);
  switch (answer) {
    case "STRUCTURAL CRACKING > EXTERNAL RENDER AND INTERNAL PLASTER":
      filters.push("exRenderIntPlaster");
      break;
    case "STRUCTURAL CRACKING > EXTERNAL RENDER ONLY":
      filters.push("exRenderOnly");
      break;
    case "STRUCTURAL CRACKING > EXTERNAL MASONRY AND INTERNAL PLASTER":
      filters.push("exMasonryIntPlaster");
      break;
    case "STRUCTURAL CRACKING > EXTERNAL MASONRY ONLY":
      filters.push("exMasonry");
      break;
    case "STRUCTURAL CRACKING > INTERNAL PLASTER ONLY":
      filters.push("intPlaster");
      break;
    default:
      break;
  }
  return filters;
};

export const changeDpcType = (filters, answer) => {
  filters = removeDpcFilters(filters);
  switch (answer) {
    case "REINFORCED FELT THROUGHOUT (FOR PROPERTIES LESS THAN 20 YEARS OLD - CAVITY)":
      filters.push("feltCavity");
      break;
    case "REINFORCED FELT (PROPERTY < 20 YEARS OLD - CAVITY) AND FLEXIBLE BITUMEN (EXTENSION / PROPERTY BETWEEN 1900-2000 - CAVITY)":
      filters.push("feltBitumenCavity");
      break;
    case "FLEXIBLE BITUMEN (PROPERTY BETWEEN 1900-2000 - CAVITY) THROUGHOUT":
      filters.push("bitumenCavity");
      break;
    case "OLDER PROPERTY ONLY (> 100 YEARS OLD)":
      filters.push("olderProperty");
      break;
    case "OLDER PROPERTY (>100 YEARS OLD) AND FLEXIBLE BITUMEN (EXTENSION / PROPERTY BETWEEN 1900-2000 - CAVITY)":
      filters.push("olderPropertyBitumen");
      break;
    case "OLDER PROPERTY (>100 YEARS OLD) AND REINFORCED FELT (EXTENSION / PROPERTY < 20 YEARS OLD - CAVITY)":
      filters.push("olderPropertyFelt");
      break;
    default:
      break;
  }
  return filters;
};

export const changeDpmType = (filters, answer) => {
  filters = removeDpmFilters(filters);
  switch (answer) {
    case "GROUND BEARING SLAB ONLY":
      filters.push("grndSlabOnly");
      break;
    case "GROUND BEARING SLAB AND SUSPENDED TIMBER GROUND FLOOR":
      filters.push("grndSlabSusTimber");
      break;
    case "GROUND BEARING SLAB AND SUSPENDED CONCRETE GROUND FLOOR":
      filters.push("grndSlabSusConcrete");
      break;
    case "SUSPENDED TIMBER GROUND FLOOR ONLY":
      filters.push("susTimberOnly");
      break;
    case "SUSPENDED CONCRETE GROUND FLOOR ONLY":
      filters.push("susConcreteOnly");
      break;
    default:
      break;
  }
  return filters;
};


export const changeRoofConfigurationFilters = (filters, answer) => {
  filters = removeRoofConfigurationFilters(filters);
  switch (answer) {
    case 'TIMBER TRUSS FRAMEWORKS':
      filters.push("timberTruss");
      break;
    case 'PRIMARY/SECONDARY RAFTERS AND HORIZONTAL PURLINS':
      filters.push("primSecHorizPurlins");
      break;
    case 'RAFTERS AND HORIZONTAL PURLINS':
      filters.push("raftersHorizPurlins");
      break;
    case 'RAFTERS ONLY (NO PURLINS VISIBLE)':
      filters.push("raftersOnly");
      break;
    default:
      break;
  }

  return filters;
}

export const changeNonStructuralItems = (filters, answer) => {
  filters = removeNonStructuralItemsFilters(filters);

  if (answer.includes("Windows and doors")) {
    filters.push("windows");
  } else {
    filters.push("noWindows")
  }
  if (answer.includes("Insulation")) {
    filters.push("insulation");
  }
  if (answer.includes("Roof drainage, downpipes, guttering")) {
    filters.push("roofDrainage");
  }
  if (answer.includes("Internal joinery")) {
    filters.push("joinery");
  }
  
  return filters;
};

/*
export const nonStructuralItemsFilters = [
  'windows',
  'doors',
  'roofDrainage',
  "joinery",
                    "Windows and doors",
                    "Roof drainage, downpipes, guttering",
                    "Internal joinery",
]
'swrVertDiagCracks'
'swrBrickVertDiagCracks'
'swrStoneVertDiagCracks'
'swBrickStoneVertDiagCracks'
'swToPropCavityExtensionVert'
'swToPropCavityExtensionRenderBrickVert'
'swToPropCavityExtensionRenderStoneVert'

'swToPropCavityExtensionBrickOrStoneVert'

'swCavityRenderVert'

'swCavityRenderBrickVert'
'swCavityRenderStoneVert'
'swCavityBrickStoneVert'
*/