import React, { Component, useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { AppContext } from "../../context/context";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
const { Stack, Accordion, ButtonGroup, Button } = require("react-bootstrap");

const propertyText = {
  propertyType: "Property type",
  otherType: "Property type",
  numberOfFloors: "Number of floors",
  groundFloorLayout: "Ground floor layout",
  otherGf: "Ground floor layout",
  firstFloorLayout: "First floor layout", //?
  otherFf: "First floor layout",
  ageOfProperty: "Age of property",
  orientationOfProperty: "Property orientation",
  hasExtensions: "Extensions",
  extensionStoreys: "Extension storeys",
  extensionLocation: "Extension location",
  roofStructure: "Roof structure",
  pitchRoofType: "Pitch roof type",
  hippedRoofType: "Hipped roof type",
  roofPitch: "Roof pitch",
  mainRoofTiles: "Main roof tiles",
  roofFelt: "Roof felt",
  extensionRoofCovering: "Extension roof covering",
  extensionRoofType: "Extension type",
  mainWallConstruction: "Main wall construction",
  solidRenderDetails: "Solid render details",
  solidWallsDetails: "Solid walls details",
  extensionWallConstruction: "Extension wall construction",
  externalWallFinishes: "External wall finish",
  extensionExternalWallFinish: "Extension wall finish",
  externalWallTypeAndFinishDescription:
    "Wall construction and finish desription",
  groundFloorConstruction: "Ground floor construction",
  otherFloor: "Other floor construction type",
  fasciaBoardMaterial: "Fascia boad material",
  hasSoffit: "Soffit",
  soffitMaterial: "Soffit material",
  windowDoorFrames: "Window and door frames",
  insulation: "Insulation",
  // hasChimney: 'Chimney',
  numberChimneys: "Number of chimneys",
  chimneyConstruction1: "Chimney construction 1",
  chimneyConstruction2: "Chimney construction 2",
  chimneyConstruction3: "Chimney construction 3",
  chimneyConstruction4: "Chimney construction 4",
  chimneyConstruction5: "Chimney construction 5",
  foundations: "Foundations",
  internalWallFinish: "Internal wall finish",
};

let requiredFields = [
  // may need to move this to state...or have a rebuilder function
  "propertyType",
  "numberOfFloors",
  "groundFloorLayout",
  "ageOfProperty",
  "orientationOfProperty",
  "hasExtensions",
  "roofStructure",
  "roofPitch",
  "mainRoofTiles",
  "roofFelt",
  // 'mainWallConstruction',
  // 'externalWallFinishes',
  "externalWallTypeAndFinishDescription",
  "groundFloorConstruction",
  "fasciaBoardMaterial",
  "hasSoffit",
  "windowDoorFrames",
  "insulation",
  // 'hasChimney',
  "foundations",
  "numberChimneys",
  "internalWallFinish",
];

const dynamicFields = {
  numberOfFloors: [
    "firstFloorLayout", //?
  ],
  hasExtensions: {
    Yes: [
      "extensionStoreys",
      "extensionLocation",
      "extensionRoofCovering",
      "extensionRoofType",
      "extensionWallConstruction",
      "extensionExternalWallFinish",
    ],
  },
  groundFloorConstruction: {
    Other: ["otherFloor"],
  },
  numberChimneys: {
    1: ["chimneyConstruction1"],
    2: ["chimneyConstruction1", "chimneyConstruction2"],
    3: ["chimneyConstruction1", "chimneyConstruction2", "chimneyConstruction3"],
    4: [
      "chimneyConstruction1",
      "chimneyConstruction2",
      "chimneyConstruction3",
      "chimneyConstruction4",
    ],
    5: [
      "chimneyConstruction1",
      "chimneyConstruction2",
      "chimneyConstruction3",
      "chimneyConstruction4",
      "chimneyConstruction5",
    ],
  },
  hasSoffit: {
    Yes: ["soffitMaterial"],
  },
  roofStructure: {
    "Pitch Roof": ["pitchRoofType"],
    "Hipped Roof": ["hippedRoofType"],
  },
  propertyType: {
    "Other:": ["otherType"],
  },
  groundFloorLayout: {
    "Other:": ["otherGf"],
  },
  firstFloorLayout: {
    "Other:": ["otherFf"],
  },
  // mainWallConstruction: {
  //   "Solid with render": ['solidRenderDetails'],
  //   "Solid": ['solidWallsDetails'],
  // },
};

const arrayQuestions = [
  "groundFloorLayout",
  "soffitMaterial",
  "firstFloorLayout",
  "fasciaBoardMaterial",
  "windowDoorFrames",
];

const defaultState = () => {
  return Object.keys(propertyText)
    .map((item) =>
      !arrayQuestions.includes(item) ? { [item]: "" } : { [item]: [] }
    )
    .reduce((acc, val) => ({ ...acc, ...val }), {});
};

const PropertyDetailsForm = () => {
  const [state, dispatch] = useContext(AppContext);
  const [values, setValues] = useState(
    state.sections["Property Details"].formValues || defaultState()
  );
  const [formerrors, setFormErrors] = useState({});
  const [prevVal, setPrevVal] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const getChimneyMaterialQuestions = () => {
    let qs = [];
    for (let i = 0; i < parseInt(values.numberChimneys); i++) {
      qs.push(i);
    }
    return qs;
  };
  const chimneyMaterialQuestions = getChimneyMaterialQuestions();
  const clearChimneyMaterialValues = (max) => {
    //Object.keys(values).forEach(item => !item[0] <= max ? values[item] = null : )
  };

  useEffect(() => {
    //// // console.log(values);
  }, [values]);

  const updateRequiredFields = (event) => {
    const { name, value } = event.target;
    if (Object.hasOwn(dynamicFields, name)) {
      // if field has dynamic dependents
      const updateReqFields = () => {
        if (Object.hasOwn(dynamicFields[name], value)) {
          // if the new value triggers dependent fields
          requiredFields.push(...dynamicFields[name][value]);
        } else {
          const fieldsToRemove = Object.values(dynamicFields[name]).reduce(
            (acc, val) => [...acc, ...val],
            []
          );
          requiredFields = requiredFields.filter(
            (item) => !fieldsToRemove.includes(item)
          );
        }
      };
      switch (name) {
        case "propertyType":
          updateReqFields();
          if (value !== "Other") {
            setFormErrors((formerrors) => ({
              ...formerrors,
              otherType: null,
            }));
          } else {
          }
          break;

        case "otherType":
          updateReqFields();
          if (value) {
            setFormErrors((formerrors) => ({
              ...formerrors,
              otherType: null,
            }));
          }

          break;

        case "groundFloorConstruction":
          if (value.includes("Other") && !requiredFields.includes("otherFloor")) {
            requiredFields.push("otherFloor");
          } else if (!value.includes("Other")) {
            setFormErrors((formerrors) => ({
              ...formerrors,
              otherFloor: null,
            }));
            requiredFields = requiredFields.filter(
              (item) => item !== "otherFloor"
            );
            values.otherFloor = "";
          }
          break;

        case "otherFloor":
          updateReqFields();
          if (value) {
            setFormErrors((formerrors) => ({
              ...formerrors,
              otherFloor: null,
            }));
          }
          break;

        case "groundFloorLayout":
          if (value.includes("Other") && !requiredFields.includes("otherGf")) {
            requiredFields.push("otherGf");
          } else if (!value.includes("Other")) {
            setFormErrors((formerrors) => ({
              ...formerrors,
              otherGf: null,
            }));
            requiredFields = requiredFields.filter(
              (item) => item !== "otherGf"
            );
          }
          break;

        case "firstFloorLayout":
          if (value.includes("Other") && !requiredFields.includes("otherFf")) {
            requiredFields.push("otherFf");
          } else if (!value.includes("Other")) {
            setFormErrors((formerrors) => ({
              ...formerrors,
              otherFf: null,
            }));
            requiredFields = requiredFields.filter(
              (item) => item !== "otherFf"
            );
          }
          break;

        case "numberOfFloors":
          if (value > 1) {
            requiredFields.push(...dynamicFields[name]);
          } else {
            requiredFields = requiredFields.filter(
              (item) => !dynamicFields[name].includes(item)
            );
          }
          if (value < 2) {
            setFormErrors((formerrors) => ({
              ...formerrors,
              firstFloorLayout: null,
            }));
          }
          break;

        case "hasExtensions":
          updateReqFields();
          if (value === "No") {
            // const errors = dynamicFields[name]['Yes'].map(item => ({[item]: null })).reduce((acc, val) => ({ ...acc, ...val }), {});
            setFormErrors((formerrors) => ({
              ...formerrors,
              extensionStoreys: null,
              extensionLocation: null,
              extensionRoofCovering: null,
              extensionRoofType: null,
              extensionWallConstruction: null,
              extensionExternalWallFinish: null,
            }));
          }
          break;

        case "hasSoffit":
          updateReqFields();
          if (value === "No") {
            setFormErrors((formerrors) => ({
              ...formerrors,
              soffitMaterial: null,
            }));
          }
          break;

        case "numberChimneys":
          const errorsToRemove = {};
          for (let i = 0; i < 5; i++) {
            if (i + 1 > value) {
              errorsToRemove[`chimneyConstruction${i + 1}`] = null;
            }
          }
          updateReqFields();
          setFormErrors((formerrors) => ({
            ...formerrors,
            ...errorsToRemove,
          }));
          break;

        case "mainWallConstruction":
          updateReqFields();
          if (value !== "Solid") {
            setFormErrors((formerrors) => ({
              ...formerrors,
              solidWallsDetails: null,
            }));
          }
          if (value !== "Solid with render") {
            setFormErrors((formerrors) => ({
              ...formerrors,
              solidRenderDetails: null,
            }));
          }
          break;

        case "roofStructure":
          updateReqFields();
          if (value !== "Pitch Roof") {
            setFormErrors((formerrors) => ({
              ...formerrors,
              pitchRoofType: null,
            }));
          }
          if (value !== "Hipped Roof") {
            setFormErrors((formerrors) => ({
              ...formerrors,
              hippedRoofType: null,
            }));
          }
          break;

        default:
          break;
      }
    }
    // console.log('updated required fields: ', requiredFields);
  };

  const validateField = (event) => {
    // move this inside handleChange
    const { name, value } = event.target;
    setFormErrors((formerrors) => ({
      ...formerrors,
      [name]: value?.length ? null : propertyText[name] + " is required",
    }));
    updateRequiredFields(event);
  };

  const handleChange = (event) => {
    console.log('set activekey: ', event.target.name);
    const { name, value } = event.target;
    setPrevVal(values[name]);
    setActiveKey(name);
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
    validateField({ target: { name, value } });
  };

  const handleMultiSelectChange = (name, value) => {
    // if (name === 'firstFloorLayout') {
    //   if (value.includes('3 Bedrooms')) {
    //     value = value.filter(val => val !== '1 Bedroom' && val !== '2 Bedrooms')
    //   } else if (value.includes('2 Bedrooms')) {
    //     value = value.filter(val => val !== '1 Bedroom')
    //   }
    // }
    const event = { target: { name, value } };
    handleChange(event);
  };

  useEffect(() => {
    // replace this with linear onChange logic chain.  Put end of updateRequiredFields?
    // console.log(values)
    const isWholeFormValid = () => {
      let valid = true;
      requiredFields.forEach((field) => {
        if (!values[field]?.length) {
          valid = false;
        }
      });

      return valid;
    };
    const getChimneyMaterialAnswers = () => {
      const answers = Object.keys(values)
        .map((key) =>
          key.includes("chimneyConstruction") ? values[key] : null
        )
        .filter((v) => !!v);
      //// console.log('answers: ', answers)
      return answers;
    };
    const pdCompletion = () => {
      const totalFields = requiredFields.length;
      const filledFields =
        Object.entries(values)?.filter((item) => !!item[1]?.length)?.length ||
        0;
      return { totalFields, filledFields };
    };
    const valid = isWholeFormValid();
    //const activeValues = Object.keys(values)?.filter(key => !!requiredFields.includes(key)).map(key => values[key]).reduce((acc, val) => ({...acc, ...val}),{});
    //// console.log('av:', activeValues)
    const action = {
      type: "UPDATE_PROPERTY_DETAILS",
      payload: {
        data: {
          formValues: values,
          formIsValid: valid,
          complete: valid,
          completion: pdCompletion(),
          filterFor: activeKey,
        },
        prevVal: prevVal,
        chimneyMaterials: getChimneyMaterialAnswers(),
      },
    };
    dispatch(action);
    setActiveKey(null);
    setPrevVal(null);
    return () => {};
  }, [activeKey, dispatch, prevVal, values]);

  useEffect(() => {
    // console.log('change active key: ', activeKey);
  }, [activeKey]);

  // legacy
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    //checkValidation();
  };

  return (
    <>
      <Stack>
        <Container className="ms-0 me-5">
          <Form onSubmit={handleSubmit} className="me-5">
            <div className="">
              <h3 className="mb-4 mt-3">Property Type</h3>
            </div>
            <Form.Group className="mb-5 ms-4" controlId="form.propertyType">
              <Form.Label>Select property type</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.propertyType}
                value={values.propertyType}
                as="select"
                name="propertyType"
              >
                <option value=""></option>
                <option value="Detached">Detached</option>
                <option value="Semi-detached">Semi-detached</option>
                <option value="Mid-Terrace">Mid-Terrace</option>
                <option value="End Terrace">End Terrace</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            {values.propertyType === "Other" && (
              <Form.Group className="mb-5 ms-4" controlId="form.otherType">
                <Form.Label>Please specify the type of property</Form.Label>
                <Form.Control
                  isInvalid={formerrors.otherType}
                  name="otherType"
                  onChange={handleChange}
                  value={values?.otherType}
                  type="text"
                />
              </Form.Group>
            )}

            <h3 className="mb-4 mt-3">Floors</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.numberOfFloors">
              <Form.Label>Number of floors</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.numberOfFloors}
                value={values.numberOfFloors}
                as="select"
                name="numberOfFloors"
              >
                <option value=""></option>
                <option value="1">Single Storey</option>
                <option value="2">Double Storey</option>
                <option value="3">Three Storey</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              className="mb-5 ms-4"
              controlId="form.groundFloorLayout"
            >
              <Form.Label>Ground floor layout</Form.Label>
              <DropdownMultiselect
                selected={values.groundFloorLayout}
                buttonClass={
                  formerrors.groundFloorLayout
                    ? "dropdown-invalid btn-light"
                    : "btn-light"
                }
                handleOnChange={(event) =>
                  handleMultiSelectChange("groundFloorLayout", event)
                }
                options={[
                  "Living Room",
                  "Dining Room",
                  "Sitting Room",
                  "Open Plan Living / Dining Room",
                  "Kitchen",
                  "Kitchen / Diner",
                  "Kitchen / Breakfast Room",
                  "Bathroom",
                  "Utility",
                  "Shower-room",
                  "W/C",
                  "Bedroom",
                  "Other",
                ]}
                name="groundFloorLayout"
              />
            </Form.Group>

            {values.groundFloorLayout.includes("Other") && (
              <Form.Group className="mb-5 ms-4" controlId="form.otherGf">
                <Form.Label>
                  Please specify 'Other' ground floor room
                </Form.Label>
                <Form.Control
                  isInvalid={formerrors.otherGf}
                  name="otherGf"
                  onChange={handleChange}
                  value={values?.otherGf}
                  type="text"
                />
              </Form.Group>
            )}

            {parseInt(values.numberOfFloors) > 1 && (
              <Form.Group
                className="mb-5 ms-4"
                controlId="form.firstFloorLayout"
              >
                <Form.Label>First floor layout</Form.Label>
                <DropdownMultiselect
                  buttonClass={
                    formerrors.firstFloorLayout
                      ? "dropdown-invalid btn-light"
                      : "btn-light"
                  }
                  handleOnChange={(event) =>
                    handleMultiSelectChange("firstFloorLayout", event)
                  }
                  options={[
                    "1 Bedroom",
                    "2 Bedrooms",
                    "3 Bedrooms",
                    "4 Bedrooms",
                    "5 Bedrooms",
                    "En-suite(s)",
                    "Bathroom",
                    "Shower-room",
                    "W/C",
                    "Other",
                  ]}
                  name="firstFloorLayout"
                  selected={values.firstFloorLayout}
                />
              </Form.Group>
            )}
            {parseInt(values.numberOfFloors) > 1 &&
              values.firstFloorLayout.includes("Other") && (
                <Form.Group className="mb-5 ms-4" controlId="form.otherFf">
                  <Form.Label>
                    Please specify 'Other' first floor room
                  </Form.Label>
                  <Form.Control
                    isInvalid={formerrors.otherFf}
                    name="otherFf"
                    onChange={handleChange}
                    value={values?.otherFf}
                    type="text"
                  />
                </Form.Group>
              )}

            <h3 className="mb-4 mt-3">Age of Property</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.ageOfProperty">
              <Form.Label>Select estimated age of property</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.ageOfProperty}
                value={values.ageOfProperty}
                as="select"
                name="ageOfProperty"
              >
                {[
                  "",
                  "Mid Victorian 1857-1877",
                  "Late Victorian - 1877-1901",
                  "1901 - 1910",
                  "1911 - 1920",
                  "1921 - 1930",
                  "1931 - 1940",
                  "1941 - 1950",
                  "1951 - 1960",
                  "1961 - 1970",
                  "1971 - 1980",
                  "1981 - 1990",
                  "1991 - 2000",
                  "2001 - 2010",
                  "2011 - 2021",
                  "2021 - 2030",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Orientation</h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.orientationOfProperty"
            >
              <Form.Label>Select property orientation</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.orientationOfProperty}
                value={values.orientationOfProperty}
                as="select"
                name="orientationOfProperty"
              >
                <option value=""></option>
                <option value="North">North</option>
                <option value="North-East">North-East</option>
                <option value="East">East</option>
                <option value="South-East">South-East</option>
                <option value="South">South</option>
                <option value="South-West">South-West</option>
                <option value="West">West</option>
                <option value="North-West">North-West</option>
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Extensions</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.hasExtensions">
              <Form.Label>Does the property have extensions?</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.hasExtensions}
                value={values.hasExtensions}
                as="select"
                name="hasExtensions"
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>

            {values.hasExtensions === "Yes" && (
              <>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.extensionStoreys"
                >
                  <Form.Label>Extension storeys</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    isInvalid={formerrors.extensionStoreys}
                    value={values.extensionStoreys}
                    as="select"
                    name="extensionStoreys"
                  >
                    <option value=""></option>
                    <option value="1">Single Storey</option>
                    <option value="2">Double Storey</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.extensionLocation"
                >
                  <Form.Label>Extension location</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    isInvalid={formerrors.extensionLocation}
                    value={values.extensionLocation}
                    as="select"
                    name="extensionLocation"
                  >
                    <option value=""></option>
                    <option value="Front">Front</option>
                    <option value="Side">Side</option>
                    <option value="Rear">Rear</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                </Form.Group>
              </>
            )}

            <h3 className="mb-4 mt-3">Main Roof Structure</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.roofStructure">
              <Form.Label>Select type of roof</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.roofStructure}
                value={values.roofStructure}
                as="select"
                name="roofStructure"
              >
                <option value=""></option>
                {[
                  "Pitch Roof",
                  "Hipped Roof",
                  "Saltbox",
                  "Flat Roof",
                  "Mono-pitch (shed) roof",
                  "'M' Shaped Roof",
                  "Mansard Roof",
                  "Gambrel Roof",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {values.roofStructure === "Pitch Roof" && (
              <Form.Group className="mb-5 ms-4" controlId="form.pitchRoofType">
                <Form.Label>Pitch roof type</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  isInvalid={formerrors.pitchRoofType}
                  value={values.pitchRoofType}
                  as="select"
                  name="pitchRoofType"
                >
                  <option value=""></option>
                  {[
                    "Continuous pitch roof profile",
                    "Open gable (at one end) and continuous pitch roof with the neighbouring property",
                    "Open Gable Roof Profile",
                    "Cross pitch roof profile",
                    "Multiple cross pitch roof profile",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {values.roofStructure === "Hipped Roof" && (
              <Form.Group className="mb-5 ms-4" controlId="form.hippedRoofType">
                <Form.Label>Hipped roof type</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  isInvalid={formerrors.hippedRoofType}
                  value={values.hippedRoofType}
                  as="select"
                  name="hippedRoofType"
                >
                  <option value=""></option>
                  {[
                    "Hip and valley roof profile",
                    "Continuous hipped roof profile",
                    "Cross Hipped Roof",
                  ].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            <h3 className="mb-4 mt-3">Roof Pitch</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.roofPitch">
              <Form.Label>Select angle of pitch</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.roofPitch}
                value={values.roofPitch}
                as="select"
                name="roofPitch"
              >
                <option value=""></option>
                {["30 Degree", "35 Degree", "40 Degree", "Other"].map(
                  (item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                )}
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Main Roof Tiles</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.mainRoofTiles">
              <Form.Label>Select material</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.mainRoofTiles}
                value={values.mainRoofTiles}
                as="select"
                name="mainRoofTiles"
              >
                <option value=""></option>
                {[
                  "Concrete",
                  "Composite concrete",
                  "Slate",
                  "Composite Slate",
                  "Clay",
                  "Thatch",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Roofing Felt</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.roofFelt">
              <Form.Label>Type of felt present</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.roofFelt}
                value={values.roofFelt}
                as="select"
                name="roofFelt"
              >
                <option value=""></option>
                {[
                  "No roofing felt is present below tiles",
                  "Non-breathable roofing felt is present below tiles",
                  "Breathable roofing felt is present below tiles",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {values.hasExtensions === "Yes" && (
              <>
                <h3 className="mb-4 mt-3">Extension Roof</h3>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.extensionRoofType"
                >
                  <Form.Label>Extension roof type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    isInvalid={formerrors.extensionRoofType}
                    value={values.extensionRoofType}
                    as="select"
                    name="extensionRoofType"
                  >
                    <option value=""></option>
                    {["Flat", "Mono-pitch (Shed)", "Other"].map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.extensionRoofCovering"
                >
                  <Form.Label>Extension roof covering</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    isInvalid={formerrors.extensionRoofCovering}
                    value={values.extensionRoofCovering}
                    as="select"
                    name="extensionRoofCovering"
                  >
                    <option value=""></option>
                    {[
                      "Mineral Felt",
                      "GRP / fibreglass",
                      "Corrugated plastic sheeting",
                      "Other",
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}

            {/* <h3 className="mb-4 mt-3">Main Wall Construction</h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.mainWallConstruction"
            >
              <Form.Label>Select material</Form.Label>
              <Form.Control
                onChange={handleChange}
                
                isInvalid={formerrors.mainWallConstruction}
                value={values.mainWallConstruction}
                as="select"
                name="mainWallConstruction"
              >
                <option value=""></option>
                {[
                  "Solid",
                  "Solid with render",
                  "Cavity Brick/Brick",
                  "Cavity Block/Block with render",
                  "Timber frame",
                  "Steel frame",
                  "Precast Reinforced Concrete",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {values.mainWallConstruction === "Solid with render" && (
              <>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.solidRenderDetails"
                >
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    
                    isInvalid={formerrors.solidRenderDetails}
                    value={values.solidRenderDetails}
                    as="select"
                    name="solidRenderDetails"
                  >
                    <option value=""></option>
                    {[
                      "Brickwork to the external wall leaf and blockwork to the internal wall leaf to form the load-bearing walls",
                      "Brickwork to the internal and external wall leafes to form the load-bearing walls",
                      "Blockwork to the internal and external wall leaves to form the load-bearing walls",
                      "Other",
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}
            {values.mainWallConstruction === "Solid" && (
              <>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.solidWallsDetails"
                >
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    
                    isInvalid={formerrors.solidWallsDetails}
                    value={values.solidWallsDetails}
                    as="select"
                    name="solidWallsDetails"
                  >
                    <option value=""></option>
                    {[
                      "Irregular stonework masonry units to form the load-bearing walls",
                      "Other",
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )} */}
            {/*             
            <h3 className="mb-4 mt-3">Main Property External Wall Finishes</h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.externalWallFinishes"
            >
              <Form.Label>Select wall finish</Form.Label>
              <Form.Control
                onChange={handleChange}
                
                isInvalid={formerrors.externalWallFinishes}
                value={values.externalWallFinishes}
                as="select"
                name="externalWallFinishes"
              >
                <option value=""></option>
                {[
                  "Full Rendered",
                  "Part Rendered and part exposed brickworks",
                  "Exposed brickworks",
                  "Natural stonework",
                  "UPVC cladding",
                  "Tiled",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {values.hasExtensions === "Yes" && (
              <>
                <h3 className="mb-4 mt-3">Extension Wall Construction</h3>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.extensionWallConstruction"
                >
                  <Form.Label>Select type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    
                    isInvalid={formerrors.extensionWallConstruction}
                    value={values.extensionWallConstruction}
                    as="select"
                    name="extensionWallConstruction"
                  >
                    <option value=""></option>
                    {[
                      "Cavity Wall construction",
                      "Single block / skin wall construction",
                      "Other",
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}

           
            {values.hasExtensions === "Yes" && (
              <>
                <h3 className="mb-4 mt-3">Extension External Wall Finishes</h3>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.extensionExternalWallFinish"
                >
                  <Form.Label>Select wall finish</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    
                    isInvalid={formerrors.extensionExternalWallFinish}
                    value={values.extensionExternalWallFinish}
                    as="select"
                    name="extensionExternalWallFinish"
                  >
                    <option value=""></option>
                    {[
                      "Full Rendered",
                      "Part Rendered and part exposed brickworks",
                      "Exposed brickworks",
                      "Natural stonework",
                      "UPVC cladding",
                      "Tiled",
                      "Other",
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )} */}

            <h3 className="mb-4 mt-3">
              Wall Construction and Finish Description
            </h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.externalWallTypeAndFinishDescription"
            >
              <Form.Label>Select description</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.externalWallTypeAndFinishDescription}
                value={values.externalWallTypeAndFinishDescription}
                as="select"
                name="externalWallTypeAndFinishDescription"
              >
                <option value=""></option>
                {[
                  "SOLID WALLS THROUGHOUT WITH RENDERED FINISHES ONLY",
                  "SOLID WALLS THROUGHOUT WITH RENDERED AND BRICKWORK FINISHES",
                  "SOLID WALLS THROUGHOUT WITH RENDERED AND STONEWORK FINISHES",
                  "SOLID WALLS THROUGHOUT WITH BRICKWORK AND/OR STONEWORK FINISHES",
                  "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH RENDERED FINISHES ONLY",
                  "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH RENDERED AND BRICKWORK FINISHES",
                  "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH RENDERED AND STONEWORK FINISHES",
                  "SOLID WALLS (TO ORIGINAL PROPERTY), CAVITY WALL (TO EXTENSION) WITH BRICKWORK AND/OR STONEWORK FINISHES",
                  "CAVITY WALLS THROUGHOUT WITH RENDERED FINISHES ONLY",
                  "CAVITY WALLS THROUGHOUT WITH RENDERED AND BRICKWORK FINISHES",
                  "CAVITY WALLS THROUGHOUT WITH RENDERED AND STONEWORK FINISHES",
                  "CAVITY WALLS THROUGHOUT WITH BRICKWORK AND/OR STONEWORK FINISHES",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Ground Floor Construction</h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.groundFloorConstruction"
            >
              <Form.Label>Select type</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.groundFloorConstruction}
                value={values.groundFloorConstruction}
                as="select"
                name="groundFloorConstruction"
              >
                <option value=""></option>
                {[
                  "Concrete ground floor slabs",
                  "Suspended timber ground floors",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {values.groundFloorConstruction === "Other" && (
              <Form.Group className="mb-5 ms-4" controlId="form.otherFloor">
                <Form.Label>
                  Please specify the type of floor construction
                </Form.Label>
                <Form.Control
                  isInvalid={formerrors.otherFloor}
                  name="otherFloor"
                  onChange={handleChange}
                  value={values?.otherFloor}
                  type="text"
                />
              </Form.Group>
            )}

            <h3 className="mb-4 mt-3">Fascia Board Material</h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.fasciaBoardMaterial"
            >
              <Form.Label>Select all that apply</Form.Label>
              <DropdownMultiselect
                buttonClass={
                  formerrors.fasciaBoardMaterial
                    ? "dropdown-invalid btn-light"
                    : "btn-light"
                }
                handleOnChange={(event) =>
                  handleMultiSelectChange("fasciaBoardMaterial", event)
                }
                options={["UPVC", "Timber", "Other"]}
                name="fasciaBoardMaterial"
                selected={values.fasciaBoardMaterial}
              />
            </Form.Group>

            <h3 className="mb-4 mt-3">Soffit Board</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.hasSoffit">
              <Form.Label>Does the property have soffit boards?</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.hasSoffit}
                value={values.hasSoffit}
                as="select"
                name="hasSoffit"
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>

            {values.hasSoffit === "Yes" && (
              <>
                <Form.Group
                  className="mb-5 ms-4"
                  controlId="form.soffitMaterial"
                >
                  <Form.Label>
                    Select all soffit board materials that apply
                  </Form.Label>
                  <DropdownMultiselect
                    className={
                      formerrors.soffitMaterial
                        ? "dropdown-invalid btn-light"
                        : "btn-light"
                    }
                    handleOnChange={(event) =>
                      handleMultiSelectChange("soffitMaterial", event)
                    }
                    options={[
                      "UPVC",
                      "Timber",
                      "Cement / Asbestos panels",
                      "Other",
                    ]}
                    name="soffitMaterial"
                    selected={values.soffitMaterial}
                  />
                </Form.Group>
              </>
            )}

            <h3 className="mb-4 mt-3">Window And Door Frames</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.windowDoorFrames">
              <Form.Label>Select all materials that apply</Form.Label>
              <DropdownMultiselect
                className={
                  formerrors.windowDoorFrames
                    ? "dropdown-invalid btn-light"
                    : "btn-light"
                }
                handleOnChange={(event) =>
                  handleMultiSelectChange("windowDoorFrames", event)
                }
                options={["UPVC", "Timber Framed", "Aluminium Framed", "Other"]}
                name="windowDoorFrames"
                selected={values.windowDoorFrames}
              />
            </Form.Group>

            <h3 className="mb-4 mt-3">Insulation</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.insulation">
              <Form.Label>Loft insulation</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.insulation}
                value={values.insulation}
                as="select"
                name="insulation"
              >
                <option value=""></option>
                {[
                  "Insulation was present in the loft space",
                  "No insulation was present in the loft space",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Chimney(s)</h3>
            {/* <Form.Group className="mb-5 ms-4" controlId="form.hasChimney">
              <Form.Label>Chimney(s) present?</Form.Label>
              <Form.Control
                onChange={handleChange}
                
                isInvalid={formerrors.hasChimney}
                value={values.hasChimney}
                as="select"
                name="hasChimney"
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group> */}

            <Form.Group className="mb-5 ms-4" controlId="form.numberChimneys">
              <Form.Label>Number of chimneys</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.numberChimneys}
                value={values.numberChimneys}
                as="select"
                name="numberChimneys"
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            {chimneyMaterialQuestions.map((i) => {
              return (
                <Form.Group
                  key={i}
                  className="mb-5 ms-4"
                  controlId="form.chimneyConstruction"
                >
                  <Form.Label>{`Chimney ${i + 1} construction`}</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    isInvalid={formerrors[`chimneyConstruction${i + 1}`]}
                    value={values[`chimneyConstruction${i + 1}`]}
                    as="select"
                    name={`chimneyConstruction${i + 1}`}
                  >
                    <option value=""></option>
                    {[
                      "Brickwork",
                      "Rendered",
                      "Brickwork/Rendered",
                      "Stonework",
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              );
            })}

            <h3 className="mb-4 mt-3">Foundations</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.foundations">
              <Form.Label>Select type</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.foundations}
                value={values.foundations}
                as="select"
                name="foundations"
              >
                <option value=""></option>
                {[
                  "Conventional concrete strip",
                  "Stone filled trench",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <h3 className="mb-4 mt-3">Internal Wall Finishes</h3>
            <Form.Group
              className="mb-5 ms-4"
              controlId="form.internalWallFinish"
            >
              <Form.Label>Select finish</Form.Label>
              <Form.Control
                onChange={handleChange}
                isInvalid={formerrors.internalWallFinish}
                value={values.internalWallFinish}
                as="select"
                name="internalWallFinish"
              >
                <option value=""></option>
                {[
                  "Plaster-board and skim IF property post dates 1930",
                  "Lathe and plaster ceiling and solid plaster wall finish IF property pre-dates 1930",
                  "Other",
                ].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/*ption 1 Pitch Roof,"Continuous pitch roof profile", "Open gable (at one end) and continuous pitch roof with the neighbouring property", "Open Gable Roof Profile", "Cross pitch roof profile", "Multiple cross pitch roof profile"   */}
            {/* <h3 className="mb-4 mt-3">Property Type</h3> */}
            {/* <Form.Group className="mb-5 ms-4" controlId="form.groundFloorLayout">
              <Form.Label>Ground floor layout</Form.Label>
              <Form.Control
                multiple
                onChange={handleChange}
                value={values.groundFloorLayout}
                as="select"
                name="groundFloorLayout"
              >
                <option value=""></option>
                <option value="Living Room">Living Room</option>
                <option value="Dining Room">Dining Room</option>
                <option value="Sitting Room">Sitting Room</option>
                <option value="Open Plan Living / Dining Room">Open Plan Living / Dining Room</option>
                <option value="Kitchen">Kitchen</option>
                <option value="Kitchen / Diner">Kitchen / Diner</option>
                <option value="Kitchen / Breakfast Room">Kitchen / Breakfast Room</option>
                <option value="Bathroom">Bathroom</option>
                <option value="Utility">Utility</option>
                <option value="Shower-room">Shower-room</option>
                <option value="W/C">W/C</option>
                <option value="Bedroom">Bedroom</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group> */}
            {/* {values.propertyType === 'Detached' &&(
              <Form.Group className="mb-5 ms-4" controlId="form.test">
              <Form.Label>Select property type</Form.Label>
              <Form.Control
                onChange={handleChange}
                value={values.test}
                as="select"
              >
                <option value=""></option>
                <option value="Detached">Detached</option>
                <option value="Semi-detached">Semi-detached</option>
                <option value="Mid-Terrace">Mid-Terrace</option>
                <option value="End Terrace">End Terrace</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group> 
            )}*/}

            {/* <Button className="mb-3" type="submit" variant="outline-primary" size="lg">
              Validate
            </Button> */}
            {formerrors &&
              Object.values(formerrors).map((error, index) => (
                <p key={index} className="mt-2 text-warning-field">
                  {error}
                </p>
              ))}
          </Form>
        </Container>
      </Stack>
    </>
    //     </Accordion.Body>
    //   </Accordion.Item>
    // </Accordion>
  );
};
export default PropertyDetailsForm;
