import { useContext, useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../context/context";
import {
  Flag,
  FlagFill,
  Camera,
  CameraFill,
  InfoCircle,
  Trash,
  Plus,
} from "react-bootstrap-icons";
import { saveLog } from "../service/storage.service";
import {isEqual} from "lodash";

const defaultConditionText = [
  "No repair is currently needed.",
  "Defects that need repairing or replacing but are not considered to be serious or urgent. The property must be maintained in the normal way.",
  "Defects which are considered to be more urgent and requiring remedial works in a more timely manner.",
  "Not inspected",
];

const Question = ({
  type,
  filterFor,
  map,
  options,
  answer,
  pictureTaken,
  pictureCaption,
  flag,
  note,
  CR,
  MRT,
}) => {
  const [state, dispatch] = useContext(AppContext);
  const { section, subsection, buildingItem, defect } = map;
  const [showReportModal, setShowReportModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [noteText, setNoteText] = useState(note);
  const [showPicModal, setShowPicModal] = useState(false);
  const [pics, setPics] = useState(pictureCaption || []);
  // const [multiOptionVals, setMultiOptionVals] = useState(answer);
  const [picText, setPicText] = useState(pictureCaption || []);
  const [initialPicsValue, setInitialPicsValue] = useState(
    pictureCaption || []
  );
  const handleCloseReport = () => {
    setShowReportModal(false);
  };
  const handleCloseNote = () => {
    setShowNoteModal(false);
  };
  const handleClosePic = () => {
    setPics(pictureCaption || []);
    setShowPicModal(false);
  };
  const handleShowReport = () => setShowReportModal(true);
  const handleShowNote = () => setShowNoteModal(true);
  const handleShowPic = () => {
    //setPics(pictureCaption);
    setShowPicModal(true)
  };
  const getPreviousValue = () =>
    state.sections[map.section].subsections[map.subsection].buildingItems[
      map.buildingItem
    ].defects[map.defect].answer;

  const getChimneyMaterialAnswers = () => {
    const keys = [1, 2, 3, 4, 5].map((num) => `Chimney ${num} Construction`);
    const answers = keys.map((key) => [
      key,
      state.sections["External Roof"].subsections["Chimneys"].buildingItems[
        "Chimney Materials"
      ].defects[key].answer,
    ]);

    answers.reduce((acc, val) => ({ ...acc, [val[0]]: val[1] }), {});
    // console.log('answers: ', answers)
    return answers;
  };

  const onChange = (event) => {
    const action = {
      type: "UPDATE_QUESTION",
      payload: {
        data: {
          answer: event.target.value,
          complete: !!event.target.value,
          filterFor,
        },
        map,
        prevVal: getPreviousValue(),
      },
    };
    if (filterFor === "numberOfChimneys") {
      action.payload.chimneyMaterials = getChimneyMaterialAnswers();
    }
    dispatch(action);
    saveLog(action);
    // console.log(event.target.value);
  };

  const onMultiChange = (event) => {
    // console.log(event);
    let currVal = answer?.length ? answer : [];
    if (currVal.includes(event.target.value) && !event.target.checked) {
      currVal = currVal.filter((val) => val !== event.target.value);
    } else if (!currVal.includes(event.target.value) && event.target.checked) {
      currVal.push(event.target.value);
    }

    currVal = currVal.length ? currVal : null;

    const synEvent = {
      target: {
        value: currVal,
      },
    };

    onChange(synEvent);
    //setMultiOptionVals(currVal);
  };

  const onChangeNote = (event) => {
    setNoteText(event.target.value);
  };

  const onChangeCaption = (event) => {
    setPicText(event.target.value);
  };

  const handleAction = (updatedProp) => {
    const action = {
      type: "UPDATE_QUESTION",
      payload: { data: updatedProp, map },
    };
    dispatch(action);
    saveLog(action);
  };

  const handleFlag = () => {
    handleAction({ flag: !flag, note: noteText });
    setShowNoteModal(false);
  };
  const handlePic = () => {
    handleAction({ pictureTaken: !!pics?.length, pictureCaption: pics });
    setShowPicModal(false);
  };

  const addPic = () => {
    const newIndex = pics.length + 1;
    const newPic = "Picture " + newIndex;
    setPics((pics) => [...pics, newPic]);
    //handleAction({ pictureCaption: pics });
  };

  const removePic = (index) => {
    const newPics = pics.filter((pic, i) => i !== index);
    setPics(newPics);
  };

  const handleChangePicCaption = (event, index) => {
    console.log("changePicCaption: ", event, index);
    const newPics = [...pics];
    newPics[index] = event.target.value;
    console.log("newPics: ", newPics);
    setPics(newPics);
  };

  const picsHaveChanged = () => {
    if (initialPicsValue?.length !== pics?.length) {
      return true;
    }
    return !isEqual(initialPicsValue, pics);
  };

  // useEffect(() => {
  //   console.log("pics: ", pics);
  //   console.log("initialPics: ", initialPicsValue);
  //   console.log('changed?', picsHaveChanged())
  // }, [initialPicsValue, pics]);

  const conditionText = defaultConditionText.map((text, i) =>
    !!CR[i] ? CR[i] : text
  );

  const ratingQuestion = () => {
    return (
      <Form onChange={onChange}>
        <Form.Label className="mt-4">
          Select condition rating
          <InfoCircle
            size={20}
            onClick={handleShowReport}
            className="ms-3 cursor-pointer"
          />
        </Form.Label>
        {conditionText.map((text, i) =>
          text ? (
            <Form.Check
              className={
                "mt-4 mb-5 me-4 cursor-pointer " +
                (parseInt(answer) === i ? "font-weight-bold" : "")
              }
              key={i}
              name={defect}
              type={"radio"}
              value={i}
              defaultChecked={parseInt(answer) === i}
              id={`default-${section}-${subsection}-${buildingItem}-${defect}-${i}`}
              label={(i < 3 ? `${i + 1}.` : ``) + ` ${text}`}
            />
          ) : null
        )}
      </Form>
    );
  };

  const multiSelectQuestion = () => {
    return (
      <Form onChange={onMultiChange}>
        <Form.Label className="mt-4">
          Select all that apply
          <InfoCircle
            size={20}
            onClick={handleShowReport}
            className="ms-3 cursor-pointer"
          />
        </Form.Label>
        {options.map((text, i) =>
          text ? (
            <Form.Check
              className={
                "mt-4 mb-5 me-4 cursor-pointer " +
                (!!answer?.includes(text) ? "font-weight-bold" : "")
              }
              key={i}
              name={defect}
              type={"checkbox"}
              value={text}
              defaultChecked={!!answer?.includes(text)}
              id={`default-${section}-${subsection}-${buildingItem}-${defect}-${i}`}
              label={`${text}`}
            />
          ) : null
        )}
      </Form>
    );
  };

  const yesNoQuestion = () => {
    return (
      <Form onChange={onChange}>
        {["No", "Yes"].map((text, i) => (
          <Form.Check
            className={
              "mt-4 mb-5 me-4 cursor-pointer " +
              (answer === text ? "font-weight-bold" : "")
            }
            key={i}
            name={defect}
            type={"radio"}
            value={text}
            defaultChecked={answer === text}
            id={`default-${section}-${subsection}-${buildingItem}-${defect}-${i}`}
            label={!!CR[i + 1] ? CR[i + 1] : `${text}`} //${secondary}
          />
        ))}
      </Form>
    );
  };

  const freeTextQuestion = () => {
    return (
      <Form className="me-3 mb-5" onChange={onChange}>
        <Form.Label htmlFor="inputPassword5"></Form.Label>
        <Form.Control
          type="comments"
          id={`default-${section}-${subsection}-${buildingItem}-${defect}-ctrl`}
          aria-describedby={`default-${subsection}-${buildingItem}-${defect}`}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          style={{ height: "100px" }}
          defaultValue={answer}
        />
        <Form.Text
          value={answer}
          label={`Please enter your comments`}
          id={`default-${section}-${subsection}-${buildingItem}-${defect}`}
        ></Form.Text>
      </Form>
    );
  };

  const selectQuestion = () => {
    return (
      <Form className="me-5 mb-5" onChange={onChange}>
        <Form.Label>Select</Form.Label>
        <Form.Control
          id={`default-${section}-${subsection}-${buildingItem}-${defect}-ctrl`}
          defaultValue={answer}
          as="select"
          name={`default-${section}-${subsection}-${buildingItem}-${defect}-ctrl`}
        >
          <option value=""></option>
          {options?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </Form.Control>
      </Form>
    );
  };

  const question = () => {
    switch (type) {
      case "rating":
        return ratingQuestion();
      case "yesNo":
        return yesNoQuestion();
      case "freeText":
        return freeTextQuestion();
      case "select":
        return selectQuestion();
      case "multi":
        return multiSelectQuestion();
      default:
        return null;
    }
  };

  return (
    <>
      <Modal show={showReportModal} onHide={handleCloseReport} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>{defect}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {MRT.map((text, i) => {
            return <p key={i}>{`${i + 1}.  ${text ? text : "N/A"}`}</p>;
          })}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseReport}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={showNoteModal} onHide={handleCloseNote} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Flag defect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!flag && (
            <Form className="me-3 mb-5" onChange={onChangeNote}>
              <Form.Label
                htmlFor={`note-${section}-${subsection}-${buildingItem}-${defect}-ctrl`}
              >
                Add a note to the flagged defect
              </Form.Label>
              <Form.Control
                type="comments"
                id={`note-${section}-${subsection}-${buildingItem}-${defect}-ctrl`}
                aria-describedby={`note-${subsection}-${buildingItem}-${defect}`}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                style={{ height: "100px" }}
                defaultValue={noteText}
              />
              <Form.Text
                value={noteText}
                label={`Please enter your comments`}
                id={`note-${subsection}-${buildingItem}-${defect}`}
              ></Form.Text>
            </Form>
          )}
          {flag && (
            <div>
              <p>
                <i>{`"${noteText}"`}</i>
              </p>
              <p></p>
              <p>Remove this flag?</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseNote}>
            Close
          </Button> */}
          <Button
            variant="primary"
            disabled={!flag && !noteText}
            onClick={handleFlag}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPicModal} onHide={handleClosePic} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Add picture placeholders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            Image placeholders with captions will be inserted into the report
            body for this defect.
          </div>
          {pics?.map((text, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-space-between align-items-center mb-3"
              >
                {/* <div className="">{text}</div> */}
                <Form.Control
                  className="me-4"
                  name="pics"
                  isInvalid={!text}
                  onChange={(event) => handleChangePicCaption(event, index)}
                  //onBlur={validateField}
                  value={pics[index]}
                  type="text"
                  placeholder=""
                />
                <Button
                  key={"delete" + index}
                  variant="outline-primary"
                  onClick={() => removePic(index)}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Trash size={20} />
                </Button>
              </div>
            );
          })}
          <Button
            variant="outline-primary"
            onClick={addPic}
            className="d-flex align-items-center justify-content-center mt-3"
          >
            <Plus size={20} />
            Add
          </Button>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClosePic}>
            Close
          </Button> */}
          <Button
            variant="primary"
            //disabled={!picsHaveChanged()}
            onClick={handlePic}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex w-100 flex-column">
        {question()}
        <div className="d-flex w-100 justify-content-between pe-3 mt-3 mb-4">
          <div
            onClick={() => handleShowPic()}
            className={
              "d-flex action-btn-wrap camera p-2 me-3 " +
              (pictureTaken ? "active" : "")
            }
          >
            <div className="camera-button">
              {!!pictureTaken && (
                <CameraFill size={30} className={"camerafill-icon"} />
              )}
              {!pictureTaken && <Camera size={30} className={"camera-icon"} />}
            </div>
          </div>
          <div
            onClick={() => handleShowNote()}
            className={
              "d-flex action-btn-wrap flag p-2  " + (flag ? "active" : "")
            }
          >
            <div className="flag-button">
              {flag && <FlagFill size={25} className={"camerafill-icon"} />}

              {!flag && <Flag size={25} className={"camera-icon"} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;

// need rating options, pic taken checkbox/icon, flag option;
