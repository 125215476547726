import React, { Component, useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { AppContext } from "../../context/context";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
const { Stack, Accordion, ButtonGroup, Button } = require("react-bootstrap");

const propertyText = {
  propertyAddress1: "Property address line 1",
  propertyAddress2: "Property address line 2",
  propertyCity: "Property city",
  propertyState: "Property state",
  // propertyCountry: "Property country",
  propertyPostcode: "Property postcode",
  clientName: "Client name",
  clientAddress1: "Client address line 1",
  clientAddress2: "Client address line 2",
  clientCity: "Client city",
  clientCountry: "Client country",
  clientState: "Client state",
  clientPostcode: "Client postcode",
  occupancy: "Occupancy",
  agentName: "Agent name",
  otherAgent: "Agent name",
  buyersSolicitor: "Buyer's solicitor",
  buyersSolicitorNotDisclosed: '',
  surveyType: "Survey type",
  dateOfInspection: "Date of inspection",
  weather: "Weather",
  otherWeather: "Weather",
  engineer: "Engineer name",
  reviewedBy: "Reviewer",
  signed: "Signature",
  nsi: "Non-Structural Items"
}

export const allFieldsNull = () => {
  return Object.keys(propertyText)
    .map(item => ({[item]: ''}))
    .reduce((acc, val) => ({ ...acc, ...val }), {});
}

const SurveyDetailsForm = () => {
  const [state, dispatch] = useContext(AppContext);
  const [values, setValues] = useState(state.sections['Survey Details'].formValues || allFieldsNull());
  const [formerrors, setFormErrors] = useState({});
  const [activeKey, setActiveKey] = useState(null);
  // // console.log('values on init', values)
  
  useEffect(()=> {
    //// // console.log('values updated', values)
  }, [values])

  const sdCompletion = () => {
    const totalFields = Object.keys(propertyText).length - 1;
    const filledFields = Object.entries(values)?.filter(item => !!item[1])?.length || 0;
    return {totalFields, filledFields};
  }

  useEffect(() => {
    const valid = isWholeFormValid();
    const action = {
      type: "UPDATE_SURVEY_DETAILS",
      payload: { data: {
        formValues: values,
        formIsValid: valid,
        complete: valid,
        completion: sdCompletion(),
        filterFor: activeKey
      }},
    }
    dispatch(action);
  },[formerrors, values])
  
  const handleChange = (event) => {
    setActiveKey(event.target.name);
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckChange = (event) => {
    const currentVal = !!values.buyersSolicitorNotDisclosed;
    const newVal = !currentVal;
    setValues((values) => ({
      ...values,
      buyersSolicitorNotDisclosed: newVal
    }));
    if (newVal) {
      setFormErrors(formerrors => ({  // updated to function updater
        ...formerrors,
        buyersSolicitor: null
      }))
    } else if (!values.buyersSolicitor) {
      setFormErrors(formerrors => ({
        ...formerrors,
        buyersSolicitor: propertyText['buyersSolicitor'] + ' is required'
      }))
    }
  }

  const validateField = (event) => {
    // console.log('validate field optional:', isOptional(event.target.name))
    if (!isOptional(event.target.name)) {
      setFormErrors(formerrors => ({
        ...formerrors,
        [event.target.name]: event.target.value ? null : propertyText[event.target.name] + ' is required'
      }));
    } else {
      const action = {
        type: "UPDATE_SURVEY_DETAILS",
        payload: { data: {
          formValues: values,
        }},
      }
      dispatch(action);
    }
  }

  const isWholeFormValid = () => {
    let errors = {};
    let hasValues = !!Object.values(values).filter(v => !!v).length;
    if (hasValues) {
      Object.entries(values).forEach((item, index) => {
        if (!values[item[0]] && !isOptional(item[0])) {
          if (
            (item[0] === 'buyersSolicitor' && !values.buyersSolicitorNotDisclosed)
            || item[0] !== 'buyersSolicitor'
          ) {
            errors[item[0]] = propertyText[item[0]] + ' is required';
          } 
        }
      })
      if (Object.keys(errors)?.length === 0 && hasValues) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  };

  const isOptional = (name) => {
    return name.includes('Address2' ) 
    || name.includes("buyersSolicitorNotDisclosed") 
    || name.includes("nsi")
    || (values.weather !== 'Other' && name.includes("otherWeather"))
    || (values.agentName !== 'Other' && name.includes("otherAgent"))
    ;
  }

  // const handleMultiSelectChange = (name, value) => {
  //   // console.log('handleMultiSelectChange:', name, value)
  //   const event = {target: {name, value}};
  //   handleChange(event);
  //   // validateField(event);
  // };

  return (
    <>
      <Stack >
        <Container className="ms-0 mt-4 me-5">
          <Form className="me-5"> {/* onSubmit={handleSubmit} onChange={(event) => null} */}
            <h3 className="mb-3">Property Address</h3>
            <Form.Group className="mb-3 ms-4" controlId="form.propertyAddress1">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control
                onBlur={validateField}
                isInvalid={formerrors.propertyAddress1}
                name="propertyAddress1"
                onChange={handleChange}
                value={values?.propertyAddress1}
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.propertyAddress2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control
                onBlur={validateField}
                name="propertyAddress2"
                onChange={handleChange}
                value={values?.propertyAddress2}
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.propertyCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                onBlur={validateField}
                isInvalid={formerrors.propertyCity}
                name="propertyCity"
                onChange={handleChange}
                value={values?.propertyCity}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.propertyState">
              <Form.Label>State/Region</Form.Label>
              <Form.Control
                onBlur={validateField}
                isInvalid={formerrors.propertyState}
                name="propertyState"
                onChange={handleChange}
                value={values?.propertyState}
                type="text"
              />
            </Form.Group>

            {/* <Form.Group className="mb-3 ms-4" controlId="form.propertyCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                onBlur={validateField}
                isInvalid={formerrors.propertyCountry}
                name="propertyCountry"
                onChange={handleChange}
                value={values?.propertyCountry}
                type="text"
              />
            </Form.Group> */}

            <Form.Group className="mb-5 ms-4" controlId="form.propertyPostcode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                isInvalid={formerrors.propertyPostcode}
                name="propertyPostcode"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.propertyPostcode}
                type="text"
              />
            </Form.Group>

            <h3 className="mb-3">Occupancy</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.occupancy">
              <Form.Label>Select occupancy status</Form.Label>
              <Form.Control
                onBlur={validateField}
                isInvalid={formerrors.occupancy}
                name="occupancy"
                onChange={handleChange}
                value={values?.occupancy}
                as="select"
              >
                <option value=""></option>
                <option value="vacant">Vacant</option>
                <option value="occupied">Occupied</option>
              </Form.Control>
            </Form.Group>

            <h3 className="mb-3">Client Details</h3>
            <Form.Group className="mb-3 ms-4" controlId="form.clientName">
              <Form.Label>Client name</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientName}
                name="clientName"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientName}
                type="text"
                placeholder=""
              />
              {/* {formerrors.clientName && (
                <p className="text-warning-field">{formerrors.clientName}</p>
              )} */}
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.clientAddress1">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientAddress1}
                name="clientAddress1"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientAddress1}
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.clientAddress2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientAddress2}
                name="clientAddress2"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientAddress2}
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.clientCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientCity}
                name="clientCity"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientCity}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.clientState">
              <Form.Label>State/Region</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientState}
                name="clientState"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientState}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-3 ms-4" controlId="form.clientCountry">
              <Form.Label>Country</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientCountry}
                name="clientCountry"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientCountry}
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-5 ms-4" controlId="form.clientPostcode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                isInvalid={formerrors.clientPostcode}
                name="clientPostcode"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.clientPostcode}
                type="text"
              />
            </Form.Group>

            <h3 className="mb-3">Property agent</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.agentName">
              <Form.Label>Agent name</Form.Label>
              <Form.Control
                name="agentName"
                isInvalid={formerrors.agentName}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.agentName}
                as="select"
              >
                {['',
                  'West Wales Properties',
                  'Pinkmove',
                  'Peter Morgan',
                  'Dawsons',
                  'Belvoir',
                  'No 86',
                  'Sullivans',
                  'Calow Evans',
                  'John Francis',
                  'FBM',
                  'Chambers',
                  'Astleys',
                  'Fine & Country',
                  'Anna Ashton',
                  'Brinsons',
                  'Watts & Morgan',
                  'BP2',
                  'North Letherby',
                  'Yopa',
                  'Remax',
                  'Peter Alan',
                  'Other'
                ].map(agent => (
                  <option key={agent} value={agent}>
                    {agent}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {values.agentName === 'Other' && (<Form.Group className="mb-5 ms-4" controlId="form.otherAgent">
              <Form.Label>Please specify the agent name</Form.Label>
              <Form.Control
                isInvalid={formerrors.otherAgent}
                name="otherAgent"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.otherAgent}
                type="text"
                placeholder=""
              />
            </Form.Group>)}

            <h3 className="mb-3">Buyers' solicitor</h3>
            <Form.Group className="mb-3 ms-4" controlId="form.buyersSolicitor">
              <Form.Label>Solicitor name</Form.Label>
              <Form.Control
                name="buyersSolicitor"
                isInvalid={formerrors.buyersSolicitor && !values.buyersSolicitorNotDisclosed}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.buyersSolicitor}
                type="text"
                placeholder=""
              />
            </Form.Group>

            <Form.Group
              className="mb-5 ms-4"
              controlId="form.buyersSolicitorNotDisclosed"
            >
              <Form.Check
                isInvalid={formerrors.buyersSolicitorNotDisclosed}
                className=""
                name="buyersSolicitorNotDisclosed"
                type="checkbox"
                onChange={handleCheckChange}
                defaultChecked={values?.buyersSolicitorNotDisclosed}
                label="Not disclosed"
                value={values?.buyersSolicitorNotDisclosed}
              />
            </Form.Group>

            <h3 className="mb-3">Survey type</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.surveyType">
              <Form.Label>Select type of survey to be carried out</Form.Label>
              <Form.Control
                name="surveyType"
                isInvalid={formerrors.surveyType}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.surveyType}
                as="select"
              >
                <option value=""></option>
                <option value="Basic">BASIC</option>
                <option value="Full">FULL</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-5 ms-4" controlId="form.nsi">
              <Form.Label>Select any non-structural items to be included in survey:</Form.Label>
                <DropdownMultiselect
                  selected={values.nsi}
                  buttonClass={formerrors.nsi ? "dropdown-invalid btn-light" : "btn-light"}
                  handleOnChange={(value) => handleChange({target: {name: 'nsi', value}})}
                  options={[
                    "Insulation",
                    "Windows and doors",
                    "Roof drainage, downpipes, guttering",
                    "Internal joinery",
                  ]}
                  name="nsi"
                />
            </Form.Group>
            {/* (i) insulation (ii) windows and doors (iii) roof drainage, downpipes, guttering (iv) internal joinery. */}

            <h3 className="mb-3">Date of inspection</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.dateOfInspection">
              {/* <Form.Label>Select type of survey to be carried out</Form.Label> */}
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                isInvalid={formerrors.dateOfInspection}
                onChange={handleChange}
                onBlur={validateField}
                defaultValue={values?.dateOfInspection}
                // value={values?.dateOfInspection}
                type="date"
                name="dateOfInspection"
                placeholder=""
              />
            </Form.Group>

            <h3 className="mb-3">Weather conditions</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.weather">
              <Form.Label>Select current conditions</Form.Label>
              <Form.Control
                name="weather"
                isInvalid={formerrors.weather}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.weather}
                as="select"
              >
                <option value=""></option>
                <option value="Dry / Overcast">Dry / Overcast</option>
                <option value="Dry / Overcast">Dry / Clear</option>
                <option value="Light Rain">Light Rain</option>
                <option value="Heavy Rain">Heavy Rain</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>

            {values.weather === 'Other' && (<Form.Group className="mb-5 ms-4" controlId="form.otherWeather">
              <Form.Label>Please specify the weather conditions</Form.Label>
              <Form.Control
                isInvalid={formerrors.otherWeather}
                name="otherWeather"
                onChange={handleChange}
                onBlur={validateField}
                value={values?.otherWeather}
                type="text"
                placeholder=""
              />
            </Form.Group>)}

            <h3 className="mb-3">Engineer</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.engineer">
              <Form.Label>Select name of engineer</Form.Label>
              <Form.Control
                name="engineer"
                isInvalid={formerrors.engineer}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.engineer}
                as="select"
              >
                <option value=""></option>
                <option value="Josh Lines-Zechmann">Josh Lines-Zechmann</option>
                <option value="Simon Clarke">Simon Clarke</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>

            <h3 className="mb-3">Reviewed/Approved</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.reviewedBy">
              <Form.Label>Select name of reviewer</Form.Label>
              <Form.Control
                name="reviewedBy"
                isInvalid={formerrors.reviewedBy}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.reviewedBy}
                as="select"
              >
                <option value=""></option>
                <option value="Simon Clarke">Simon Clarke</option>
                <option value="other">Other</option>
              </Form.Control>
            </Form.Group>

            <h3 className="mb-3">Signed</h3>
            <Form.Group className="mb-5 ms-4" controlId="form.signed">
              <Form.Label>Select signature</Form.Label>
              <Form.Control
                name="signed"
                isInvalid={formerrors.signed}
                onChange={handleChange}
                onBlur={validateField}
                value={values?.signed}
                as="select"
              >
                <option value=""></option>
                <option value="simon">Simon Clarke</option>
                <option value="josh">Josh Lines-Zechmann</option>
              </Form.Control>
            </Form.Group>
            {/* <Button className="mb-3" type="submit" variant="primary" size="lg">
              Submit
            </Button> */}
            
            {formerrors && (
              <div className="mt-3">
                {Object.entries(formerrors).map((item, index) => 
                  <p key={index} className="mt-2 text-warning-field">{formerrors[item[0]]}</p>
                )}
              </div>
            )}
          </Form>
        </Container>
      </Stack>
    </>
  );
};

export default SurveyDetailsForm;
