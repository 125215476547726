import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
  
import { Button, Form } from 'react-bootstrap'
import '../index';

const Auth = () => {
    const navigate = useNavigate();
    const [pw, setPw] = useState('');
    const handleChange = (event) => {
      setPw(event.target?.value);
    }
    const handleSubmit = () => {
      if (pw === 'soffit001') {
        window.localStorage.setItem('ssuk_auth_2', true);
        navigate("/survey-main");
      } else {
        setPw('');
        alert("Incorrect password");
      }
    }

    return (
      <div className="App d-flex justify-content-center align-items-center w-100 h-100">
        <div className='m-5 p-5 d-flex justify-content-center align-items-center flex-column '>
            <h3 className="mb-3">Enter password</h3>
            <Form.Group className="mb-3" controlId="auth">
              <Form.Control
                name="password"
                isInvalid={null}
                onChange={handleChange}
                value={pw}
                type="text"
                placeholder=""
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSubmit}>
              Enter
            </Button>
        </div>
      </div>
    );
  }
  
  export default Auth;