import { v4 as uuidv4 } from 'uuid';
import { sourceData } from '../data/source';

export const mapExcelRowsToSurvey = (prefill = false, prefillVals = 'none') => {
    // const src = JSON.stringify(sourceData)
    //   .replaceAll('"Check Roof Alignment "', "Check Roof Alignment")
    //   .replaceAll('&gt;', '>')
    //   .replaceAll('&lt;', '<')
    //   .replaceAll('&amp;', '&');
    // // console.log('stringified data: ', src)
    const source = sourceData;
    const output = {
      info: {
        id: uuidv4(),
        name: '21 Maple Drive',
        dateCreated: new Date(),
        dateModified: new Date()
      },
      filters: [
        "noWindows",
        "gfExt"
        // 'renderedChimney',
        // 'brickworkChimney',
        // 'brickworkRenderChimney',
        // 'stoneworkChimney',
        // 'renderedChimney1',
        // 'brickworkChimney1',
        // 'brickworkRenderChimney1',
        // 'stoneworkChimney1',
        // 'renderedChimney2',
        // 'brickworkChimney2',
        // 'brickworkRenderChimney2',
        // 'stoneworkChimney2',
        // 'renderedChimney3',
        // 'brickworkChimney3',
        // 'brickworkRenderChimney3',
        // 'stoneworkChimney3',
        // 'renderedChimney4',
        // 'brickworkChimney4',
        // 'brickworkRenderChimney4',
        // 'stoneworkChimney4',
        // 'renderedChimney5',
        // 'brickworkChimney5',
        // 'brickworkRenderChimney5',
        // 'stoneworkChimney5',

      ],
      sections: {
        'Survey Details': {
          key: 'Survey Details',
          name: 'Survey Details',
          enabled: true,
          complete: false,
          formValues: null
        },
        'Property Details': {
          key: 'Property Details',
          name: 'Property Details',
          enabled: true,
          complete: false,
          formValues: null
        },
        'External Roof': {
          id: 1,
          key: 'External Roof',
          name: 'External Roof',
          index: 5,
          enabled: true,
          complete: false,
        }
      }
    };
    const sections = {
      'External Roof': source['5.a (External Roof)'],
      'External Walls': source['5.b (External Walls)'],
      'Ventilation': source['5.c Ventilation'],
      'Foundations': source['5.d Foundations'],
      'Structural Dampness': source['5.e Structural Dampness '],
      'Roof Void (Internal)': source['5.f Roof Void (Internal)'],
      'First Floor Rooms': source['5.g. First Floor Rooms '],
      'Ground Floor Rooms': source['5.h. Ground Floor Rooms']
    };

    Object.entries(sections).forEach(data => {
      let subsections = {};
      const defects = [];
      data[1] = data[1].filter(row => !row.Skip)
      data[1].forEach((row, i) => {
        const section = data[0]?.replaceAll('&gt;', '>').replaceAll('&lt;', '<').replaceAll('&amp;', '&').trim();
        const subsection = row['Subsection']?.replaceAll('&gt;', '>').replaceAll('&lt;', '<').replaceAll('&amp;', '&').trim();
        const buildingItem = row['Building Item']?.replaceAll('&gt;', '>').replaceAll('&lt;', '<').replaceAll('&amp;', '&').trim();
        const defectName = row['Defect']?.replaceAll('&gt;', '>').replaceAll('&lt;', '<').replaceAll('&amp;', '&').trim();
        const questionType = row['Type'];
        const filterFor = row['FilterForKey'];
        const filterBy = row['FilterByKey']?.split(',');
        const options = row["Select Options"]?.replaceAll('&gt;', '>').split(',');
        const optional = row["Optional"];
        const MBRT1 = row['MBRT1'];
        const MBRT2 = row['MBRT2'];
        const MBRT3 = row['MBRT3'];
        const GRT = row['GRT'];
        const recommendation2 = row['Recommendation2'];
        const recommendation3 = row['Recommendation3'];
        const conclusion2 = row['Conclusion2'];
        const conclusion3 = row['Conclusion3'];
        const id = row['id'];
        const CR1 = row['CR1'];
        const CR2 = row['CR2'];
        const CR3 = row['CR3'];
    
        defects.push({
          id,
          defect: defectName,
          section,
          subsection,
          buildingItem,
          questionType,
          filterBy,
          filterFor,
          optional,
          MBRT1,
          MBRT2,
          MBRT3,
          GRT,
          CR1,
          CR2,
          CR3,
          recommendation2,
          recommendation3,
          conclusion2,
          conclusion3,
          options,
          enabled: true,
          include: true,
          complete: false,
          pictureTaken: false,
          flag: false
        });
      });

      const prefillAnswer = () => {
        switch(prefillVals) {
          case 'none':
            return null;
            break;
          case 'Rating 1':
            return 0;
            break;
          case 'Rating 2':
            return 1;
            break;
          case 'Rating 3':
            return 2;
            break;
          case 'Randomise':
            return Math.floor(Math.random() * 3);
            break;
          default:
            return null;
        }
      }
  
      defects.forEach((defect, index)=> {
        subsections = {
          ...subsections,
          [defect.subsection]: {
            ...subsections[defect.subsection],
            buildingItems: {
              ...subsections[defect.subsection]?.buildingItems,
              [defect.buildingItem]: {
                ...subsections[defect.subsection]?.buildingItems[defect.buildingItem],
                defects: {
                  ...subsections[defect.subsection]?.buildingItems[defect.buildingItem]?.defects,
                  [defect.defect]: {
                    ...defect,
                    answer: prefill && defect.questionType === 'rating' ? prefillAnswer() : null, // Math.floor(Math.random() * 4)
                    complete:  prefill && defect.questionType === 'rating'
                  }
                }
              }
            }
          }
        }
      })
  
     

      if (data[0] === 'External Roof') {
        const createMultiChimneySections = (chimneySubsection, numOfChimneys, index) => {
          chimneySubsection.buildingItems = Object.entries(chimneySubsection.buildingItems).map(i => { // issue from repeated defect names?
            const item = [i[0], {...i[1]}];
            item[1].defects = Object.entries(item[1].defects).map(d => {
              const defect = [d[0], {...d[1]}];
              defect[1] = {
                ...defect[1],
                subsection: `${defect[1].subsection} #${index + 1}`,
                filterBy: defect[1].filterBy[0] ? [`${defect[1].filterBy[0]}${index + 1}`] : null,
                filterFor: defect[1].filterFor ? `${defect[1].filterFor}${index + 1}` : null
              }
    
              return defect;
            }).reduce((acc, val) => {
              return { ...acc, [val[0]]: val[1] };
            }, {});
    
            return item;
          }).reduce((acc, val) => {
            return { ...acc, [val[0]]: val[1] };
          }, {});
    
          return chimneySubsection;
        }
        let numOfChimneys = 5;
        [
          'Chimney Structure (Rendered)',
          'Chimney Structure (Brickwork)',
          'Chimney Structure (Brickwork/Rendered)',
          'Chimney Structure (Stonework)',
        ].forEach((item, index) => {
          for (let i = 0; i < numOfChimneys; i++) {
            subsections[`${item} #${i + 1}`] = createMultiChimneySections({...subsections[`${item}`]}, numOfChimneys, i);
          }
          delete subsections[item];
        });

        const reOrderSubsections = () => {
          const getChimneySections = (index) => {
            const subs = {...subsections}
            return Object.entries(subs)
              .filter(sub =>
                sub[0].includes(`#${index}`) 
                && sub[0].includes('Chimney Structure')
              )
              .reduce((acc, val) => {
                return { ...acc, [val[0]]: val[1] };
              }, {});
          }
          const groupedChimneySubsections = [1,2,3,4,5].map(num => getChimneySections(num));
    
          [
            'Chimney Structure (Rendered)',
            'Chimney Structure (Brickwork)',
            'Chimney Structure (Brickwork/Rendered)',
            'Chimney Structure (Stonework)',
          ].forEach((item, index) => {
            for (let i = 0; i < numOfChimneys; i++) {
              delete subsections[`${item} #${i + 1}`]
            }
          });
    
          groupedChimneySubsections.forEach((group) => {
            Object.entries(group).forEach(g => {
              delete subsections[g[0]];
              subsections[g[0]] = g[1];
            })
          });
    
          [
            'Check Roof Ventilation',
            'Check Extension Flat Roof',
            'Check Extension Roof Drainage',
          ].forEach(item => {
            const temp = {...subsections[item]};
            delete subsections[item];
            subsections[item] = temp;
          })
        }
    
        reOrderSubsections();
      }

      // console.log('adding subsections:', subsections)
      output.sections = {
        ...output.sections,
        [data[0]]: {
          key: data[0],
          name: data[0],
          enabled: true,
          complete: false,
          subsections
        }
      }
    })
    //const defaultExcluded = ['Check Chimney Structure(s)']
    
    //initialState.sections = output.sections
    return output;
  }