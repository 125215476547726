import { useContext, useEffect, useRef, useState } from 'react';
import { CheckCircleFill, CircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import Question from '../components/question';
import { AppContext } from '../context/context';
import PropertyDetailsForm from '../sections/forms/propertyDetailsForm';
import SurveyDetailsForm from '../sections/forms/surveyDetailsForm';
import ReportGenerator from '../service/report-generator';
import { saveSurvey } from '../service/storage.service';
import { Stack, Accordion, ButtonGroup, Button, Modal, Form } from "react-bootstrap";

const SurveyMain = () => {
  const [state, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [active, setActive] = useState({});
  const titleRef = useRef({
    // '///Survey Details': null,
    // '///Property Details': null,
    // '///External Roof': null,
    // '///External Walls': null,
    // '///Ventilation': null,
    // '///Foundations': null,
    // '///Structural Dampness': null,
    // '///Roof Void (Internal)': null,
  })
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({includeGrt: true, includeImages: true});
  const auth = window.localStorage.getItem('ssuk_auth_2');
  const handleCloseModal = () => {
    //setSelectedSections([]);
    setSelectedOptions({includeGrt: true, includeImages: true})
    setShowReportModal(false);
  };
  const handleShowModal = () => setShowReportModal(true);
  const sectionOptions = [
    'SELECT ALL',
    'Title page',
    'Table of contents',
    'Executive summary',
    'Introduction',
    'Survey details',
    'Property details',
    'Guide for clients',
    'External roof',
    'External walls',
    'Ventilation',
    'Foundations',
    'Structural damp',
    'Roof void',
    state.filters?.includes('firstFloor') ? 'First floor rooms' : null,
    'Ground floor rooms',
    'Conclusions',
    'Recommendations',
    'Repair costs',
    'What to do next',
    'Appendix'
  ].filter(v => !!v);


  const handleSelectSections = (event) => {
    const {value, checked} = event.target;
    let newData = [...selectedSections];
    if (value !== 'SELECT ALL') {
      if (checked) {
        if (!newData.includes(value)) {
          newData.push(value);
        }
      } else {
        newData = newData.filter(f => f !== value)
      }
    } else {
        newData = checked ? sectionOptions : []
    }
    // console.log('upated selection: ', newData)
    setSelectedSections([...newData])
  }

  const handleOptionsChange = (event) => {
    const {value, checked} = event.target;
    const tempOptions = {...selectedOptions};
    if (value === 'Include general report text') {
      tempOptions.includeGrt = checked? true : false;
    }
    if (value === 'Include images') {
      tempOptions.includeImages = checked? true : false;
    }
    // console.log(tempOptions)
    setSelectedOptions({...tempOptions});
  }

  useEffect(() => {
    // // console.log(state);
    // // console.log('save survey in main: ', state)
    if (state?.sections) {
      saveSurvey(state);
    }
    if (!state?.filters?.includes('firstFloor')) {
      setSelectedSections(selectedSections.filter(f => f !== 'First floor rooms'))
    }
  },[state]);

  useEffect(() => {
    if (!auth) {
      navigate("/auth");
    } else if (!state?.sections) {
      navigate('/surveys');
    }

   },[]);

  const generateReport = () => {
    ReportGenerator(state, selectedSections, selectedOptions);
  }

  
  const toggleActive = (eventKey, map) => {
    // // console.log(eventKey, map)
    let fieldsToClose;
    let keyString;
    let sectionsToClose;
    if (!map.subsection) {
      keyString = '///'
    }
    if (map.subsection) {
      keyString = map.section + '///';
    } 
    if (map.buildingItem) {
      keyString = keyString + map.subsection + '///';
    }
    if (map.defect) {
      keyString = keyString +  map.buildingItem + '///'
    }
      
    fieldsToClose = Object.entries(active).map((item, index) => {
      return item[0].includes(keyString) ? [item[0], null] : item;
    }).reduce((accumulator, value, index) => {
      return {...accumulator, [value[0]]: value[1]};
    }, {});

    if (active[eventKey]) {
      setActive({...fieldsToClose, [eventKey]: null})
    } else {
      setActive({...fieldsToClose, ...sectionsToClose, [eventKey]: eventKey})
      if (keyString === '///') {
        setTimeout(() => {
          titleRef.current['///Survey Details']?.scrollIntoView();
        },0)
      }
    }
  }

  const getItem = (map) => {
    if (map.defect) {
      return state?.sections[map.section].subsections[map.subsection].buildingItems[map.buildingItem].defects[map.defect];
    } else if (map.buildingItem) {
      return state?.sections[map.section].subsections[map.subsection].buildingItems[map.buildingItem];
    } else if (map.subsection) {
      return state?.sections[map.section].subsections[map.subsection];
    } else if (map.section) {
      return state?.sections[map.section];
    }
  }


  const nestedMenu = (item, name, content, eventKey, map, type, flush ) => {
   // // // console.log('gen nested menu: ', name, content, type, flush)
    return (
      <Accordion
        ref={type === 'section' ? (el) => (titleRef.current[eventKey] = el) : null}
        activeKey={active[eventKey]}
        key={name + eventKey}
        flush={flush}
      >
        <Accordion.Item key={eventKey} eventKey={eventKey} className={'acc-item-' + type}>
          <Accordion.Button disabled={item.disabled} className={'acc-button-' + type} onClick={()=> toggleActive(eventKey, map)}>
            <div className="check-wrapper">
              {getItem(map).complete && (<CheckCircleFill className={"checkfill complete"}/>)}
              {!getItem(map).complete && !getItem(map).optional && (<CircleFill className={"checkfill"}/>)}
              {!getItem(map).complete && getItem(map).optional && (<CircleFill className={"checkfill optional"}/>)}
            </div>
            {name}
          </Accordion.Button>
          <Accordion.Body className={type !== 'defect' ? 'pe-0' : ''}>
            <Stack >
            {content}
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }

  const question = (defect, key, map) => {
    return (
      <Question
        answer={defect.answer}
        CR={[defect.CR1, defect.CR2, defect.CR3]}
        MRT={[defect.MBRT1, defect.MBRT2, defect.MBRT3]}
        type={defect.questionType}
        filterFor={defect.filterFor}
        options={defect.options}
        key={key}
        map={map}
        pictureTaken={defect.pictureTaken}
        flag={defect.flag}
        note={defect.note}
        pictureCaption={defect.pictureCaption}
      />
    )
  }

  const defects = (defects, key, map) => Object.entries(defects).map((defect, index) => {
    let newKey = key + '///' + defect[0];
    map = {
      ...map,
      defect: defect[0]
    }
    return defect[1].include ? nestedMenu(defect, defect[0], question(defect[1], newKey, map), newKey, map, 'defect', true):null;
  });

  const buildingItems = (buildingItems, key, map) => buildingItems ? Object.entries(buildingItems).map((item, index) => {
    let newKey = key + '///' + item[0];
    map = {
      ...map,
      buildingItem: item[0]
    }
    return item[1].include ? nestedMenu(item, item[0], defects(item[1].defects, newKey, map), newKey, map, 'buildingItem' ) : null;
  }) : null;

  const subsections = (subsections, key, map) => Object.entries(subsections).map((subsection, index) => {
    let newKey = key + '///' + subsection[0];
    map = {
      ...map,
      subsection: subsection[0]
    }
    return subsection[1].include ? nestedMenu(subsection, subsection[0], buildingItems(subsection[1].buildingItems, newKey, map), newKey, map, 'subsection') : null;
  });

  const detailsSection = (name) => {
    if (name === 'Survey Details') {
      return (
        <SurveyDetailsForm />
      )
    } else if (name === 'Property Details') {
      return (
        <PropertyDetailsForm />
      )
    }
  }

  const surveySections = state?.sections ? Object.entries(state.sections).map((section, index) => {
    const key = '///' + section[1].name;
    const map = {
      section: section[1].name
    }
    if (section[1].subsections) {
      return section[1].include ?  nestedMenu(section, section[1].name, subsections(section[1].subsections, key, map), key, map, 'section') : null
    } else {
      return nestedMenu(section, section[1].name, detailsSection(section[1].name, key, map), key, map, 'section')
    }
  }) : null;


  return (
    <>
      <Modal className="test" show={showReportModal} onHide={handleCloseModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Generate report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="">
            <Form.Label htmlFor="survey-sections">Select sections to include:</Form.Label>
            {sectionOptions.map((item, i) => {
                return (
                  <Form.Check
                    className={"mt-4 ms-4 mb-4 me-4 cursor-pointer "}
                    key={i}
                    name={item}
                    type={"checkbox"}
                    value={item}
                    onChange={handleSelectSections}
                    checked={selectedSections?.includes(item)}
                    id={`section-select-${i}`}
                    label={`${item}`}
                  />
                )
              })
            }
            <hr />
            <Form.Label htmlFor="survey-options">Options for testing:</Form.Label>
            {[
                'Include general report text',
                'Include images'
              ].map((item, i) => {
                return (
                  <Form.Check
                    className={"mt-4 ms-4 mb-4 me-4 cursor-pointer "}
                    key={i}
                    name={item}
                    type={"checkbox"}
                    value={item}
                    onChange={handleOptionsChange}
                    checked={item.includes('images') ? selectedOptions.includeImages : selectedOptions.includeGrt}
                    id={`section-options-${i}`}
                    label={`${item}`}
                  />
                )
              })
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" disabled={!selectedSections.length} onClick={generateReport}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Header completion={state?.info?.completion} name={state?.info?.name}/>
      <div className="padder pt-5 mb-2"></div>
      <Stack className="ms-3 me-3 mb-3 pt-5" >
        {/* {state?.info?.name} */}
        {surveySections?.filter(v => !!v)}
        <Button onClick={handleShowModal} className="report-button p-3 mb-4 ">GENERATE SURVEY REPORT</Button>
      </Stack>
    </>
  );
};

export default SurveyMain;
