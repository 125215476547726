import React, { createContext, useReducer } from 'react';
import {
  changeChimneyMaterials,
  changeDpcType,
  changeDpmType,
  changeExtStoreys,
  changeFeltType,
  changeNonStructuralItems,
  changeRoofConfigurationFilters,
  changeStoreys,
  changeStructuralCrackingType,
  changeVentilationDescription,
  changeWallDescription,
  numberOfChimneysChanged,
} from '../service/filters.service';

export const AppContext = createContext();

const initialState = { sections: null };

const allFiltersPresent = (filters, filterBy) => {
  if (!filterBy || !filterBy.length) {return true}
  let res = true;
  //// console.log('filterBY: ', filterBy, filters)
  filterBy?.forEach(f => {
    res = filters?.includes(f) ? res : false
  })
  return res;
}

const processDefect = ({
  defect,
  counter,
  filters,
  itemIsEmpty,
  itemIsComplete,
  itemIsOptional,
  itemContainsRequiredDefects,
}) => {
  if (!!defect[1].filterBy?.length && !allFiltersPresent(filters, defect[1].filterBy)) {
    defect[1].include = false;
  } else {
    if (!defect[1].optional) {
      counter.defects.total++;
      itemContainsRequiredDefects = true;
      itemIsOptional = false;
    }
    if (defect[1].complete) {
      counter.defects.complete++;
    } else if (
      !defect[1].optional ||
      (defect[1].optional && !itemContainsRequiredDefects)
    ) {
      itemIsComplete = false;
    }

    defect[1].include = true;
    itemIsEmpty = false;
  }

  return {
    defect,
    itemIsComplete,
    itemIsEmpty,
    itemIsOptional,
    counter,
    itemContainsRequiredDefects,
  };
};

const processItem = ({ item, counter, filters, subIsEmpty, subIsComplete }) => {
  let itemIsEmpty = true;
  let itemIsComplete = true;
  let itemIsOptional = true;
  let itemContainsRequiredDefects = false;
  item[1].defects = Object.entries(item[1].defects)
    .map((defect, index) => {
      ;({
        defect,
        counter,
        itemIsComplete,
        itemIsOptional,
        itemIsEmpty,
        itemContainsRequiredDefects,
      } = processDefect({
        defect,
        filters,
        counter,
        itemIsOptional,
        itemIsComplete,
        itemIsEmpty,
        itemContainsRequiredDefects,
      }));

      return defect;
    })
    .reduce((acc, val) => {
      return { ...acc, [val[0]]: val[1] };
    }, {});
  if (itemIsEmpty) {
    item[1].include = false;
  } else {
    item[1].include = true;
    subIsEmpty = false;
  }
  item[1].complete = itemIsComplete;
  item[1].optional = itemIsOptional;
  if (!itemIsComplete && !itemIsOptional) {
    subIsComplete = false;
  }

  return { item, subIsComplete, subIsEmpty, counter };
};

const processSubsection = ({
  subsection,
  counter,
  filters,
  sectionIsEmpty,
  sectionIsComplete,
}) => {
  let subIsEmpty = true;
  let subIsComplete = true;
  subsection[1].buildingItems = Object.entries(subsection[1].buildingItems)
    .map((item) => {
      ;({ item, counter, subIsComplete, subIsEmpty } = processItem({
        item,
        filters,
        counter,
        subIsComplete,
        subIsEmpty,
      }));
      if (!subIsEmpty) {
        sectionIsEmpty = false;
      }
      return item;
    })
    .reduce((acc, val) => {
      return { ...acc, [val[0]]: val[1] };
    }, {});

  if (subIsEmpty) {
    subsection[1].include = false;
  } else {
    subsection[1].include = true;
  }
  if (!subIsComplete) {
    sectionIsComplete = false;
  }
  subsection[1].complete = subIsComplete;

  return { subsection, sectionIsComplete, sectionIsEmpty, counter };
};

const processSection = ({section, counter, filters}) => {
  let sectionIsComplete = true;
  let sectionIsEmpty = true;
  if (section[1].subsections) { // test survey sections
    section[1].subsections = Object.entries(section[1].subsections)
      .map((subsection) => {
        ;({ subsection, sectionIsComplete, sectionIsEmpty, counter } =
          processSubsection({
            subsection,
            sectionIsComplete,
            sectionIsEmpty,
            counter,
            filters,
          }));
        return subsection;
      })
      .reduce((acc, val) => {
        return { ...acc, [val[0]]: val[1] };
      }, {});
      section[1].include = !sectionIsEmpty;
  } else {
    if (!section[1].formIsValid) { // test form sections
      sectionIsComplete = false;
    }
    // // console.log('calc SD completion:')
    // // console.log('running total: ', counter.defects.total)
    // // console.log('running complete: ', counter.defects.complete)
    // // console.log('SD total: ', section[1]?.completion?.totalFields)
    // // console.log('SD complete: ', section[1]?.completion?.filledFields)
    counter.defects.total += section[1]?.completion?.totalFields || 0;
    counter.defects.complete += section[1]?.completion?.filledFields || 0;
  }
  
  section[1].complete = sectionIsComplete;

  return {section, counter}
}

const filterAndClean = (data) => {
  const filters = data.filters;
  // // console.log('pre-state', data)
  const temp = { ...data };
  // // console.log("pre-state temp", temp);

  let counter = {
    defects: {
      total: 0,
      complete: 0,
    },
    buildingItems: 0,
    subsections: 0,
    sections: 0,
  };
  // refactor this so it only checks sections that have changed.  No need to go over the whole thing every time, apart from maybe to recalc completion with newly filtered sections?
  temp.sections = Object.entries(temp.sections)
    .map((section) => {
      ;({section, counter} = processSection({section, counter, filters}))
      return section;
    })
    .reduce((acc, val) => ({ ...acc, [val[0]]: val[1] }), {});

  // console.log('total fields: ', counter.defects.total)
  temp.info.completion = Math.floor(
    (counter.defects.complete / counter.defects.total) * 100
  );
  if (temp.info.completion > 100) {
    temp.info.completion = 100;
  }
  //temp.info.id = uuidv4();

  return temp; // was data, still worked?
};


/*

*/


const applyFilters = (action, data, prevVal, chimneyMaterialsAnswers) => {
  const filterFor = action.payload.data.filterFor;
  const answer = !!action.payload.data['formValues'] ? action.payload.data.formValues[filterFor] : action.payload.data.answer;
  // console.log('answer in filters: ', answer, filterFor, action);
  switch(filterFor) {
    case 'swrVertDiagCracks':
    case 'swrBrickVertDiagCracks':
    case 'swrStoneVertDiagCracks':
    case 'swBrickStoneVertDiagCracks':
    case 'swToPropCavityExtensionVert':
    case 'swToPropCavityExtensionRenderBrickVert':
    case 'swToPropCavityExtensionRenderStoneVert':
    case 'swToPropCavityExtensionBrickOrStoneVert':
    case 'swCavityRenderVert':
    case 'swCavityRenderBrickVert':
    case 'swCavityRenderStoneVert':
    case 'swCavityBrickStoneVert':
    case 'intrusiveExRenderIntPlaster':
    case 'intrusiveExRenderOnly':
    case 'intrusiveExMasonryIntPlaster':
    case 'intrusiveExMasonry':
    case 'intrusiveIntPlaster':
    case 'valleyDefects':
    case 'wallBowing':
      if (parseInt(answer) > 0 && !data.filters.includes(filterFor)) {
        data.filters.push(filterFor);
      } else if (parseInt(answer) === 0) {
        data.filters = data.filters.filter(
          (f) => f !== filterFor
        );
      }
      break;

    case 'extensionRoof':
      if (answer === 'Yes' && !data.filters.includes(filterFor)) {
        data.filters.push(filterFor);
      } else if (answer === 'No') {
        data.filters = data.filters.filter(
          (f) => f !== filterFor
        );
      }
      break;

    case 'roofVoidAvailable':
      if (answer === 'Yes' && !data.filters.includes(filterFor)) {
        data.filters.push(filterFor);
      } else if (answer === 'No') {
        data.filters = data.filters.filter(
          (f) => f !== filterFor
        );
      }
      break;

    case 'roofVoidConverted':
      if (answer === 'Yes' && !data.filters.includes(filterFor)) {
        data.filters.push(filterFor);
        data.filters = data.filters.filter(
          (f) => f !== 'timberInspectable'
        );
      } else if (answer === 'No') {
        data.filters.push('timberInspectable');  // makes dupe filter entry.  Needs cleaning at some point
        data.filters = data.filters.filter(
          (f) => f !== filterFor
        );
      }
      break;

    case 'timberInspectable':
      if (answer === 'Some limited timber inspection achievable' && !data.filters.includes(filterFor)) {
        data.filters.push(filterFor);
      } else if (answer !== 'Some limited timber inspection achievable') {
        data.filters = data.filters.filter(
          (f) => f !== filterFor
        );
      }
      break;

    case 'nsi':
      data.filters = changeNonStructuralItems(data.filters, answer);
      break;

    case 'roofConfiguration':
      data.filters = changeRoofConfigurationFilters(data.filters, answer);
      break;

    case 'subFloorVentilationType':
      data.filters = changeVentilationDescription(data.filters, answer);
      break;

    case 'structuralCracking':
      data.filters = changeStructuralCrackingType(data.filters, answer);
      break;

    case 'dpc':
      data.filters = changeDpcType(data.filters, answer);
      break;
    
    case 'dpm':
      data.filters = changeDpmType(data.filters, answer);
      break;

    case 'numberOfFloors':
      data.filters = changeStoreys(data.filters, answer);
      break;

    case 'extensionStoreys':
      data.filters = changeExtStoreys(data.filters, answer);
      break;
      
    case 'numberChimneys':
      // console.log('get num of chimneys:', prevVal)
      data.filters = numberOfChimneysChanged(data.filters, parseInt(answer), prevVal, chimneyMaterialsAnswers); // is this method from wrong file?
      break;

    case 'hasExtensions':
      // console.log('hasExtensions:', answer)
      if (answer === 'Yes' && !data.filters.includes(filterFor)) {
        data.filters.push(filterFor);
      } else if (answer === 'No') {
        data.filters = data.filters.filter(
          (f) => f !== filterFor
        );
      }
      break;

    case 'extensionRoofType':
      if (answer === 'Flat' && !data.filters.includes('extensionRoof')) {
        data.filters.push('extensionRoof');
      } else if (answer !== 'Flat') {
        data.filters = data.filters.filter(
          (f) => f !== 'extensionRoof'
        );
      }
      break;

    case 'chimneyConstruction1':
    case 'chimneyConstruction2':
    case 'chimneyConstruction3':
    case 'chimneyConstruction4':
    case 'chimneyConstruction5':
      const chimneyIndex = parseInt(filterFor.charAt(filterFor.length - 1));
      data.filters = changeChimneyMaterials(data.filters, answer, chimneyIndex, prevVal);
      break;
    
    case 'externalWallTypeAndFinishDescription':
      // console.log('CHANGE WALL DESC: ', answer)
      data.filters = changeWallDescription(data.filters, answer);
      break;

    case 'roofFelt':
      data.filters = changeFeltType(data.filters, answer);
      break;

    default:
      break;
  }

  return data;
}



const reducer = (state, action) => {
  //const start = performance.now()
  // // console.log("reeducer firing", action.payload, state);
  let map;
  let s;
  let prevVal;
  let chimneyMaterials;
  switch (action.type) {
    case "LOAD_SURVEY":
      const tempState = { ...action.payload.data };
      const result = filterAndClean(tempState);
      //// console.log(result)
      return {
        ...result,
      };

    case "UPDATE_SURVEY_DETAILS":
      s = { ...state };
      s.sections['Survey Details'] = {
        ...s.sections['Survey Details'],
        ...action.payload.data,
      }
      s = applyFilters(action, s);
      s = filterAndClean(s);
      console.log(s)
      return {...s}
    
    case "UPDATE_PROPERTY_DETAILS":
      prevVal = action.payload.prevVal;
      chimneyMaterials = {...action.payload.chimneyMaterials};
      s = { ...state };
      s.sections['Property Details'] = {
        ...s.sections['Property Details'],
        ...action.payload.data,
      }
      s = applyFilters(action, s, prevVal, chimneyMaterials);
      s = filterAndClean(s);
      console.log(s)
      return {...s}

    case "UPDATE_QUESTION":
      map = {...action.payload.map};
      prevVal = action.payload.prevVal;
      s = { ...state };
      s.sections[map.section].subsections[map.subsection].buildingItems[map.buildingItem].defects[map.defect] = {
        ...s.sections[map.section].subsections[map.subsection].buildingItems[map.buildingItem].defects[map.defect],
        ...action.payload.data,
      };
      let temp = { ...s };
      if (action.payload.chimneyMaterials) {
        chimneyMaterials = {...action.payload.chimneyMaterials};
      }
      temp = applyFilters(action, temp, prevVal, chimneyMaterials);
      s = filterAndClean(temp);
      // const time = performance.now() - start;
      // // // console.log(time);
      console.log(s)
      return { ...s };

    case "START":
      return {
        loading: true,
      };
    case "COMPLETE":
      return {
        loading: false,
      };
    default:
      throw new Error();
  }
};

export const ContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
};
