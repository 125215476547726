import {
    AlignmentType,
    BorderStyle,
    convertInchesToTwip,
    Document,
    HeadingLevel,
    HeightRule,
    ImageRun,
    Packer,
    PageBreak,
    Paragraph,
    Table,
    TableCell,
    TableLayoutType,
    TableRow,
    TextRun,
    VerticalAlign,
    WidthType,
} from 'docx';
import roofingMaterialsLife from '../images/roofingMaterialsLife.png';
import { saveAs } from 'file-saver';
import Logo from '../images/SSUK-logo.png';
import flatRoofDiagram from '../images/flatRoof.png';
import fireStopDetail from '../images/fireStopDetail.png';
import concGroundSlabDetail from '../images/concGroundSLabDetail.png';
import susTimberFloorDetail from '../images/susTimberFloorDetail.png';
import susGroundFloorDetail from '../images/susGroundFloorDetail.png';
import cavityWallFloorDetail from '../images/cavityWallFloorDetail.png';
import cavityWallTie from '../images/cavityWallTie.png';
import trickleVentsDetail from '../images/trickleVentsDetail.jpg';
import windowLocksDetail from '../images/windowLocksDetail.png';
import ventRoofTiles from '../images/ventRoofTiles.jpg';
import roofVentSoffitBoards from '../images/roofVentSoffitBoards.jpg';
import concreteStripFoundationDetail from '../images/concreteStripFoundationDetail.png';
import deepConcreteFoundationDetail from '../images/deepConcreteFoundationDetail.png';
import concreteRaftFoundationDetail from '../images/concreteRaftFoundationDetail.png';
import dampPenetrationDetail from '../images/dampPenetrationDetail.png';
import dampMembraneDetail from '../images/dampMembraneDetail.png';
import dampMembraneInstallation from '../images/dampMembraneInstallation.png';
import susTimberSectionDetail from '../images/susTimberSectionDetail.png';
import concSusFloorDpmDetail from '../images/concSusFloorDpmDetail.png';
import risingDampFinishes from '../images/risingDampFinishes.png';
import risingDampTideMarks from '../images/risingDampTideMarks.png';
import dampProofCourse from '../images/dampProofCourse.png';
import dampMeter from '../images/dampMeter.png';
import groutVoids from '../images/groutingVoids.png';
import correctWallTies from '../images/correctWallTies.png';
import incorrectWallTies from '../images/incorrectWallTies.png';
import houseDiagram from '../images/houseDiagram.png';
import bracingDiagram from '../images/bracingDiagram.png';
import ssukInfo from '../images/ssukinfo.png';
import simonSignature from '../images/simonSignature.png';
import joshSignature from '../images/joshSignature.png';
import { dpcFilters, dpmFilters, feltFilters, structuralCrackingFilters, ventilationFilters, wallTypeDescriptionFilters } from './filters.service';


const ReportGenerator = (data, selectedSections, options) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    try {
        generateReport(dataCopy, selectedSections, options);
    } catch (e) {
        window.alert('Failed to generate survey: ' + e.message)
    }
}



const generateReport = async (data, selectedSections, options) => {
    let roofTileDamageAreaText;
    let roofTileDamageTypes;
    let ageOfTiles;
    let remainingLifespanOfTiles;
    let valleyDamage;
    let valleyActions;
    let wallSurfaces;
    let dampMeterReadings;
    
    const {includeGrt, includeImages} = options;
    const logo = await fetch(Logo);
    
    const defaultTextColor = '000000';
    const textConfig = {
        bold: true,
        color: "000000",
        break: 2,
    }
    const noBordersConfig = {
        style: BorderStyle.DASH_SMALL_GAP,
        size: 0,
        color: "FFFFFF",
    };
    const noBorders = {
        top: noBordersConfig,
        bottom: noBordersConfig,
        left: noBordersConfig,
        right: noBordersConfig,
    };
    const spacing = {
        before: 300,
    };

    const titleCase = (str) => {
        if (str) {
            return str.replace(/\w\S*/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        } else {
            return null
        }
    }

    const textToParagraph = (text, spacing) => {
        return new Paragraph({
            text:  text,
            spacing,
            style: "basic",
        })
    }

    const subsectionHeading = (text, spacing) => {
        return new Paragraph({
            text: text.replace('Check ', ''),
            heading: HeadingLevel.HEADING_2,
            spacing: {
                before: spacing.before * 2
            }
        })
    }

    const buldingItemHeading = (text, spacing) => {
        return new Paragraph({
            text: text.replace('Check ', ''),
            heading: HeadingLevel.HEADING_3,
            spacing: {
                before: spacing.before * 2,
                after: spacing.before
            }
        })
    }

    

    const containsLineBreak = (str) => {
        // console.log(str)
        const specialChars = /(\\n)/gm //(\[LNBRK\])/gm // /(\n)/;
        // console.log(specialChars.test(str))
        return specialChars.test(str);
    }

    const formatForLineBreaks = (text, color, flag) => {
        let textText;
        if (containsLineBreak(text)) {
            textText = text.split(/(\\n)/gm).map((line, i) => {
                return new TextRun({
                    text: !line.includes('\\n') ? ( i === 0 ? '- ' : '') + line : '',
                    color,
                    break: 1
                })
            })
        } else {
            textText = [new TextRun({
                    text: '- ' + text,
                    color,
                    break: flag ? 1 : 0
                })]
            ;
        }
        return textText;
    }

    const imageParagraph = async (imageFile, spacing, width, height, caption, inline, leftAlign) => {
        // // console.log(imageFile)
        const imageData = await fetch(imageFile);
        return new Paragraph({
            children:  [
                new ImageRun({
                    data: await imageData.blob(),
                    transformation: {
                        width,
                        height,
                    },
                }),
                !!caption ? new TextRun({
                    text: caption,
                    break: 2
                }) : null
            ].filter(v => !!v),
            spacing: {
                ...spacing,
                after: inline ? 0 : 600
            },
            style: "basic",
            alignment: !leftAlign ? AlignmentType.CENTER : AlignmentType.LEFT
        })
    }
    const cavityWallTieImage = await imageParagraph(cavityWallTie, spacing, 385, 200, 'Typical Cavity Wall Detail (Note the insulation either side of the inner blockwork)');
    const groutVoidImage = await imageParagraph(groutVoids, spacing, 300, 410, "");
    const correctWallTieImage = await imageParagraph(correctWallTies, spacing, 600, 300, "");
    const incorrectWallTieImage = await imageParagraph(incorrectWallTies, spacing, 420, 350, "");
    const bracingImage = await imageParagraph(bracingDiagram, spacing, 300, 720, "");


    const getCommaListText = (list) => {
        if (list?.length > 1) {
            let str = ``;
            const last = list[list.length - 1];
            const rest = list.slice(0, list.length - 1);
            if (rest.length === 1) {
                str = str + rest[0] + ' and ' + last;
            } else {
                rest.forEach((item, index) => {
                    if (index < rest.length - 1) {
                        str = str + item + ', ';
                    } else if (index === rest.length - 1) {
                        str = str + item + ' and ' + last;
                    }
                })
            }
            return str;
        } else if (list?.length === 1) {
            return list[0];
        }

        return null
    }

    const addVars = (text) => {
        return text?.replace('[ageOfTiles]', ageOfTiles)
            .replace('[remainingTileLifespan]', remainingLifespanOfTiles)
            .replace('[roofAreas]', roofTileDamageAreaText)
            .replace('[tileDamageTypes]', roofTileDamageTypes)
            .replace('[valleyDamage]', 'missing/slipped ' + valleyDamage)
            .replace('[valleyActions]', valleyActions)
            .replace('[wallSurfaces]', wallSurfaces)
            .replace('[dampMeterRating]', dampMeterReadings) || '';
    }

    const formatConclusions = (defectsData, spacing, prefix = '') => {

       
        const defects = defectsData.map(defect => {
            let rAndCText2 = defect.conclusion2;
            let rAndCText3 = defect.conclusion3;
    
            let text;
            // if (defect[1].questionType === 'rating') {
            switch(parseInt(defect.answer)) {
                case 1:
                    text = addVars(rAndCText2)
                    break;
                case 2:
                    text = addVars(rAndCText3)
                    break;

                default:
                    break;
            }
            

            const color = defect.flag ? '991d17': defaultTextColor;

            let textText;
            if (containsLineBreak(text)) {
                textText = text.split(/(\\n)/gm).map((line, i) => {
                    return new TextRun({
                        text: !line.includes('\\n') ? ( i === 0 ? '- ' : '') + line : '',
                        color,
                        break: 1
                    })
                })
            } else {
                textText = [new TextRun({
                        text: '- ' + text,
                        color,
                        break: defect.flag ? 1 : 0
                    })]
                ;
            }

            

            const defectText = text && defect?.include ? [new Paragraph({
                children: [
                    defect.flag ? 
                    new TextRun({
                        text: '[ FLAGGED BY USER ]',
                        color,
                    }) : null,
                    defect.flag && defect.note ? 
                    new TextRun({
                        text: ' ' + defect.note,
                        color,
                        italics: true
                    }) : null,
                    // new TextRun({
                    //     text,
                    //     color,
                    //     break: defect[1].flag ? 1 : 0
                    // }),
                    ...textText,
                ].filter(v => !!v),
                spacing,
                style: "basic",
                indent: {
                    start: 600,
                }
            }),
            ].filter(v => !!v) : null;
            return defectText;
        }).filter(v => !!v).reduce((acc, curr) => [...acc, ...curr], []);

        // console.log('ffr defects: ', type, defects)
        const itemContent = defects.length ? 
        [
            ...defects
        ] : []
        //// // console.log('item content: ',itemContent)
        return itemContent;
    }

    const formatRecommendations = (defectsData, spacing) => {
        
        let index = 0;
        const defects = defectsData.map((defect) => {
            let concText2 = defect.conclusion2;
            let concText3 = defect.conclusion3;
            let recText2 = defect.recommendation2;
            let recText3 = defect.recommendation3;

            let concText;
            let recText;
            // if (defect[1].questionType === 'rating') {
            switch(parseInt(defect.answer)) {
                case 1:
                    concText = addVars(concText2);
                    recText = addVars(recText2)
                    break;
                case 2:
                    concText = addVars(concText3);
                    recText = addVars(recText3)
                    break;

                default:
                    break;
            }
           

            const color = defect.flag ? '991d17': defaultTextColor;

            let textText;
            if (containsLineBreak(concText)) {
                concText = concText.split(/(\\n)/gm).map((line, i) => {
                    return new TextRun({
                        text: !line.includes('\\n') ? ( i === 0 ? `${index + 1}). ` : '') + line : '',
                        color,
                        break: 1
                    })
                })
            } else if (!!concText) {
                concText = [new TextRun({
                        text: `${index + 1}). ` + concText,
                        color,
                        break: defect.flag ? 1 : 0
                    })]
                ;
            }
            if (containsLineBreak(recText)) {
                recText = recText.split(/(\\n)/gm).map((line, i) => {
                    return new TextRun({
                        text: !line.includes('\\n') ? line : '',
                        color,
                        italics: true,
                        break: i === 0 ? 2 : 1
                    })
                })
            } else if (!!recText) {
                recText = [new TextRun({
                        text: recText,
                        color,
                        italics: true,
                        break: 2
                    })]
                ;
            }

            if (concText && recText && defect?.include) {
                index++;
            }

            const defectText = concText && recText && defect?.include ? [new Paragraph({
                children: [
                    defect.flag ?
                    new TextRun({
                        text: '[ FLAGGED BY USER ]',
                        color,
                    }) : null,
                    defect.flag && defect.note ? 
                    new TextRun({
                        text: ' ' + defect.note,
                        color,
                        italics: true
                    }) : null,
                    // new TextRun({
                    //     text,
                    //     color,
                    //     break: defect[1].flag ? 1 : 0
                    // }),
                    ...concText,
                    ...recText,
                   
                ].filter(v => !!v),
                spacing,
                style: "basic",
            }),
                defect.subsection === 'Damp' && defect.defect.includes('(solid wall') && parseInt(defect.answer) === 2 ? groutVoidImage : null,
                defect.subsection === 'Damp' && defect.defect.includes('(cavity wall') && defect.answer === 2 ? correctWallTieImage : null,
                defect.subsection === 'Damp' && defect.defect.includes('(cavity wall') && defect.answer === 2 ? incorrectWallTieImage : null,
                defect.subsection === 'Roof Void' && defect.defect.includes('Is there diagonal roof bracing evident') && (defect.answer === 1 || defect.answer === 2) ? bracingImage : null,
            ].filter(v => !!v) : null;
            // console.log('IMAGE INC DEFECT: ', defect)
           
           
            return defectText;
        }).filter(v => !!v).reduce((acc, curr) => [...acc, ...curr], []);

        // console.log('ffr defects: ', type, defects)
        const itemContent = defects.length ? 
        [
            ...defects
        ] : []
        //// // console.log('item content: ',itemContent)
        return itemContent;
    }

    const notedDefects = Object.entries(data.sections).map(section => {
        if (section[1].subsections) {
            return Object.entries(section[1].subsections).map(subsection => {
                return Object.entries(subsection[1].buildingItems).map(item => {
                    const res = Object.entries(item[1].defects).map(defect => {
                        // // // // console.log('mapping defect: ', defect[1]);
                        // // // // console.log('check to include: ', (defect[1].questionType === 'rating' && defect[1].include && !!defect[1].answer));
                        // // // // console.log('check type: ', defect[1].questionType === 'rating');
                        // // // // console.log('check include: ', defect[1].include);
                        // // // // console.log('check answer: ', !!defect[1].answer);
                        if (defect[1].questionType === 'rating' && defect[1].include && !!defect[1].answer) {

                        }
                        return defect[1].questionType === 'rating' && defect[1].include && !!defect[1].answer ? defect : null
                    }).reduce((acc, val) => {
                            // // // // console.log('acc, val: ', acc, val)
                            return val ? [...acc, val[1]] : [...acc];
                        }, []);
                    return res;
                }).reduce((acc, val) => {
                    // // // // console.log('acc, val: ', acc, val)
                    return [...acc, ...val];
                }, []);
            }).reduce((acc, val) => {
                // // // // console.log('acc, val: ', acc, val)
                return [...acc, ...val];
            }, []);
        }
    }).reduce((acc, val) => {
        // // // // console.log('acc, val: ', acc, val)
        return val ? [...acc, ...val] : [...acc];
    }, []).filter(v => !!v);

    const level2Defects = notedDefects?.filter(defect => parseInt(defect.answer) === 1 && defect.section !== 'Structural Dampness');
    const level3Defects = notedDefects?.filter(defect => parseInt(defect.answer) === 2 && defect.section !== 'Structural Dampness');

    const internalCrackDefectText = 'Structural cracks vertical and/or diagonal are internal at the same location?'
    const crackDefectText = 'Structural cracks vertical and / or diagonal?'
    level2Defects.forEach((defect, i) => {
        if (defect.defect === crackDefectText)  {
            const overWriteWithDefect = data.sections['External Walls'].subsections[defect.subsection].buildingItems[defect.buildingItem].defects[internalCrackDefectText];
            if (overWriteWithDefect.answer?.toLowerCase() === 'yes') {
                console.log('overwriting with: ', overWriteWithDefect)
                defect.MBRT2 = overWriteWithDefect.MBRT2;
                defect.MBRT3 = overWriteWithDefect.MBRT3;
                defect.conclusion2 = overWriteWithDefect.conclusion2;
                defect.conclusion3 = overWriteWithDefect.conclusion3;
                defect.recommendation2 = overWriteWithDefect.recommendation2;
                defect.recommendation3 = overWriteWithDefect.recommendation3;
            }
            
        }
    })

    console.log('noted defects2: ', level2Defects)
    
    level3Defects.forEach((defect, i) => {
        if (defect.defect === crackDefectText)  {
            const overWriteWithDefect = data.sections['External Walls'].subsections[defect.subsection].buildingItems[defect.buildingItem].defects[internalCrackDefectText];
            if (overWriteWithDefect.answer?.toLowerCase() === 'yes') {
                console.log('overwriting with: ', overWriteWithDefect)
                defect.MBRT2 = overWriteWithDefect.MBRT2;
                defect.MBRT3 = overWriteWithDefect.MBRT3;
                defect.conclusion2 = overWriteWithDefect.conclusion2;
                defect.conclusion3 = overWriteWithDefect.conclusion3;
                defect.recommendation2 = overWriteWithDefect.recommendation2;
                defect.recommendation3 = overWriteWithDefect.recommendation3;
            }
            
        }
    })
    let sections = {};

    
   
    //const conclusions = getConclusions(notedDefects);

    const address = `${data.sections['Survey Details'].formValues.propertyAddress1}, ${!!data.sections['Survey Details'].formValues.propertyAddress2 ? data.sections['Survey Details'].formValues.propertyAddress2 + ', ': '' }${data.sections['Survey Details'].formValues.propertyCity}, ${data.sections['Survey Details'].formValues.propertyState}`;

    const twoColumnTableRow = (text1, text2, col1Width, signatureImage) => {

        const children = text2 === 'simon' || text2 === 'josh' ? 
            [signatureImage] : 
            [new Paragraph({
                children: [
                    new TextRun({
                        text: text2?.replace('\\n', '') || '',
                    }),
                    new TextRun({
                        text: ' ',
                        break: 1
                    })
                ].filter(v => !!v),
            
                style: "basic"
            })];
       

        return new TableRow({
            height: {
                value: 500,
                rule: HeightRule.ATLEAST
            },
            children: [
                new TableCell({
                    children: [new Paragraph({
                        text: text1,
                        style: "basic",
                    })],
                    width: {
                        size: col1Width,
                        type: WidthType.PERCENTAGE
                    },
                    borders: noBorders
                }),
                new TableCell({
                    children,
                    width: {
                        size: 100 - col1Width,
                        type: WidthType.PERCENTAGE
                    },
                    borders: noBorders
                }),
            ],
        })
    }
    const titlePage = async () => {
        return {
            properties: {},
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new ImageRun({
                            data: await logo.blob(),
                            transformation: {
                                width: 530,
                                height: 177,
                            },
                        }),
                    ],
                    style: "basic",
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    style: "basic",
                    children: [
                        new TextRun({
                            text: "Structural Survey (Inspection)",
                            ...textConfig
                        }),
                        new TextRun({
                            text: "to",
                            ...textConfig
                        }),
                        new TextRun({
                            text:  `${data.sections['Survey Details'].formValues.propertyAddress1}`,
                            ...textConfig
                        }),
                        data.sections['Survey Details'].formValues.propertyAddress2 ? new TextRun({
                            text:  `${data.sections['Survey Details'].formValues.propertyAddress2}`,
                            ...textConfig,
                            break: 1,
                        }) : null,
                        new TextRun({
                            text:  `${data.sections['Survey Details'].formValues.propertyCity}`,
                            ...textConfig,
                            break: 1,
                        }),
                        new TextRun({
                            text:  `${data.sections['Survey Details'].formValues.propertyState}`,
                            ...textConfig,
                            break: 1,
                        }),
                        new TextRun({
                            text:  `${data.sections['Survey Details'].formValues.propertyPostcode}`,
                            ...textConfig,
                            break: 1,
                        }),
                        // new TextRun({
                        //     text:  `${data.sections['Survey Details'].formValues.propertyCountry}`,
                        //     ...textConfig,
                        //     break: 1,
                        // }),
                        new TextRun({
                            text: ' ',
                            break: 18,
                        }),
                    ].filter(v => !!v),
                }),
                await imageParagraph(ssukInfo, spacing, 300, 150),
            ],
        }
    }
   

    const tableOfContents = () => {
        const spacing = {
            before: 200,
        }
        return {
            properties: {},
            children: [
                new Paragraph({
                    text: 'TABLE OF CONTENTS',
                    heading: HeadingLevel.HEADING_1,
                    spacing: {
                        ...spacing,
                        after: 200
                    }
                }),
                new Table({
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    rows: [
                        ["SECTION 1", "INTRODUCTION"],
                        ["SECTION 2", "SURVEY DETAILS"],
                        ["SECTION 3", "PROPERTY INFORMATION"],
                        ["SECTION 4", "STRUCTURAL SURVEY (INSPECTION) – GUIDE FOR CLIENTS"],
                        ["SECTION 5", "STRUCTURAL SURVEY (INSPECTION) – DETAILS"],
                        ["", "EXTERNAL CONDITION"],
                        ["", "INTERNAL CONDITION"],
                        ["SECTION 6", "CONCLUSIONS"],
                        ["SECTION 7", "RECOMMENDATIONS"],
                        ["SECTION 8", "GUIDE TO REPAIR COSTS"],
                        ["SECTION 8", "WHAT TO DO NOW"],
                        ["APPENDIX A", "TYPICAL HOUSE DIAGRAM"],
                    ].map(text => twoColumnTableRow(text[0], text[1], 20))
                })
            ]
        }
    }

    const executiveSummary = () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            color: "000000",
        };

        const propertyDescriptionText = () => {
            const getFloorText = (rooms, type) => {
                if (rooms?.length > 1) {
                    let str = type === 'ground' ? `a ` : '';
                    const last = rooms[rooms.length - 1];
                    const rest = rooms.slice(0, rooms.length - 1);
                    if (rest.length === 1) {
                        str = str + rest[0] + ' and ' + last;
                    } else {
                        rest.forEach((item, index) => {
                            if (index < rest.length - 1) {
                                str = str + item + ', ';
                            } else if (index === rest.length - 1) {
                                str = str + item + ' and ' + last;
                            }
                        })
                    }
                    // // // console.log('updated str: ', str)
                    return str + ` on the ${type} floor`;
                } else if (rooms?.length === 1) {
                    return rooms[0];
                }

                return null
            }
            let gfRooms = data.sections['Property Details'].formValues.groundFloorLayout;
            if (gfRooms.includes('Other'))  {
                gfRooms.push(data.sections['Property Details'].formValues.otherGf);
                gfRooms = gfRooms.filter(room => room !== 'Other');
            }
            let ffRooms = data.sections['Property Details'].formValues.firstFloorLayout;
            if (ffRooms.includes('Other'))  {
                ffRooms.push(data.sections['Property Details'].formValues.otherFf);
                ffRooms = ffRooms.filter(room => room !== 'Other');
            }
            const groundFloorText = getFloorText(gfRooms, 'ground');
            const firstFloorText = getFloorText(ffRooms, 'first');
            const descriptionString = firstFloorText ? groundFloorText + ", and " + firstFloorText : groundFloorText;

            return descriptionString?.toLowerCase();
        }
        let propType = data.sections['Property Details'].formValues.propertyType;
        if (propType === 'Other') {
            propType = data.sections['Property Details'].formValues.otherType;
        }
        const executiveSummary =  {
            properties: {},
            children: [
                new Paragraph({
                    text: 'EXECUTIVE SUMMARY',
                    heading: HeadingLevel.HEADING_1,
                }),
                new Paragraph({
                    text: `Structural Surveys UK were commissioned to undertake a ${data.sections['Survey Details'].formValues.surveyType} Structural Survey (Inspection) to a residential property at ${address}.`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text:  `The survey was carried out on a non-intrusive basis, the observations of which form the body of this report, together with the conclusions and recommendations for repair where applicable. Any defects that were hidden which could only be ascertained by intrusive works are not covered, in addition to areas that were inaccessible.`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: `The property is a ${propType.toLowerCase()} dwelling with ${data.sections['Property Details'].formValues.numberOfFloors} storeys, principally comprising of ${propertyDescriptionText()}.`,
                    spacing,
                    style: "basic",
                }), // needs work - sync up with dropdown options in survey
                new Paragraph({
                    text:  `The main items addressed externally included the roof coverings, load-bearing walls, damp courses, building ventilation and foundations.`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text:  `Our internal inspection included the roof void, ceilings, load-bearing walls, ground and suspended floors where applicable, together with an inspection on the presence of rising and/or penetrating damp in the property.`,
                    spacing,
                    style: "basic",
                }), // needs work - sync up with dropdown options in survey
                new Paragraph({
                    text:  `Having completed a structural survey to ${address} and following inspection / assessment of the roof profile and structural timber frameworks, load-bearing wall condition and alignment, ground and suspended floors, foundation performance and assessment of damp in the property we conclude the following (N.B. based on a non-intrusive inspection):`,
                    spacing,
                    style: "basic",
                }),
            ],
        }

        

        

        const bulletListDefects = (items) => {
            let isFirst = true;
    
            return new Paragraph({
                indent: {
                    left: convertInchesToTwip(0.25 ),
                },
                children:[
                    ...items.map((defect, i) => {
                        const text = parseInt(defect.answer) === 1 ? defect.conclusion2 : defect.conclusion3;
                        const content = text ? new TextRun({
                            text: `- ${addVars(text)}`?.replace(/\n/gm, ' ').replace(/\\n/gm, ' ').replaceAll(/\n/gm, ' '),
                            break: isFirst ? 1 : 2,
                        }) : null
                        if (!!text) {
                            isFirst = false;
                        }
                        return content;
                    }).filter(v => !!v),
                ],
                style: "basic",
            })
        }
        const addDefectsList = (defects, category) => {
            console.log('defect list, category: ', defects, category, defects[0].answer)
            if (defects?.length) {
                executiveSummary.children.push(
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: 'The Condition Rating ',
                            }),
                            new TextRun({
                                text: parseInt(defects[0].answer) + 1,
                                highlight: parseInt(defects[0].answer) === 1 ? "yellow" : "red",
                            }),
                            new TextRun({
                                text: category === '2' ? ' defects that will require remedial works to prevent further more problematic degradation occurring are as follows' : ' defects which are considered to be more urgent and requiring remedial works in a more timely manner are:',
                            }),
                        ],
                        //text:  `The Condition Rating ${parseInt(defects[0].answer) + 1} defects which are considered to be more urgent and requiring remedial works in a more timely manner are:`,
                        spacing,
                        style: "basic",
                    }),
                )
                const list = bulletListDefects(defects)
                executiveSummary.children.push(list)
            }
        }
        if (!!level2Defects?.length) {
            addDefectsList(level2Defects, '2');
        }
        if (!!level3Defects?.length) {
            addDefectsList(level3Defects, '3');
        }
        //[level2Defects, level3Defects].forEach(data => addDefectsList(data));

        return executiveSummary;
    }

    const introduction = () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const intro =  {
            properties: {},
            children: [
                new Paragraph({
                    text: '1. INTRODUCTION',
                    heading: HeadingLevel.HEADING_1,
                }),
                new Paragraph({
                    text: 'Structural Surveys UK are a group of highly skilled Chartered Civil & Structural Engineers who have acquired over 25 years experience in the design and construction of a wide range of buildings and facilities. We have also gained considerable experience in the structural inspection, appraisal, assessment and investigation of building structures, including commercial and residential properties.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: `This report details the results of a ${titleCase(data.sections['Survey Details'].formValues.surveyType)} Structural Survey (Inspection) to a residential property at ${address}. The scope of the report covers the main property only and is not inclusive of any further outbuildings or other structures unless agreed as part of the quotation process.`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'In accordance with your instructions we have completed the internal and external survey at the above property, the results of which are contained in Section 5.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Section 6 details the conclusions of the survey, indicating areas where remedial works are required.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Section 7 and 8 discuss the recommendations for these works and a guide to repair costs.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Section 9 provides guidance on what to do now.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Appendix A provides a 3D view of a typical house and the various building components listed to ease reader understanding.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: '1.1 Scope of the Report',
                    heading: HeadingLevel.HEADING_2,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The scope of our Structural Survey (Inspection) covers an assessment of 10 key ‘structural’ areas of a typical property, which if defects were identified would be the most costly to repair. These 10 areas include a structural inspection of the following:',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: `1) Roof Structure (external):`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    children: [
                        ...[   
                            'roof alignment / deformation / sagging)',
                            'roof tile coverage and condition',
                            'roof periphery (verge/eaves/hip/valley lines)',
                            'roof spread',
                            'soffit / fascia boards',
                            'chimney structures(s)',
                            'ventilation',
                            'flat roofs (where present)'
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })
                    ],
                    style: "basic",
                }),
                new Paragraph({
                    text: `2) Roof Structure (internal roof void)`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    children: [
                        ...[   
                            'structural timber framework (layout & missing / damaged timbers)',
                            'dry rot check',
                            'wet rot check',
                            'presence of woodworm',
                            'evidence of rainwater ingress',
                            'presence / condition of roof void insulation',
                            'roof void ventilation '
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })
                    ],
                    style: "basic",
                }),
                new Paragraph({
                    text: `3) External load-bearing walls`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'vertical and horizontal wall alignment',
                            'structural and non-structural cracks in masonry and / or render (where applicable)',
                            'missing / damaged / de-bonded render (where applicable)',
                            'cavity wall ties (where applicable) and presence of horizontal cracks',
                            'skewed / misaligned door / window openings',
                            'exposed masonry and mortar joints'
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `4) Internal load-bearing walls`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'vertical and horizontal wall alignment',
                            'structural and non-structural cracks in masonry and / or plaster (where applicable)',
                            'missing / damaged / defective plaster',
                            'cavity wall ties (where applicable) and presence of horizontal cracks',
                            'skewed / misaligned door / window openings'
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `5) Internal Ceiling Finishes`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'deformed /damaged / cracked ceiling surfaces',
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `6) Ventilation`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'confirm satisfactory ventilation to roof void, cavity walls, suspended ground floors (where applicable), internal / habitable rooms',
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `7) Structural Dampness (aided by the use of a damp meter)`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'presence of rising damp to base of walls and to ground floors',
                            'presence of penetrating damp through roof coverings and / or walls',
                            'presence of condensation build up',
                            'comment on the type of damp proof course / membrane and performance',
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `8) Foundations`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'settlement / subsidence / building movement / heave (based on the condition of the above ground structure, presence of cracks etc)',
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `9) Suspended Floors`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[   
                            'deformation / deflection / sloping of floor surface',
                            'floor vibration',
                            'presence of dry/wet rot (if available for inspection)',
                            'ventilation (suspended ground floors only)',
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: `10) Ground Floors`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [
                        ...[
                            'deformation / deflection / sloping of floor surface',
                            'cracks / spalling (if available for inspection)',
                            'subsidence to ground floors'
                        ].map((item, i) => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: i > 0 ? 1 : 0,
                            })
                        })]
                }),
                new Paragraph({
                    text: '1.2 Exclusions',
                    heading: HeadingLevel.HEADING_2,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `This report provides an assessment on the Structural Condition of the property only, concentrating on the 10 structural items noted above. This report does not cover a condition assessment of non-structural items (unless instructed to do so by the client as per our quotation), `,
                        }),
                        new TextRun({
                            text: `apart from those that may affect the performance of or provide further supporting evidence to the condition of the 10 structural items noted in 1.1.`,
                            underline: {type: "single"}
                        })
                    ],
                    style: "basic",
                    spacing
                }),
                new Paragraph({
                    text: `Clients generally have an understanding on whether they need to replace non-structural items, hence why we concentrate on the ‘structure’ only. This is of most importance when assessing the structural condition of the property / building as any defects noted to the 10 items listed above in 1.1 will provide an indication on how well it’s performing.`,
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Non-structural items not specifically covered (unless agreed with the client at point of instruction) include:',
                    spacing,
                    style: "basic",
                }),
                //
                new Paragraph({
                    indent: {
                        left: convertInchesToTwip(0.25),
                    },
                    style: "basic",
                    children: [   
                            'Services (e.g. gas, water, electrical)',
                            'Windows and doors (Window condition is only checked to support our assessment on whether any movement (settlement / heave) of the property has occurred, i.e. any consequential damage to the frames that prevents them being opened or closed. We do inspect the windows to confirm whether they provide an adequate means of internal ventilation and this is reported herein. Our report does not address the actual condition of the windows in terms of their acoustic and / or thermal performance. Any defects to the windows that have not been caused by structural movement of the property or are not associated with ventilation are out of scope of this report)',
                            'Internal joinery (e.g. staircases, coving, skirting, doors)',
                            'Insulation (to roof void, walls, floors)',
                            'Chimney Flues (N.B. the chimney structure is included in our report if a chimney is present)',
                            'Fixtures and fittings (e.g. bathrooms, kitchens)',
                            'Rainwater pipes and guttering (unless these are defective and are creating damp in the walls)',
                            'Below ground drainage inspection (manholes, pipes, soakaways, septic tanks)',
                            'Presence of contaminated materials (e.g. asbestos)'
                        ].map(item => {
                            return new TextRun({
                                text: `- ${item}`,
                                break: 1,
                            })
                        })
                }),
                new Paragraph({
                    text: 'The report is based on the condition of the property on the date of inspection and no liability can be accepted for any deterioration in the condition of the property after this date. The structural condition of the property is based on the expert judgement of a Chartered Civil / Structural Engineer, taking account of the outcome of the inspection and any defects identified during the survey, ascertaining the history of the property from the current owners where such information is available (e.g. any structural modifications, building works, previous defects and repair works undertaken etc.) and also performing any relevant searches if required (e.g. coal mining for subsidence, ascertaining local geology) or desk top studies that may have influenced the buildings performance.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'In addition, the report contents are based on a non-intrusive survey of the building as presented. No liability can therefore be accepted for defects that were hidden and not accessible, which could only have been noted from intrusive investigation or where physical access was precluded (e.g. working at height due to health and safety reasons).',
                    spacing,
                    style: "basic",
                })

            ],
        }

        return intro
    }

    const surveyDetails = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const sdData = data.sections['Survey Details'].formValues;
        const address = `${sdData.propertyAddress1}, ${sdData.propertyCity}, ${sdData.propertyState}, ${sdData.propertyPostcode} `
        const clientAddress = `${sdData.clientAddress1}, ${sdData.clientAddress2 ? sdData.clientAddress2 + ', ' : '' }${sdData.clientCity}, ${sdData.clientState}, ${sdData.clientPostcode}`
        const getSignature = async () => {
            
        }
        let signatureImage;
        if (sdData.signed === 'simon' || sdData.signed === 'josh') {
            const signatureFile = sdData.signed === 'simon' ? simonSignature : joshSignature;
            signatureImage = await imageParagraph(signatureFile, 0, 160, 40, '', true, true);
        }
        const surveyDetailsContent =  {
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: '2. SURVEY DETAILS',
                        })
                    ],
                    style: "basic",
                    spacing: {
                        after: 300
                    },
                    heading: HeadingLevel.HEADING_1,
                }),
                new Table({
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },

                    rows: [
                        ["Property address", address || ''],
                        ["Property occupancy", titleCase(sdData.occupancy) || ''],
                        ["Client name", sdData.clientName || ''],
                        ["Client address", clientAddress || ''],
                        ["Property agent", sdData.agentName === 'Other' ? sdData.otherAgent : sdData.agentName || ''],
                        ["Buyers' solicitor", sdData.buyersSolicitor || 'Not disclosed'],
                        ["Type of survey instructed", titleCase(sdData.surveyType) || ''],
                        ["Date of inspection", sdData.dateOfInspection?.split('-').reverse().join('-') || ''],
                        ["Weather conditions", sdData.weather === 'Other' ? sdData.otherWeather : sdData.weather || ''],
                        ["Our engineer", sdData.engineer || ''],
                        ["Reviewed/approved by", sdData.reviewedBy || ''],
                        ["Signed", sdData.signed || ''],  //signature
                        ["Office Number", "0333 577 2655"],
                        ["Office Email", 'info@structural-survey.com'],
                    ].map(text => twoColumnTableRow(text[0], text[1], 35, signatureImage))
                }),
                new Paragraph({
                    text: 'The SSUK Team will be pleased to talk to you about your report and answer any questions you may have.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    style: "basic",
                    children: [
                        new TextRun({
                            text: 'Copyright : This report has been produced solely for the client noted above and remains the property of Structural Surveys UK. Its contents cannot be otherwise shared, transferred, sold or used by a third party (apart from those organisations facilitating the sale/purchase of the property being surveyed on behalf of our client as noted above, e.g. mortgage lender, solicitor, agent) without the prior written permission of Structural Surveys UK, as set out by the Copyright, Designs and Patents Act 1988.  Our report cannot be used for any purpose by any third party (e.g. mortgage lender, solicitor, agent) to assist the sale for any person(s) other than the client specifically noted above.',
                            bold: true,
                            color: '991d17'
                        })
                    ],
                    //text: 'Copyright : This report has been produced solely for the client noted above and remains the property of Structural Surveys UK. Its contents cannot be otherwise transferred, sold or used by a third party without the prior written permission of Structural Surveys UK, as set out by the Copyright, Designs and Patents Act 1988.',
                    spacing
                }),
            ],
        }							

        return surveyDetailsContent;
    }

    const propertyDetails = () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const pdData = data.sections['Property Details'].formValues;
        
        const propertyDetailsContent =  {
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun({
                            text: '3. PROPERTY DETAILS',
                        })
                    ],
                    spacing: {
                        after: 300
                    },
                    heading: HeadingLevel.HEADING_1,
                }),
                new Table({
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    rows: [
                        ["Property type", pdData.propertyType === 'Other' ? pdData.otherType : pdData.propertyType || ''],
                        ["Number of floors", pdData.numberOfFloors || ''],
                        ["Ground floor layout", pdData.groundFloorLayout?.filter(item => item !== 'Other')?.join(', ') || ''],
                        ["First floor layout", pdData.firstFloorLayout?.filter(item => item !== 'Other')?.join(', ') || ''],
                        ["Age of property", pdData.ageOfProperty ? pdData.ageOfProperty + ' (N.B. this is an approximation only based on form of construction and condition. Please discuss with your solicitor who should be able to confirm the exact property age via the title deeds).\\n' : ''],
                        // ["Orientation of property", pdData.orientationOfProperty || ''],
                        ["Extensions present", pdData.hasExtensions || ''],
                        ["Extension storeys", pdData.extensionStoreys || ''],
                        ["Extension location", pdData.extensionLocation || ''],
                        ["Roof structure", pdData.roofStructure || ''],
                        ["Pitch roof type", pdData.pitchRoofType || ''],
                        ["Hipped roof type", pdData.hippedRoofType || ''],
                        ["Roof pitch", pdData.roofPitch || ''],
                        ["Main roof tiles", pdData.mainRoofTiles || ''],
                        ["Roof felt", pdData.roofFelt || ''],
                        ["Extension roof covering", pdData.extensionRoofCovering || ''],
                        ["Extension roof type", pdData.extensionRoofType || ''],
                        // ["Main wall construction", pdData.mainWallConstruction || ''],
                        // ["Solid render details", pdData.solidRenderDetails || ''],
                        // ["Solid wall details", pdData.solidWallsDetails || ''],
                        // ["Extension wall construction", pdData.extensionWallConstruction || ''],
                        // ["External wall finish", pdData.externalWallFinishes || ''],
                        // ["Extension external wall finish", pdData.extensionExternalWallFinish || ''],
                        ["Wall construction and finish", pdData.externalWallTypeAndFinishDescription ?  `${pdData.externalWallTypeAndFinishDescription[0].toUpperCase()}${pdData.externalWallTypeAndFinishDescription.slice(1).toLowerCase()}` : ''],
                        ["Ground floor construction", pdData.groundFloorConstruction === 'Other' ? pdData.otherFloor : pdData.groundFloorConstruction|| ''],
                        ["Fascia board material", pdData.fasciaBoardMaterial?.join(', ') || ''],
                        ["Soffit material", pdData.soffitMaterial?.join(', ') || ''],
                        ["Window and door frames", pdData.windowDoorFrames?.join(', ') || ''],
                        ["Insulation", pdData.insulation || ''],
                        ["Number of chimneys", pdData.numberChimneys || 'None'],
                        ["Chimney construction", pdData.chimneyConstruction || ''],
                        ["Foundations", pdData.foundations || ''],
                        // ["Internal wall finish", pdData.internalWallFinish || ''],
                        ["Internal wall finish", 'For properties which date from post the 1930s, then plaster board would be expected to be present to wall and ceiling finishes. For older properties (which have not been refurbished since original build, then lathe and plaster finishes would be anticipated).'],
                        
                    ].filter(item => !!item[1]).map(text => twoColumnTableRow(text[0], text[1], 35))
                }),
            ],
        }							

        return propertyDetailsContent;
    }


    const guideForClients = () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const col1Width = 35;
        const guide =  {
            properties: {},
            children: [
                new Paragraph({
                    text: '4. STRUCTURAL SURVEY (INSPECTION) - GUIDE FOR CLIENTS ',
                    heading: HeadingLevel.HEADING_1,
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'Types of Survey',
                            underline: {type: "single"}
                        })
                    ],
                    style: "basic",
                    spacing
                }),
                new Paragraph({
                    text: 'The Construction Industry Council (CIC) has produced a highly informative leaflet entitled \'Definitions of Inspections and Surveys of Buildings\' clarifying the terminology used in conjunction with the various kinds of survey. The term \'Structural Survey\' has come into common use to describe a survey which covers all visible and accessible parts of a building.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Eleven different types of \'survey\' are described by the CIC and the one which is discussed with here is a \'building survey\' which is defined as .......an investigation and assessment of the construction and condition of a building and will not normally include advice on value. The survey will generally include the structure, fabric, finishes and grounds; the exposure and testing of services are not usually covered.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The extent of the survey will be subject to specific agreement between the professional and the client. The report will include reference to visible defects and guidance as appropriate on maintenance and remedial measures.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The current differences in survey nomenclature are further set out in \'Guide to Surveys and Inspections of Buildings & Similar Structures\' published by the Institution of Structural Engineers. In consequence, the term ‘Structural Survey Report’ has been adopted as a preference when preparing reports of this nature.',
                    spacing,
                    style: "basic",
                }),

                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'Purpose of Survey',
                            underline: {type: "single"}
                        })
                    ],
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The primary purpose of a survey is to give an independent professional opinion on the structural condition of a property.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The word \'independent\' requires further explanation. In the strict sense of the term the engineer carrying out the survey is acting on behalf of his client, the purchaser, and therefore cannot be wholly independent as he serves the purchaser\'s interests. However, the main object of such a survey is to ensure that an opinion is given by someone who has no connection with the vendor or their agents.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Those who are best qualified to carry out such work will have considerable experience in the performance of buildings and their components as well as their design and construction. This would suggest that a Chartered Civil / Structural Engineer would be best suited to this type of work.',
                    spacing,
                    style: "basic",
                }),

                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'Clients\' instructions',
                            underline: {type: "single"}
                        })
                    ],
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'In the first instance the client should provide the engineer with the address and a general description of the property concerned. In particular, the type of property, its age, use, size and the means of access thereto should be noted. ',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'In addition, the client should stipulate any specific requirements for the survey, e.g. if a known defect exists that requires further investigation.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The client should be advised of the method by which the engineer’s fees will be calculated. Sometimes it is possible to give an approximate cost if the engineer has knowledge of the property concerned, or if the description by the client is sufficient for the engineer to assess the extent of the work which will be required. ',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'For many years the Royal Institute of Chartered Surveyors attempted to complete a scale rate of charges for building surveys, but this in practice proved very difficult. The reasons are manifold but the principal difficulty is that more time and trouble can easily be spent on a small two up and two down cottage in a bad state of repair, than on a large four or five bedroom dwelling which is of modern construction and in good order.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'Generally, fees are charged on an hourly rate.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The client must be advised that the engineer is a \'General Practitioner\' and will not provide specialist reports on drainage, heating systems or electrical installations. A broad opinion usually based on visual observation is given in the report but if the client requires more detailed information then the engineer must advise specialist tests and reports which will involve additional costs.',
                    spacing,
                    style: "basic",
                }),

                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'Condition Rating',
                            underline: {type: "single"}
                        })
                    ],
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'To help describe the condition of the property, the engineer gives ‘Condition Ratings’ to the main parts (or elements) of the building. Some elements can be made up of different parts. For example, a home can have a pitched (sloped) roof to the main buildings and a flat roof to an extension. In this case, the engineer will give each a condition rating.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The condition ratings are described as follows:',
                    spacing,
                    style: "basic",
                }),
                new Table({
                    rows: [
                        new TableRow({
                            height: {
                                value: 500,
                                rule: HeightRule.ATLEAST
                            },
                            children: [
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: '- Condition Rating ',
                                                break: 1
                                            }),
                                            new TextRun({
                                                text: '3',
                                                highlight: "red",
                                            }),
                                            new TextRun({
                                                text: ':',
                                            }),
                                        ],
                                        style: "basic",
                                    })],
                                    width: {
                                        size: col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Defects which are considered to be more urgent and requiring remedial works in a more timely manner.',
                                                break: 1
                                            }),
                                        ],
                                        style: "basic"})],
                                    width: {
                                        size: 100 - col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                            ],
                        }),

                        new TableRow({
                            height: {
                                value: 500,
                                rule: HeightRule.ATLEAST
                            },
                            children: [
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: '- Condition Rating ',
                                                break: 1
                                            }),
                                            new TextRun({
                                                text: '2',
                                                highlight: "yellow",
                                            }),
                                            new TextRun({
                                                text: ':',
                                            }),
                                        ],
                                        style: "basic",
                                    })],
                                    width: {
                                        size: col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Defects that need repairing or replacing but are not considered to be serious or urgent. The property must be maintained in the normal way.',
                                                break: 1
                                            }),
                                        ],
                                        style: "basic"})],
                                    width: {
                                        size: 100 - col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                            ],
                        }),

                        new TableRow({
                            height: {
                                value: 500,
                                rule: HeightRule.ATLEAST
                            },
                            children: [
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: '- Condition Rating ',
                                                break: 1
                                            }),
                                            new TextRun({
                                                text: '1',
                                                highlight: "green",
                                            }),
                                            new TextRun({
                                                text: ':',
                                            }),
                                        ],
                                        style: "basic",
                                    })],
                                    width: {
                                        size: col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                break: 1,
                                                text: 'No structural repair is currently needed. The property must be maintained in the normal way.',
                                            }),
                                        ],
                                        style: "basic"})],
                                    width: {
                                        size: 100 - col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                            ],
                        }),

                        new TableRow({
                            height: {
                                value: 500,
                                rule: HeightRule.ATLEAST
                            },
                            children: [
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: '- NI:',
                                                break: 1
                                            }),
                                        ],
                                        style: "basic",
                                    })],
                                    width: {
                                        size: col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                                new TableCell({
                                    children: [new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Not inspected (See ‘Important Note’ below)',
                                                break: 1
                                            }),
                                        ],
                                        style: "basic"})],
                                    width: {
                                        size: 100 - col1Width,
                                        type: WidthType.PERCENTAGE
                                    },
                                    borders: noBorders,
                                    verticalAlign: VerticalAlign.TOP,
                                }),
                            ],
                        })
                    ]
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'Important Note',
                            underline: {type: "single"}
                        })
                    ],
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The engineer will only carry out a visual inspection. This means that they do not take up carpets, floor coverings or floorboards, move furniture or remove the contents of cupboards. Also, they do not remove secured panels or undo electrical fittings.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The engineer will inspect roofs, chimneys, and other surfaces on the outside of the building from ground level and, if necessary, from neighbouring public property and with the help of binoculars.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'They will inspect the roof structure from inside the roof space if there is access (although they will not move or lift insulation material, stored goods or other contents).',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'They will examine floor surfaces and under-floor spaces so far as there is access to these (although the engineer will not move or lift furniture, floor coverings or other contents). The engineer is not able to access the condition of the inside of any chimney, boiler or other flues.',
                    spacing,
                    style: "basic",
                }),
                new Paragraph({
                    text: 'The engineer will note in their report if they were not able to check any parts of the property that the inspection would normally cover. If the engineer is concerned about these parts, the report tells you about any further investigations that are needed.',
                    spacing,
                    style: "basic",
                }),
            ],
        }

        return guide
    }

    const getImagePlaceholderTable = (caption) => {
        const borders = {
            top: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#bfbfbf",
            },
            bottom: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "#ffffff",
            },
            left: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "#ffffff",
            },
            right: {
                style: BorderStyle.SINGLE,
                size: 0,
                color: "#ffffff",
            },
        };

        return new Table({
            rows: [
                new TableRow({
                    height: {
                        value: 100,
                        rule: HeightRule.ATLEAST
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE
                    },
                    children: [
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({text: ' '}),
                                ],
                                style: "basic",
                            })],
                            width: {
                                size: 7,
                                type: WidthType.PERCENTAGE
                            },
                            borders: noBorders,
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({
                                        text: caption,
                                        break: 1,
                                        italics: true
                                    }),
                                ],
                                style: "basic",
                                alignment: AlignmentType.CENTER,
                            })],
                            width: {
                                size: 86,
                                type: WidthType.PERCENTAGE
                            },
                            borders,
                            verticalAlign: VerticalAlign.TOP,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                children: [
                                    new TextRun({text: ' '}),
                                ],
                                style: "basic",
                            })],
                            width: {
                                size: 7,
                                type: WidthType.PERCENTAGE
                            },
                            borders: noBorders,
                            verticalAlign: VerticalAlign.TOP,
                        }),
                    ],
                }),
            ],
            height: {
                value: 500,
                rule: HeightRule.ATLEAST
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            layout: TableLayoutType.FIXED,
        })
    }

    const pageBreakParagraph = () => new Paragraph({
        children: [
            new PageBreak(),
        ],
    });

    const formatImagePlaceholders = (defect) => { // marked
        const content = [pageBreakParagraph()]; //[new PageBreak()];
        defect[1].pictureCaption.forEach((caption, i) => {
            content.push(getImagePlaceholderTable(caption));
            content.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ' ',
                        break: 1,
                        italics: true
                        }),
                    ],
                alignment: AlignmentType.CENTER,
                style: "basic",
            }));

            if (!!i && i % 2 !== 0 && i !== defect[1].pictureCaption.length - 1) {
                content.push(pageBreakParagraph());
            }
        })

        return content;
    }

    const formatItemContent = (prefix, item, spacing, defects) => {
        return [
            new Paragraph({
                style: "basic",
                children: [
                    new TextRun({
                        text: `${prefix}${item[0]}`,
                        underline: {type: "single"}
                    }),
                ],
                spacing
            }),
            ...defects
        ]
    }

    const formatDefectContent = (text, defect, color, spacing, isDampSection = false) => {
        let textText;
        if (containsLineBreak(text)) {
            textText = text.split('\\n').map((line, i) => {
                return new TextRun({
                    text: !line.includes('\\n') ? line : '',
                    color,
                    break: 1 //i > 0 ? 1: 0
                })
            });
        } else {
            textText = [new TextRun({
                    text: text,
                    color,
                    break: defect[1].flag ? 1 : 0
                })]
            ;
        }

        let imagePlaceholders = [];
        if (defect[1].pictureTaken) {
            imagePlaceholders = formatImagePlaceholders(defect);
        }

        const defectParagraphs = [];

        if (defect[1]?.include) {
            if (defect[1].flag) {
                defectParagraphs.push(new Paragraph({
                    children: [
                        new TextRun({
                            text: '[ FLAGGED BY USER ]',
                            color,
                        }),
                        defect[1].note ? 
                        new TextRun({
                            text: ' ' + defect[1].note,
                            color,
                            italics: true
                        }) : null,
                    ].filter(v => !!v),
                    spacing,
                    style: "basic",
                }))
            } 
            if (!!text) {
                defectParagraphs.push(new Paragraph({
                    children: textText,
                    spacing,
                    style: "basic",
                }));
            }
            if (defect[1].defect.includes('indication of wall tie failures')) {
                defectParagraphs.push(cavityWallTieImage);
            }
            if (!!imagePlaceholders.length) {
                defectParagraphs.push(...imagePlaceholders);
            }
           
        }

        return defectParagraphs;
    }

    //const getDefectText =
    const formatBuildingItemOutput = ({item, spacing, prefix = '', filterType, type}) => {
        console.log('ITEM in format B.I: ', item)
        console.log('filterType: ', filterType)
         //const defects = [];
         const defects = Object.entries(item[1].defects).map(defect => {
            console.log('defect: ', defect);
            let text;
            if (!filterType || (filterType && defect[1].filterBy?.includes(filterType))) { // poss pass a 'useFilter' boolean in case filter is undefined, so it still checks.
                switch (defect[1].questionType) {
                    case 'rating':
                        switch(parseInt(defect[1].answer)) {
                            case 0:
                                text = addVars(defect[1].MBRT1);
                                break;
                            case 1:
                                console.log('answer is 1: ', parseInt(defect[1].answer));
                                text = addVars(defect[1].MBRT2);
                                break;
                            case 2:
                                text = addVars(defect[1].MBRT3);
                                break;
                            case 3:
                                text = "Not inspected"
                                break;
                            default:
                                break;
                        }
                        break;

                    case 'yesNo':
                        switch(defect[1].answer) {
                            case 'Yes':
                                text = addVars(defect[1].MBRT1)
                                break;
                            case 'No':
                                text = addVars(defect[1].MBRT2)
                                break;
        
                            default:
                                break;
                        }
                        break;
                    case 'freeText':
                        text = defect[1].answer || '';
                        break;
                    
                    default:
                        break;
                }
            }
            const color = defect[1].flag || defect[1].questionType === 'freeText' ? '991d17': defaultTextColor;
            return formatDefectContent(text, defect, color, spacing);
         }).reduce((acc, cur) => [...acc, ...cur], []).filter(v => !!v)
 
         const itemContent = defects.length ? formatItemContent(prefix, item, spacing, defects) : [];
         return itemContent;
     }

    const formatRatingYesNoAndFreeTextBuildingItem = (item, spacing, prefix = '') => { // cant merge yet due to yes-no mismatch report text
        const defects = Object.entries({...item[1].defects}).map(defect => {
            let text;
            if (defect[1].questionType === 'rating') {
                switch(parseInt(defect[1].answer)) {
                    case 0:
                        
                        text = defect[1].MBRT1
                        break;
                    case 1:
                        text = defect[1].MBRT2
                        break;
                    case 2:
                        text = defect[1].MBRT3
                        break;
                    case 3:
                        text = "Not inspected"
                        break;
                    default:
                        break;
                }
            } else if (defect[1].questionType === 'yesNo') {
                switch(defect[1].answer) {
                    case "Yes":
                        text = defect[1].MBRT2
                        break;
                    case "No":
                        text = defect[1].MBRT3
                        break;

                    default:
                        break;
                }
            } else if (defect[1].questionType === 'freeText' && defect[1].answer) {
                text = defect[1].answer
            }

            const color = defect[1].flag || defect[1].questionType === 'freeText' ? '991d17': defaultTextColor;

            return formatDefectContent(text, defect, color, spacing);
        }).reduce((acc, cur) => [...acc, ...cur], []).filter(v => !!v)

        const itemContent = defects.length ? formatItemContent(prefix, item, spacing, defects) : [];

        return itemContent;
    }

   

   

    const formatDampRatingAndFreeTextBuildingItem = (item, spacing, filterType, type, prefix = '') => {
        // // console.log('format damp item:', item)
        const defects = Object.entries(item[1].defects).map(defect => {
            // // console.log('format damp defect:', filterType, defect[1],)
            let text;
            if (defect[1].filterBy?.includes(filterType)) {
                if (defect[1].questionType === 'rating') {
                    switch(parseInt(defect[1].answer)) {
                        case 0:
                            
                            text = defect[1].MBRT1
                            break;
                        case 1:
                            text = defect[1].MBRT2
                            break;
                        case 2:
                            text = defect[1].MBRT3
                            break;
                        case 3:
                            text = "Not inspected"
                            break;
                        default:
                            break;
                    }
                } else if (defect[1].questionType === 'freeText' && defect[1].answer) {
                    text = defect[1].answer;
                }
            } else if (defect[1].defect.includes('Any other defects') && item[0] === type) {
                text = defect[1].answer;
            }
            
            const color = defect[1].flag || defect[1].questionType === 'freeText' ? '991d17': defaultTextColor;

            return formatDefectContent(text, defect, color, spacing);
        }).reduce((acc, cur) => [...acc, ...cur], []).filter(v => !!v);

        const itemContent = defects.length ? 
        formatItemContent(prefix, item, spacing, defects) : [];
        // // console.log('item content: ',itemContent)
        return itemContent;
    }

    const formatRoofRatingYesNoAndFreeTextBuildingItem = (item, spacing, prefix = '', skipHeader = false) => {
        const defects = Object.entries(item[1].defects).map(defect => {
            let text;

            if (defect[1].questionType === 'rating') {
                switch(parseInt(defect[1].answer)) {
                    case 0:
                        text = defect[1].MBRT1
                        break;
                    case 1:
                        text = defect[1].MBRT2
                        break;
                    case 2:
                        text = defect[1].MBRT3
                        break;
                    case 3:
                        text = "Not inspected"
                        break;
                    default:
                        break;
                }
            } else if (defect[1].questionType === 'freeText' && defect[1].answer) {
                text = defect[1].answer
            } else if (defect[1].questionType === 'yesNo') {
                switch(defect[1].answer) {
                    case "Yes":
                        text = defect[1].MBRT3
                        break;
                    case "No":
                        break;
                    default:
                        break;
                }
            }
            
            const color = defect[1].flag || defect[1].questionType === 'freeText' ? '991d17': defaultTextColor;

            return formatDefectContent(text, defect, color, spacing);
        }).reduce((acc, cur) => [...acc, ...cur], []).filter(v => !!v);
            

        const itemContent = defects.length ? 
        [new Paragraph({
                style: "basic",
                children: [
                    !skipHeader ? new TextRun({
                        text: `${prefix}${item[0]}`,
                        underline: {type: "single"}
                    }) : null,
                ].filter(v => !!v),
                spacing
            }),
            ...defects
        ] : [];

        return itemContent;
    }

    const formatTileCoverageText = (spacing) => {
        let tileDamageText = '';
        let wornTilesText = '';
        let tileVegetationText = '';
        let defectiveFlashingText = '';
        const coverageBI = data.sections['External Roof'].subsections['Check Roof Tile Coverage'].buildingItems;
        console.log('coverageBI: ', coverageBI);
        const notes = [];
        let hasFlags = false;
        Object.entries(coverageBI).forEach(item => {
            Object.entries(item[1].defects).forEach(defect => {
                if (defect[1].flag) {
                    hasFlags = true;
                    if (defect[1].note) {
                        notes.push(new TextRun({
                            text: ` ${defect[1].note}`,
                            color: '991d17',
                            italics: true,
                            break: 1
                        }))
                    }
                }
            })
        })
                    

        const frontRoofSurface = coverageBI['Front Roof Surface'].defects;
        const sideRoofSurface = coverageBI['Side/Gable Roof Surface'].defects;
        const rearRoofSurface = coverageBI['Rear Roof Surface'].defects;
        const tileDamageDefects = [
            'Missing tiles?',
            'Slipped tiles?',
            'Cracked / defective tiles?'
        ]
        // // console.log(frontRoofSurface);
        // // console.log(sideRoofSurface);
        // // console.log(rearRoofSurface);
        const tcd = {
            front: {
                tileDamage: {
                    1: Object.values(frontRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 0),
                    2: Object.values(frontRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 1),
                    3: Object.values(frontRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 2)
                },
                worn: {
                    1: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 0),
                    2: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 1),
                    3: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 2)
                },
                vegetation: {
                    1: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 0),
                    2: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 1),
                    3: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 2)
                },
                flashing: {
                    1: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 0),
                    2: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 1),
                    3: Object.values(frontRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 2)
                },
            },
            side: {
                tileDamage: {
                    1: Object.values(sideRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 0),
                    2: Object.values(sideRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 1),
                    3: Object.values(sideRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 2)
                },
                worn: {
                    1: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 0),
                    2: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 1),
                    3: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 2)
                },
                vegetation: {
                    1: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 0),
                    2: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 1),
                    3: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 2)
                },
                flashing: {
                    1: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 0),
                    2: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 1),
                    3: Object.values(sideRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 2)
                },
            },
            rear: {
                tileDamage: {
                    1: Object.values(rearRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 0),
                    2: Object.values(rearRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 1),
                    3: Object.values(rearRoofSurface).filter(defect => tileDamageDefects.includes(defect.defect) && parseInt(defect.answer) === 2)
                },
                worn: {
                    1: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 0),
                    2: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 1),
                    3: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('worn') && parseInt(defect.answer) === 2)
                },
                vegetation: {
                    1: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 0),
                    2: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 1),
                    3: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('vegetation') && parseInt(defect.answer) === 2)
                },
                flashing: {
                    1: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 0),
                    2: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 1),
                    3: Object.values(rearRoofSurface).filter(defect => defect.defect?.toLowerCase()?.includes('flashing') && parseInt(defect.answer) === 2)
                },
            }
        }
        // // console.log(tcd);
        const noTileDamage = () => {
            return !tcd.front.tileDamage[2]?.length 
                && !tcd.front.tileDamage[3]?.length
                && !tcd.side.tileDamage[2]?.length
                && !tcd.side.tileDamage[3]?.length
                && !tcd.rear.tileDamage[2]?.length
                && !tcd.rear.tileDamage[3]?.length
        }
        // // console.log('no tile dmage? ', noTileDamage())
        if (noTileDamage()) {
            tileDamageText = 'The tile covering is presently complete throughout with no obvious indication of missing, slipped or damaged units.'
        } else {
            const cat2TileDamageAreas = [];
            const cat2TileDamageTypes = [];
            Object.entries(tcd).forEach(area => {
                if (area[1].tileDamage[2]?.length) {
                    cat2TileDamageAreas.push(area[0]);
                    area[1].tileDamage[2].forEach(defect => {
                        cat2TileDamageTypes.push(defect.defect.split(' tiles')[0].toLowerCase()?.trim());
                    })
                }
            });
            // // console.log('tileDmage data 2: ', cat2TileDamageAreas, cat2TileDamageTypes)
            const cat3TileDamageAreas = [];
            const cat3TileDamageTypes = [];
            Object.entries(tcd).forEach(area => {
                if (area[1].tileDamage[3]?.length) {
                    cat3TileDamageAreas.push(area[0]);
                    area[1].tileDamage[3].forEach(defect => {
                        cat3TileDamageTypes.push(defect.defect.split(' tiles')[0].toLowerCase()?.trim());
                    })
                }
            });
            // // console.log('tileDmage data 3: ', cat3TileDamageAreas, cat3TileDamageTypes)
            const tileDamageTypes = [...new Set([...cat2TileDamageTypes, ...cat3TileDamageTypes])];
            const tileDamageAreas = [...new Set([...cat2TileDamageAreas, ...cat3TileDamageAreas])];
            
            roofTileDamageAreaText = getCommaListText(tileDamageAreas);
            roofTileDamageTypes = getCommaListText(tileDamageTypes);
            tileDamageText = `Inspection of the ${roofTileDamageAreaText} roof profiles confirmed roof tiles that were ${roofTileDamageTypes} and will need to be reinstated / replaced to maintain the primary weather-proof barrier of the external roof surface.`
        }

        const noWornTiles = () => {
            return !tcd.front.worn[2]?.length 
                && !tcd.front.worn[3]?.length
                && !tcd.side.worn[2]?.length
                && !tcd.side.worn[3]?.length
                && !tcd.rear.worn[2]?.length
                && !tcd.rear.worn[3]?.length
        }
        // // console.log('no worn tiles? ', noWornTiles());
        ageOfTiles = data.sections['External Roof'].subsections['Check Roof Tile Coverage'].buildingItems['Age Of Tiles'].defects['Estimated age of tiles?'].answer;
        remainingLifespanOfTiles = data.sections['External Roof'].subsections['Check Roof Tile Coverage'].buildingItems['Age Of Tiles'].defects['Estimated remaining lifespan of tiles?'].answer;

        if (noWornTiles()) {
            wornTilesText = addVars(frontRoofSurface['Worn / weathered tiles that need replacing?'].MBRT1);
        } else {
            let maxCat = 2;
            Object.entries(tcd).forEach(area => {
                if (area[1].worn[3]?.length) {
                    maxCat = 3;
                }
            });
            if (maxCat === 2) {
                wornTilesText = addVars(frontRoofSurface['Worn / weathered tiles that need replacing?'].MBRT2);
            } else if (maxCat === 3) {
                wornTilesText = addVars(frontRoofSurface['Worn / weathered tiles that need replacing?'].MBRT3);
            }
        }
        // // console.log(wornTilesText);


        const noVegetation = () => {
            return !tcd.front.vegetation[2]?.length 
                && !tcd.front.vegetation[3]?.length
                && !tcd.side.vegetation[2]?.length
                && !tcd.side.vegetation[3]?.length
                && !tcd.rear.vegetation[2]?.length
                && !tcd.rear.vegetation[3]?.length
        }
        // // console.log('no veg? ', noVegetation());
        if (noVegetation()) {
            tileVegetationText = frontRoofSurface['Vegetation / moss growth?'].MBRT1;
        } else {
            let maxCat = 2;
            let roofAreas = [];
            Object.entries(tcd).forEach(area => {
                if (area[1].vegetation[3]?.length) {
                    roofAreas.push(area[0]);
                    maxCat = 3;
                } else if (area[1].vegetation[2]?.length) {
                    roofAreas.push(area[0]);
                    maxCat = 2;
                }
            });//tileDamageText = `Inspection of the ${getCommaListText(tileDamageAreas)} roof profiles confirmed roof tiles that were ${getCommaListText(tileDamageTypes)} and will need to be reinstated / replaced to maintain the primary weather-proof barrier of the external roof surface.`
            roofAreas = getCommaListText([...new Set(roofAreas)]);
            if (maxCat === 2) {
                tileVegetationText = frontRoofSurface['Vegetation / moss growth?'].MBRT2.replace('[roofAreas]', roofAreas);
            } else if (maxCat === 3) {
                tileVegetationText = frontRoofSurface['Vegetation / moss growth?'].MBRT3.replace('[roofAreas]', roofAreas);
            }
        }
        // // console.log(tileVegetationText);

        const flashingIsFine = () => {
            return !tcd.front.flashing[2]?.length 
                && !tcd.front.flashing[3]?.length
                && !tcd.side.flashing[2]?.length
                && !tcd.side.flashing[3]?.length
                && !tcd.rear.flashing[2]?.length
                && !tcd.rear.flashing[3]?.length
        }
        // // console.log('flashing all ok? ', flashingIsFine());
        if (flashingIsFine()) {
            defectiveFlashingText = frontRoofSurface['Defective / missing lead flashing?'].MBRT1;
        } else {
            defectiveFlashingText = frontRoofSurface['Defective / missing lead flashing?'].MBRT2;
        }
        // // console.log(defectiveFlashingText);

        const tdt = [
            new Paragraph({
                children: [
                    new TextRun({
                        text: 'Roof Surface',
                        underline: {type: "single"}
                    }),
                ],
                spacing,
                style: "basic",
            }),
            hasFlags ? new Paragraph({
                style: "basic",
                children: [
                    new TextRun({
                        text: '[ SECTION FLAGGED BY USER ]',
                        color: '991d17',
                        break: 1,
                    }),
                    ...notes
                ].filter(v => !!v),
            }) : null,
            new Paragraph({
                text: tileDamageText,
                spacing,
                style: "basic",
            }),
            new Paragraph({
                children: 
                containsLineBreak(wornTilesText) ?
                    wornTilesText.split('\\n').map((line, i) => {
                        return new TextRun({
                            text: !line.includes('\\n') ? line : '',
                            break: i > 0 ? 1: 0
                        })
                    })
                : [new TextRun({
                            text: wornTilesText,
                            break: 0
                })],
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: tileVegetationText,
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: defectiveFlashingText,
                spacing,
                style: "basic",
            })
        ].filter(v => !!v);
        return tdt;
        /*

        */
    }

    const getValleyActionText = (data) => {
        let actionOptions = [
            'replace all missing / slipped roof tiles',
            'relay leadworks along the valley line to extend under the adjacent tiles',
            'rake out damaged mortar fillet and replacewith annew mix, suitably packed beneath the valley roof tiles edge'
        ];
        let valleyActions = [];
        if (data?.includes('roof tiles')) {
            valleyActions.push(actionOptions[0]);
        }
        if (data?.includes('leadworks')) {
            valleyActions.push(actionOptions[1]);
        }
        if (data?.includes('mortar fillet')) {
            valleyActions.push(actionOptions[2]);
        }

        valleyActions?.map((action, i) => `(${i}) - ${action}` )
        // // console.log('VALLEY ACTIONS: ', valleyActions);
        
        return valleyActions.length ? getCommaListText(valleyActions) : null ;
    }

    const formatPeripheryText = (spacing) => {
        const closureDefects = [
            'Missing / substandard soffit boarding (not weather-tight)?',
            'Deformed soffit boarding (sagging/bowing)?',
            'Weathered / rotten soffit boarding (timber only)?',
            'Missing / substandard fascia boarding (not weather-tight)?',
            'Deformed fascia boarding (sagging / bowing)?',
            'Weathered / rotten fascia boarding (timber only)?'
        ]
        const valleyAnswer = data.sections['External Roof'].subsections["Check Roof Periphery"].buildingItems['Valley lines'].defects['Defects to valley line?'].answer
        // console.log('valley answer: ', valleyAnswer);
        valleyDamage = getCommaListText(valleyAnswer);
        valleyActions = getValleyActionText(valleyAnswer)
        // console.log('VALLEY ACTIONS TECT: ', valleyActions);
        let closureAllOk = true;
        Object.values(data.sections['External Roof'].subsections["Check Roof Periphery"].buildingItems['Verge Line'].defects).forEach(defect => {
            if (closureDefects.includes(defect.defect) && parseInt(defect.answer) > 0) {
                closureAllOk = false;
            }
        });
        Object.values(data.sections['External Roof'].subsections["Check Roof Periphery"].buildingItems['Eaves'].defects).forEach(defect => {
            if (parseInt(defect.answer) > 0) {
                closureAllOk = false;
            }
        });
        const tempData = {...data.sections['External Roof'].subsections["Check Roof Periphery"].buildingItems};
        if (closureAllOk) {
            // // console.log('closure all ok')
            closureDefects.forEach( (d, i) => {
                if (i > 0) {
                    delete tempData['Verge Line'].defects[d];
                    delete tempData['Eaves'].defects[d];
                }
            })
        } else {
            closureDefects.forEach( (d, i) => {
                if (parseInt(tempData['Verge Line'].defects[d].answer) === 0) {
                    delete tempData['Verge Line'].defects[d];
                    delete tempData['Eaves'].defects[d];
                }
            })
        }
        const checkRoofPeripheryText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing})
        }).reduce((acc, val) => [...acc, ...val], []);

        return checkRoofPeripheryText;
    }

    const formatDrainageText = (spacing) => {

        let gutteringAllOk = true;
        let downpipesAllOk = true;
        Object.values(data.sections['External Roof'].subsections["Check Roof Drainage"].buildingItems['Guttering'].defects).forEach(defect => {
            if (parseInt(defect.answer) > 0) {
                gutteringAllOk = false;
            }
        });
        Object.values(data.sections['External Roof'].subsections["Check Roof Drainage"].buildingItems['Downpipes'].defects).forEach(defect => {
            if (parseInt(defect.answer) > 0) {
                downpipesAllOk = false;
            }
        });
        const tempGuttering = {...data.sections['External Roof'].subsections["Check Roof Drainage"].buildingItems['Guttering'].defects};
        if (gutteringAllOk) {
            Object.keys(tempGuttering).forEach( (d, i) => {
                if (i > 0) {
                    delete tempGuttering[d];
                }
            })
        } else {
            Object.keys(tempGuttering).forEach( (d, i) => {
                if (parseInt(tempGuttering[d].answer) === 0) {
                    delete tempGuttering[d];
                }
            })
        }
        const tempDownpipes = {...data.sections['External Roof'].subsections["Check Roof Drainage"].buildingItems['Downpipes'].defects};
        if (downpipesAllOk) {
            Object.keys(tempDownpipes).forEach( (d, i) => {
                if (i > 0) {
                    delete tempDownpipes[d];
                }
            })
        } else {
            Object.keys(tempDownpipes).forEach( (d, i) => {
                if (parseInt(tempDownpipes[d].answer) === 0) {
                    delete tempDownpipes[d];
                }
            })
        }
        const tempData = {...data.sections['External Roof'].subsections["Check Roof Drainage"].buildingItems};
        tempData['Guttering'].defects = {...tempGuttering};
        tempData['Downpipes'].defects = {...tempDownpipes};
        // // console.log('tempData: ', tempData)
        const checkRoofDrainageText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing})
        }).reduce((acc, val) => [...acc, ...val], []);

        return checkRoofDrainageText;
    }

    const formatChimneysText = (spacing) => {
        let numberOfChimneys = data.sections['Property Details'].formValues.numberChimneys;
        if (numberOfChimneys > 0) {
            let chimneysAllOk = true;
            const chimFilters = [
                'brickworkChimney',
                'renderedChimney',
                'brickworkRenderChimney',
                'stoneworkChimney',
            ];
            const activeChimFilters = data.filters.filter(filter => chimFilters.includes(filter.slice(0, -1)));
            // // console.log('active chim filters: ', activeChimFilters);
            const tempData = {...data.sections['External Roof'].subsections};
            
            Object.entries(tempData).forEach(sub => {
                // // console.log(sub[1]);
                // // // console.log('defect: ', defect)
                const bi = Object.values(sub[1].buildingItems)[0];
                const defect = Object.values(bi.defects)[0];
                if (sub[0].includes('Chimney Structure') && activeChimFilters.includes(defect.filterBy[0])) {
                    
                } else {
                    // // console.log('deleting sub: ', sub[0]);
                    delete tempData[sub[0]]
                }
            })
            // // console.log('cleaned chim data: ', tempData)
            
    
            Object.entries(tempData).forEach(sub => {
                Object.entries(sub[1].buildingItems).forEach(item => {
                    Object.entries(item[1].defects).forEach(defect => {
                        //// // console.log('check answer more than 0?: ', parseInt(defect.answer))
                        if (parseInt(defect[1].answer) > 0) {
                            chimneysAllOk = false;
                        }
                    })
                })
            });

            // add check for flagged defects if all ok

            if (chimneysAllOk) {
                return [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: 'Chimney Structure',
                                underline: {type: "single"}
                            }),
                        ],
                        spacing,
                        style: "basic",
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: data.sections['External Roof'].subsections['Chimney Structure (Rendered) #1'].buildingItems['Pots'].defects['Cracked / damaged pot(s)?'].MBRT1,
                            }),
                        ],
                        spacing,
                        style: "basic",
                    }),
                ]
            } else {
                const defectTexts = Object.entries(tempData).map(sub => {
                    return Object.entries(sub[1].buildingItems).map(item => {
                        return formatBuildingItemOutput({item, spacing})
                        

                    }).reduce((acc, val) => [...acc, ...val], []);
                }).filter(x => !!x?.length).reduce((acc, val) => [...acc, ...val], []);

                return [
                    new Paragraph({
                        style: "basic",
                        children: [
                            new TextRun({
                                text: 'Chimney Structure',
                                underline: {type: "single"}
                            }),
                        ],
                        spacing
                    }),
                    ...defectTexts
                ]
            }
        } else {
            return [];
        }
    }

    const formatVentilationText = (spacing) => {
        let noVentilation = true;
        Object.values(data.sections['External Roof'].subsections["Check Roof Ventilation"].buildingItems['Roof ventilation'].defects).forEach(defect => {
            if (defect.questionType === 'yesNo' && defect.answer === 'Yes') {
                noVentilation = false;
            }
        });

        const tempVentilation = {...data.sections['External Roof'].subsections["Check Roof Ventilation"].buildingItems['Roof ventilation'].defects};
        if (noVentilation) {
            // // console.log('no ventialtion')
            Object.keys(tempVentilation).forEach( (d, i) => {
                if (i > 0) {
                    delete tempVentilation[d];
                }
            })
        } else {
            // // console.log('has ventilation:')
            Object.keys(tempVentilation).forEach( (d, i) => {
                if (tempVentilation[d].answer !== 'Yes') {
                    // // console.log('delete ventilation defect:', d, tempVentilation[d].answer)
                    delete tempVentilation[d];
                }
            })
        }

        // // console.log('tempVentilation: ', tempVentilation)
        const checkVentilationText = Object.entries({'Roof ventilation': {defects: tempVentilation}}).map(item => {
            return formatBuildingItemOutput({item, spacing})
        }).reduce((acc, val) => [...acc, ...val], []);
        // // console.log('checkVentilationText: ', checkVentilationText)
        return checkVentilationText;
    }

    const formatFlatRoofText = async (spacing) => {
        const hasExtension = data.filters.includes('hasExtensions');
        const hasFlatRoof = data.filters.includes('extensionRoof');
        let flatRoofText = [];
        if (hasExtension && hasFlatRoof) {
            const genText = `The flat roof, see Photo(s), should have been constructed with structural grade timber roof beams, timber panel boarding to support the roof coverings (e.g. felt, grp, epdm/rubber). Insulation should have been installed within the depth of the flat roof, along with a vapour barrier (effectively a layer of polythene/heavy duty sheeting to the opposite side of the plaster board ceiling) to negate condensation / moisture build up within the roof constructions that could otherwise affect the integrity of the structural roof timbers – see flat roof detail provided in this report. When it comes to a flat roof's lifespan, the majority of manufacturers guarantees will only last for ten to twenty years (depending on installer and materials used). If well installed and maintained, roof finishes could last longer but it will require time and monetary investment to keep the flat roof surface it in a serviceable condition. The internal ceiling finishes have been inspected to confirm the presence of any obvious sign of rainwater ingress (e.g. damp patches on ceiling / wall surfaces).`

            const tempData = {...data.sections['External Roof'].subsections["Check Extension Flat Roof"].buildingItems};
            const roofUnavailable = tempData['Accessibility'].defects['Unable to inspect the flat roof from ground level (e.g. due to height being 2 storeys or more)?'].answer;
            if (roofUnavailable === 'No') {
                // // console.log('tempData', tempData);
                flatRoofText = Object.entries(tempData).map(item => {
                    return formatBuildingItemOutput({item, spacing, prefix: 'Extension '})
                }).reduce((acc, val) => [...acc, ...val], []);
                if (includeGrt) {
                    const temp = flatRoofText.shift();
                    flatRoofText.unshift(textToParagraph(genText, spacing));
                    flatRoofText.unshift(temp);
                }
                if (includeImages) {
                    flatRoofText.push(await imageParagraph(flatRoofDiagram, spacing, 550, 440, 'Typical Warm Flat Roof Detail'));
                }
            } else if (roofUnavailable === 'Yes') {
                flatRoofText = [
                    new Paragraph({
                        style: "basic",
                        children: [
                            new TextRun({
                                text: 'Extension Flat Roof',
                                underline: {type: "single"}
                            }),
                        ],
                        spacing
                    }),
                    textToParagraph(`We were unable to inspect the flat roof surface due to the height being 2 storey's from ground level (N.B. our health and safety policy prohibits ladder access greater than a single storey (in line with industry H&S guidance). Notwithstanding this, we have inspected the internal ceiling surfaces below the flat roof to check for any obvious rainwater penetration and damage / staining to the ceiling surface.`, spacing)
                ]
            } else {
                return [];
            }
        }

        return flatRoofText;
    }

    

    const formatExtensionDrainageText = (spacing) => {
        const hasExtension = data.filters.includes('hasExtensions');
        if (hasExtension) {
            let gutteringAllOk = true;
            let downpipesAllOk = true;
            Object.values(data.sections['External Roof'].subsections["Check Extension Roof Drainage"].buildingItems['Guttering'].defects).forEach(defect => {
                if (parseInt(defect.answer) > 0) {
                    gutteringAllOk = false;
                }
            });
            Object.values(data.sections['External Roof'].subsections["Check Extension Roof Drainage"].buildingItems['Downpipes'].defects).forEach(defect => {
                if (parseInt(defect.answer) > 0) {
                    downpipesAllOk = false;
                }
            });
            const tempGuttering = {...data.sections['External Roof'].subsections["Check Extension Roof Drainage"].buildingItems['Guttering'].defects};
            if (gutteringAllOk) {
                Object.keys(tempGuttering).forEach( (d, i) => {
                    if (i > 0) {
                        delete tempGuttering[d];
                    }
                })
            } else {
                Object.keys(tempGuttering).forEach( (d, i) => {
                    if (parseInt(tempGuttering[d].answer) === 0) {
                        delete tempGuttering[d];
                    }
                })
            }
            const tempDownpipes = {...data.sections['External Roof'].subsections["Check Extension Roof Drainage"].buildingItems['Downpipes'].defects};
            if (downpipesAllOk) {
                Object.keys(tempDownpipes).forEach( (d, i) => {
                    if (i > 0) {
                        delete tempDownpipes[d];
                    }
                })
            } else {
                Object.keys(tempDownpipes).forEach( (d, i) => {
                    if (parseInt(tempDownpipes[d].answer) === 0) {
                        delete tempDownpipes[d];
                    }
                })
            }
            const tempData = {...data.sections['External Roof'].subsections["Check Extension Roof Drainage"].buildingItems};
            tempData['Guttering'].defects = {...tempGuttering};
            tempData['Downpipes'].defects = {...tempDownpipes};
            // // console.log('tempData: ', tempData)
            const checkRoofDrainageText = Object.entries(tempData).map(item => {
                return formatBuildingItemOutput({item, spacing, prefix: 'Extension '})
            }).reduce((acc, val) => [...acc, ...val], []);
    
            return checkRoofDrainageText;
        } else {
            return [];
        }
    }

    const externalRoof = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const erData = data.sections['External Roof'];
        const subsections = erData.subsections;
        const roofAvailable = subsections["Accessibility"].buildingItems['Roof Profile'].defects['Are all inclined roof profile surfaces (Front/Side/Rear) available for inspection?'];

        const checkRoofAlignment = Object.entries(JSON.parse(JSON.stringify(erData.subsections["Check Roof Alignment"].buildingItems))).map((item, i) => {
           return formatBuildingItemOutput({item, spacing})
        }).reduce((acc, val) => [...acc, ...val], [])
        checkRoofAlignment.unshift(subsectionHeading("Check Roof Alignment", spacing));
        
        const checkRoofTileCoverage = formatTileCoverageText(spacing);
        checkRoofTileCoverage.unshift(subsectionHeading("Tile Coverage", spacing));

        const checkRoofPeriphery = formatPeripheryText(spacing);
        checkRoofPeriphery.unshift(subsectionHeading("Roof Periphery", spacing));

        const checkRoofDrainage = formatDrainageText(spacing);
        checkRoofDrainage.unshift(subsectionHeading("Roof Drainage", spacing));

        const checkChimneys = formatChimneysText(spacing);
        if (checkChimneys.length) {
            checkChimneys.unshift(subsectionHeading("Chimneys", spacing));
        }

        const checkVentilation = formatVentilationText(spacing);
        if (checkVentilation.length) {
            checkVentilation.unshift(subsectionHeading("Ventilation", spacing));
        }
        
        const checkFlatRoof = await formatFlatRoofText(spacing);
        if (checkFlatRoof.length) {
            checkFlatRoof.unshift(subsectionHeading("Extension Flat Roof", spacing));
        }

        const checkExtensionDrainage = formatExtensionDrainageText(spacing);
        if (checkExtensionDrainage.length) {
            checkExtensionDrainage.unshift(subsectionHeading("Extension Roof Drainage", spacing));
        }
        
        const introText = [
            new Paragraph({
                text: '5(A). EXTERNAL ROOF ',
                heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
                text: 'The following section details the condition of the main structural components of the property. A description is provided of defects that were found and the probable cause of their development.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Report conclusions are set out in Section 6. Recommendations for remedial works are discussed in Section 7. A guide to repair costs are detailed in Section 8. Section 9 discusses what to do now.',
                spacing,
                style: "basic",
            }),
            roofAvailable.answer == 'No' && includeGrt ? new Paragraph({
                text: roofAvailable.GRT,
                spacing,
                style: "basic",
            }) : null,

        ].filter(v => !!v)

        const roofTilesLifespan = includeImages ? await imageParagraph(roofingMaterialsLife, spacing, 550, 440) : [];

        const er =  {
            properties: {},
            children: [
                ...introText,
                ...checkRoofAlignment,
                ...checkRoofTileCoverage,
                    roofTilesLifespan,
                ...checkRoofPeriphery,
                ...checkRoofDrainage,
                ...checkChimneys,
                ...checkVentilation,
                ...checkFlatRoof,
                ...checkExtensionDrainage
            ]
        };

        return er;
    }

    

    const formatWallsText = (spacing, subsection, buildingItem, startIndex = 0, endIndex = 100) => {
        let renderAllOk = true;
        
        Object.values(data.sections['External Walls'].subsections[subsection].buildingItems[buildingItem].defects).forEach((defect, i) => {
            if (defect.questionType === 'rating' && parseInt(defect.answer) > 0 && i >= startIndex && i <= endIndex) {
                renderAllOk = false;
            }
        });

        const tempRender = {...data.sections['External Walls'].subsections[subsection].buildingItems[buildingItem].defects};
        if (renderAllOk) {
            Object.keys(tempRender).forEach( (d, i) => {
                if (i > startIndex && i <= endIndex) {
                    delete tempRender[d];
                }
            })
        } else {
            Object.keys(tempRender).forEach( (d, i) => {
                if (parseInt(tempRender[d].answer) === 0 && i >= startIndex && i <= endIndex) {
                    delete tempRender[d];
                }
            })
        }
        // xlink
        const entries = Object.entries(tempRender);
        let prevDefectKey;
        entries.forEach((defect, i) => {
            if (defect[1].questionType === 'yesNo' && defect[1].include && defect[1].answer?.toLowerCase() === 'yes') { // && parseInt(tempRender[prevDefectKey]?.answer) !== 0
                delete tempRender[prevDefectKey]; 
            } else if (defect[1].questionType === 'yesNo' && defect[1].include && defect[1].answer?.toLowerCase() === 'no') {
                delete tempRender[defect[0]]; 
            }
            prevDefectKey = defect[0];
        })
        
        const tempData = JSON.parse(JSON.stringify({...data.sections['External Walls'].subsections[subsection].buildingItems}));
        tempData[buildingItem].defects = JSON.parse(JSON.stringify(tempRender));

        const wallsText = Object.entries(tempData).map(item => {
            return formatRatingYesNoAndFreeTextBuildingItem(item, spacing, '')
        }).reduce((acc, val) => [...acc, ...val], []);
        wallsText.unshift(subsectionHeading(subsection, spacing));

        return wallsText;
    }

    const externalWalls = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const ewData = data.sections['External Walls'];
        const subsections = ewData.subsections;
        const GRT1 = `We have conducted an examination of the external wall surfaces to check for any structural movement, cracks, bulging / misalignment which could otherwise suggest subsidence/settlement/heave of the foundations. In addition, we have inspected window and door openings in the masonry wall panels to ascertain any 'skewing' or other distortion that could otherwise indicate problematic movements. Where wall finishes are present (i.e. render) then an assessment of it's integrity has been conducted to check for any cracks, spalling or other defects to this non-structural material finish. All exposed masonry has also been examined for other defects including moisture induced behaviour, thermal movements, frost damage. The mortar joints / pointing will have also been inspected.`
        const introText = [
            new Paragraph({
                text: '5(B). EXTERNAL WALLS',
                heading: HeadingLevel.HEADING_1,
            }),
            includeGrt ? new Paragraph({
                text: GRT1,
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v)
        wallSurfaces = getCommaListText(ewData.subsections["Surface"].buildingItems['Alignment'].defects['Affected wall surfaces'].answer);
        const surfaceAlignment = Object.entries(ewData.subsections["Surface"].buildingItems).map(item => {
            return formatBuildingItemOutput({item, spacing})
        }).reduce((acc, val) => [...acc, ...val], []);
        surfaceAlignment.unshift(subsectionHeading("Surface", spacing));

        const wallType = data.filters.find(filter => wallTypeDescriptionFilters.includes(filter));
        let wallText = []

        switch(wallType) {
            case 'solidWallsWithRender':
                wallText = formatWallsText(spacing, "Solid Walls (Render Finish Only)",  "Render finishes");
                break;
            case 'solidWallsWithRenderAndBrickwork':
                wallText = formatWallsText(spacing, "Solid Walls (Render & Brickwork Finishes)",  "Render finishes");
                break;
            case 'solidWallsWithRenderAndStonework':
                wallText = formatWallsText(spacing, "Solid Walls (Render & Stonework Finishes)",  "Render finishes");
                break;
            case 'solidWallsBrickworkAndOrStonework':
                wallText = formatWallsText(spacing, "Solid Walls (Brickwork and/or Stonework Finishes)",  "Brickwork/Stonework Finishes", 1);
                break;
            case 'solidWallsToPropCavityExtensionRender':
                wallText = formatWallsText(spacing, "Solid Walls (Original Property), Cavity Walls (Extension) with Rendered Finishes only)",  "Render finishes");
                break;
            case 'solidWallsToPropCavityExtensionRenderAndBrickwork':
                wallText = formatWallsText(spacing, "Solid Walls (Original Property), Cavity Walls (Extension) with Rendered and Brickwork Finishes)",  "Render finishes");
                break;
            case 'solidWallsToPropCavityExtensionRenderAndStonework':
                wallText = formatWallsText(spacing, "Solid Walls (Original Property), Cavity Walls (Extension) with Rendered and Stonework Finishes)",  "Render finishes");
                break;
            case 'solidWallsToPropCavityExtensionBrickworkAndOrStonework':
                wallText = formatWallsText(spacing, "Solid Walls (Original Property), Cavity Walls (Extension) with Brickwork and/or Stonework Finishes)",  "Brickwork/Stonework Finishes", 1, 4);
                break;
            case 'cavityWallsWithRender':
                wallText = formatWallsText(spacing, "Cavity Walls (Render Finishes only)",  "Render finishes");
                break;
            case 'cavityWallsWithRenderAndBrickwork':
                wallText = formatWallsText(spacing, "Cavity Walls (Render and Brickwork Finishes)",  "Render finishes");
                break;
            case 'cavityWallsWithRenderAndStonework':
                wallText = formatWallsText(spacing, "Cavity Walls (Render and Stonework Finishes)",  "Render finishes");
                break;
            case 'cavityWallsWithBrickworkAndOrStonework':
                wallText = formatWallsText(spacing, "Cavity Walls (Brickwork and \/ or Stonework Finishes)",  "Brickwork/Stonework Finishes", 1, 3);
                break;
            default:
                break;
        }

        

        const ew =  {
            properties: {},
            children: [
                ...introText,
                ...surfaceAlignment,
                ...wallText
                
            ]
        };

        return ew;
    
    }

    

    const ventilation = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };

        const introText = [
            new Paragraph({
                text: '5(C). VENTILATION',
                heading: HeadingLevel.HEADING_1,
            }),
        ]

        const ventType = data.filters.find(filter => ventilationFilters.includes(filter));
        let GRT1;
        let GRT2;

        
        let tempData = {...data.sections['Ventilation'].subsections['Sub-Floor Ventilation'].buildingItems};
        let subFloorVentText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing, filterType: ventType})
        }).reduce((acc, val) => [...acc, ...val], []);
        if (subFloorVentText.length) {
            subFloorVentText.unshift(subsectionHeading('Sub-Floor Ventilation', spacing));
        }

        switch(ventType) {
// //'External Solid Walls (conc ground slab throughout)'
// //'External Solid Walls (conc ground slab & suspended timber floor)'
// 'External Solid Walls (conc ground slab & suspended concrete floor)'
// 'External Solid Walls (suspended timber floor throughout)'
// 'External Solid Walls (suspended concrete floor throughout)'
// 'External Solid / Cavity Walls (conc ground slab throughout)'
// 'External Solid / Cavity Walls (conc ground slab & suspended timber floor)'
// 'External Solid / Cavity Walls (conc ground slab & suspended concrete floor)'
// 'External Solid / Cavity Walls (suspended timber floor throughout)'
// 'External Solid / Cavity Walls (suspended concrete floor throughout)'
// 'External Cavity Walls (conc ground slab throughout)'
// 'External Cavity Walls (conc ground slab & suspended timber floor)'
// 'External Cavity Walls (conc ground slab & suspended concrete floor)'
// 'External Cavity Walls (suspended timber floor throughout)'
// 'External Cavity Walls (suspended concrete floor throughout)'
            case "solidWallConcreteSlab":
                GRT1 = await imageParagraph(concGroundSlabDetail, spacing, 385, 270, 'Concrete Ground Slab Detail');
                break;

            case "solidWallConcreteSlabAndSusTimber":
                GRT1 = await imageParagraph(susTimberFloorDetail, spacing, 410, 215, 'Section through cavity wall (showing ‘air bricks’) & suspended timber ground floor (taken from Building Regulations Part C2 – Resistance to Moisture)');
                break;
                
            case "solidWallConcreteSlabAndSusConcrete":
                GRT1 = await imageParagraph(susGroundFloorDetail, spacing, 385, 226, 'Suspended Ground Floor - Ventilated Sub-floor Void');
                break;

            case "solidWallSusTimber":
                GRT1 = await imageParagraph(susTimberFloorDetail, spacing, 410, 215, 'Section through cavity wall (showing ‘air bricks’) & suspended timber ground floor (taken from Building Regulations Part C2 – Resistance to Moisture)');
                break;

            case "solidWallSusConcrete":
                GRT1 = await imageParagraph(susGroundFloorDetail, spacing, 385, 226, 'Suspended Ground Floor - Ventilated Sub-floor Void');
                break;

            case "solidCavityWallConcreteSlab":
                GRT1 = await imageParagraph(concGroundSlabDetail, spacing, 385, 270, 'Concrete Ground Slab Detail');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "solidCavityWallConcreteSlabAndSusTimber":
                GRT1 = await imageParagraph(susTimberFloorDetail, spacing, 410, 215, 'Section through cavity wall (showing ‘air bricks’) & suspended timber ground floor (taken from Building Regulations Part C2 – Resistance to Moisture)');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "solidCavityWallConcreteSlabAndSusConcrete":
                GRT1 = await imageParagraph(susGroundFloorDetail, spacing, 385, 226, 'Suspended Ground Floor - Ventilated Sub-floor Void');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "solidCavityWallSusTimber":
                GRT1 = await imageParagraph(susTimberFloorDetail, spacing, 410, 215, 'Section through cavity wall (showing ‘air bricks’) & suspended timber ground floor (taken from Building Regulations Part C2 – Resistance to Moisture)');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "solidCavityWallSusConcrete":
                GRT1 = await imageParagraph(susGroundFloorDetail, spacing, 385, 226, 'Suspended Ground Floor - Ventilated Sub-floor Void');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "cavityWallConcreteSlab":
                GRT1 = await imageParagraph(concGroundSlabDetail, spacing, 385, 270, 'Concrete Ground Slab Detail');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "cavityWallConcreteSlabAndSusTimber":
                GRT1 = await imageParagraph(susTimberFloorDetail, spacing, 410, 215, 'Section through cavity wall (showing ‘air bricks’) & suspended timber ground floor (taken from Building Regulations Part C2 – Resistance to Moisture)');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "cavityWallConcreteSlabAndSusConcrete":
                GRT1 = await imageParagraph(susGroundFloorDetail, spacing, 385, 226, 'Suspended Ground Floor - Ventilated Sub-floor Void');
                GRT2 = await imageParagraph(cavityWallFloorDetail, spacing, 385, 308, 'Detail ‘A’ – Cavity needs to extend 225mm below DPC');
                break;

            case "cavityWallSusTimber":
                GRT1 = await imageParagraph(susTimberFloorDetail, spacing, 410, 215, 'Section through cavity wall (showing ‘air bricks’) & suspended timber ground floor (taken from Building Regulations Part C2 – Resistance to Moisture)');
                break;

            case "cavityWallSusConcrete":
                GRT1 = await imageParagraph(susGroundFloorDetail, spacing, 385, 226, 'Suspended Ground Floor - Ventilated Sub-floor Void');
                break;

            default:
                break;
        }

        if (GRT1 && includeImages) {
            subFloorVentText.push(GRT1);
        }
        if (GRT2 && includeImages) {
            subFloorVentText.push(GRT2);
        }

        const passiveVentGRT1 = 'Additionally, current Building Regulation requirements specify that a permanent free flow of air be provided to the internal living areas to prevent the build-up of moisture, damp and condensation. This natural ventilation is usually provided by ‘trickle vents’ installed in windows and doors – See Figure 1 below, or by having windows with a secondary lock that allows the windows to ventilate (while maintaining security) – See Figure 2.'
        const passiveVentGRT2 = await imageParagraph(trickleVentsDetail, spacing, 385, 308, 'Figure 1 : Trickle Vents to Window Frames');
        const passiveVentGRT3 = await imageParagraph(windowLocksDetail, spacing, 385, 308, 'Figure 2 : Double Security Locks to Window Frames (to aid ventilation while maintaining security)');
        
        tempData = {...data.sections['Ventilation'].subsections['Internal Passive Ventilation (Rooms)'].buildingItems};
        let passiveRoomVoidText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing, prefix: ''})
        }).reduce((acc, val) => [...acc, ...val], []);
        if (includeGrt) {
            passiveRoomVoidText.unshift(passiveVentGRT1);
        }
        passiveRoomVoidText.unshift(subsectionHeading('Internal Passive Ventilation (Rooms)', spacing));
        if (includeImages) {
            passiveRoomVoidText.push(passiveVentGRT2, passiveVentGRT3);
        }


        const roofVentGRT1 = 'Having natural ventilation in the roof void is also required by current Building Regulations as it prevents the onset of defects developing to the structural roof timbers, e.g. dry rot. The solution is to install either ‘ventilation roof tiles’ to the front and rear roof profiles OR to install ‘ventilation slots’ to the soffit boards at eaves level (See Figures 3 and 4 below). Both the ventilation tiles and ventilation slots serve the same purpose which is to ensure a free air path into the roof void to negate the build up of moisture and the potential for defects to occur (e.g. dry rot in the structural timber sections). '
        const roofVentGRT2 = await imageParagraph(ventRoofTiles, spacing, 385, 308, 'Figure 3 : Ventilation Roof Tiles');
        const roofVentGRT3 = await imageParagraph(roofVentSoffitBoards, spacing, 385, 308, 'Figure 4 : Ventilation Soffit Boards');

        tempData = {...data.sections['Ventilation'].subsections['Internal Passive Ventilation (Roof Void)'].buildingItems};
        const feltType = data.filters.find(filter => filter !== 'feltPresent' && feltFilters.includes(filter));
        let passiveRoofVoidText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing, filterType: feltType})
        }).reduce((acc, val) => [...acc, ...val], []);


        if (includeGrt) {
            passiveRoofVoidText.unshift(roofVentGRT1);
        }
        passiveRoofVoidText.unshift(subsectionHeading('Internal Passive Ventilation (Roof Void)', spacing));
        if (includeImages) {
            passiveRoofVoidText.push(roofVentGRT2, roofVentGRT3);
        }

        const vent =  {
            properties: {},
            children: [
                ...introText,
                ...subFloorVentText,
                ...passiveRoomVoidText,
                ...passiveRoofVoidText
                
            ]
        };

        return vent;
    
    }

    

    const formatFoundationReportText = () => {

    }


    const foundations = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };

        const introText = [
            new Paragraph({
                text: '5(D). FOUNDATIONS',
                heading: HeadingLevel.HEADING_1,
            }),
            includeGrt ? new Paragraph({
                text: 'The foundation details could not be ascertained as they are clearly submerged below ground level and not available for examination. However, the foundations will have adopted one of a number of solutions are shown below:',
                spacing,
                style: "basic",
            }) : null,
            includeGrt ? new Paragraph({
                text: '1). ‘Concrete Strip Foundation’ typically 600mm to 900mm wide, depending on the ground conditions present, to safely distribute the foundation loads to the supporting ground / sub- strata, as per the detail below. This is a standard foundation detail and used where the supporting ground strata is competent enough to support the building without problematic settlement.',
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(concreteStripFoundationDetail, spacing, 385, 245, 'Concrete Strip Footing / Foundation') : null,
            includeGrt ? new Paragraph({
                text: '2). ‘A Deep Concrete Trench’ solution may also have been used if the ground conditions were poor, to distribute building loads through weaker upper layers of soil down to stronger, more competent ground bearing material. ',
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(deepConcreteFoundationDetail, spacing, 385, 405, 'Deep Concrete Trench Foundation') : null,
            includeGrt ?new Paragraph({
                text: '3). ‘Solid Concrete Raft’ of uniform thickness may have been used throughout the ground floor plan area (to spread the building load over a wider area, where the property is built on weaker soils), acting as a single foundation to the property, supporting all the floor imposed loading as well as loading from external and internal walls (N.B. a solid raft would not be applicable to those areas where suspended timber/concrete ground floors are present).',
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(concreteRaftFoundationDetail, spacing, 385, 308, 'Solid Concrete Raft Foundation') : null,
            includeGrt ? new Paragraph({
                text: 'The adequacy of the foundations to support the super-structure loading from the property is assessed by the condition of the above ground portion of the property that is visible to us for inspection. Of prime interest would be the alignment of roof and wall profiles (e.g. sagging, bowing, leaning), in addition to the presence of cracks to the load-bearing cavity walls (both internally and external).',
                spacing,
                style: "basic",
            }) : null,
            includeGrt ? new Paragraph({
                text: 'Where cracking is evident, it’s important to understand how this has occurred and its probable cause, either from physical changes (i.e. temperature, freeze / thaw action), chemical changes (i.e. sulphate attack, carbonation), imposed load effects (changes to the building load, i.e. extensions / conversions), differential soil movements (i.e. subsidence, settlement, heave) and vibration (i.e. traffic, mining). It is important to establish whether a risk remains for any further crack development that could affect the structural integrity of the property.',
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v)

        const crackingType = data.filters.find(filter => structuralCrackingFilters.includes(filter));
       

        const crackingDefect = Object.entries(data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (structural)'].defects).find(item => item[0].toLowerCase().includes('structural cracking to walls') && item[1].filterBy.includes(crackingType));
        const intrusiveRequiredDefect = Object.entries(data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (structural)'].defects).find(item => item[0].toLowerCase().includes('intrusive examination required') && item[1].filterBy.includes(crackingType));
        // console.log('crackingType: ',crackingType)
        // console.log('crackingDefect: ',crackingDefect)
        // console.log('intrusiveRequiredDefect: ',intrusiveRequiredDefect)
        //console.log('tempData: ',tempData)
        if (crackingType && crackingType !== 'exMasonry' && intrusiveRequiredDefect[1].answer === 'Yes') {
            //console.log('patching report text');
            // console.log('original:', tempData['Cracks (structural)'].defects[crackingDefect[0]].MBRT2);
            // console.log('patch:', tempData['Cracks (structural)'].defects[intrusiveRequiredDefect[0]].MBRT2);
            data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (structural)'].defects[crackingDefect[0]].MBRT2 = data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (structural)'].defects[intrusiveRequiredDefect[0]].MBRT2;
            data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (structural)'].defects[crackingDefect[0]].conclusion2 = data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (structural)'].defects[intrusiveRequiredDefect[0]].conclusion2;
        }
        let tempData = {...data.sections['Foundations'].subsections['Foundation Performance'].buildingItems};
        //console.log('tempData after: ', tempData)
        console.log('format structural cracking text: ', tempData, crackingType)
        let structuralCrackingText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing, filterType: crackingType, prefix: ''})
        }).reduce((acc, val) => [...acc, ...val], []);
        if (structuralCrackingText.length) {
            structuralCrackingText.unshift(subsectionHeading('Foundation Performance', spacing));
        }

        let tempData1 = {...data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['Cracks (shrinkage/moisture)']};
        let tempData2 = {...data.sections['Foundations'].subsections['Foundation Performance'].buildingItems['General']}
        tempData = {'Cracks (shrinkage/moisture)': {...tempData1}, 'General': {...tempData2}};
        // // console.log('TEMP DATA: ', tempData)
        let moistureCrackingAndGenText = Object.entries(tempData).map(item => {
            return formatBuildingItemOutput({item, spacing, prefix: ''})
        }).reduce((acc, val) => [...acc, ...val], []);

        const foundationsText =  {
            properties: {},
            children: [
                ...introText,
                ...structuralCrackingText,
                ...moistureCrackingAndGenText            
            ]
        };

        return foundationsText;
    }

    

    const formatDpmGrt = async (spacing, dpmType) => {
        let GRT = [];
        switch(dpmType) {
            case 'grndSlabOnly':
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: 'For the concrete ground bearing slabs, modern day construction day standards require a DPM consisting of a layer of heavy duty plastic sheeting (e.g. 1200 gauge material typically) to prevent moisture from the ground entering a property. The low density polythene sheets create a barrier between a concrete slab or screed and the final flooring finish. DPM membranes are laid either under a new concrete slab or on top of it to prevent moisture transmission. Ordinarily you will have a bed of hardcore on the ground then either a blinded surface and a DPM before a layer of concrete, or you place the DPM on top of the concrete slab before the final flooring. If you suspect the groundwater to contain sulphates, then the DPM should always be placed below the concrete.When DPMs overlap you should use DPM tape to seal the joints. The DPM always needs to be continuous with the damp proof course (DPC) in the walls etc. to prevent any moisture migration into the property.',
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(dampMembraneDetail, spacing, 385, 280, 'Typical Damp Proof Membrane Detail'),
                        await imageParagraph(dampMembraneInstallation, spacing, 385, 308, 'Typical Damp Proof Membrane Installation (before concrete slab pour)'),
                    )
                }
                        
                break;

            case 'grndSlabSusTimber':
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: 'For the concrete ground bearing slabs, modern day construction day standards require a DPM consisting of a layer of heavy duty plastic sheeting (e.g. 1200 gauge material typically) to prevent moisture from the ground entering a property. The low density polythene sheets create a barrier between a concrete slab or screed and the final flooring finish. DPM membranes are laid either under a new concrete slab or on top of it to prevent moisture transmission. Ordinarily you will have a bed of hardcore on the ground then either a blinded surface and a DPM before a layer of concrete, or you place the DPM on top of the concrete slab before the final flooring. If you suspect the groundwater to contain sulphates, then the DPM should always be placed below the concrete.When DPMs overlap you should use DPM tape to seal the joints. The DPM always needs to be continuous with the damp proof course (DPC) in the walls etc. to prevent any moisture migration into the property.',
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(dampMembraneDetail, spacing, 385, 280, 'Typical Damp Proof Membrane Detail'),
                        await imageParagraph(dampMembraneInstallation, spacing, 385, 308, 'Typical Damp Proof Membrane Installation (before concrete slab pour)'),
                    )
                }
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: "A modern day DPM (for those areas / rooms on the ground floor with suspended timber flooring) would have consisted of a layer of heavy duty plastic sheeting (e.g. ‘Visqueen'1200 gauge material) applied over the top of 100mm of hardcore / stone material and laid on a 50mm (typival) layer of clean sand (to prevent puncturing the sheeting). A gap of at least 150mm should be present between the DPM and the underside of the timber floor to aid ventilation – see example in image below. We could not corrobotate the presence of a DPM material beneath thr suspended timber floor due to the non-intruisv nature of the survey.",
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(susTimberSectionDetail, spacing, 385, 255, 'Section through Suspended Timber Floor (and DPM)'),
                    )
                }
                break;

            case 'grndSlabSusConcrete':
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: 'For the concrete ground bearing slabs, modern day construction day standards require a DPM consisting of a layer of heavy duty plastic sheeting (e.g. 1200 gauge material typically) to prevent moisture from the ground entering a property. The low density polythene sheets create a barrier between a concrete slab or screed and the final flooring finish. DPM membranes are laid either under a new concrete slab or on top of it to prevent moisture transmission. Ordinarily you will have a bed of hardcore on the ground then either a blinded surface and a DPM before a layer of concrete, or you place the DPM on top of the concrete slab before the final flooring. If you suspect the groundwater to contain sulphates, then the DPM should always be placed below the concrete.When DPMs overlap you should use DPM tape to seal the joints. The DPM always needs to be continuous with the damp proof course (DPC) in the walls etc. to prevent any moisture migration into the property.',
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(dampMembraneDetail, spacing, 385, 280, 'Typical Damp Proof Membrane Detail'),
                        await imageParagraph(dampMembraneInstallation, spacing, 385, 308, 'Typical Damp Proof Membrane Installation (before concrete slab pour)'),
                    )
                }
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: "For concrete suspended ground floors, the DPM is generally laid across the concrete beam / block surface, with a layer of insulation (e.g. 'Celotex) and screed layer over as per thr figure below.",
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(concSusFloorDpmDetail, spacing, 385, 245, 'Concrete Suspended Floor Detail (with DPM)'),
                    )
                }
                break;

            case 'susTimberOnly':
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: "A modern day DPM (for those areas / rooms on the ground floor with suspended timber flooring) would have consisted of a layer of heavy duty plastic sheeting (e.g. ‘Visqueen'1200 gauge material) applied over the top of 100mm of hardcore / stone material and laid on a 50mm (typival) layer of clean sand (to prevent puncturing the sheeting). A gap of at least 150mm should be present between the DPM and the underside of the timber floor to aid ventilation – see example in image below. We could not corrobotate the presence of a DPM material beneath the suspended timber floor due to the non-intrusive nature of the survey.",
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(susTimberSectionDetail, spacing, 385, 255, 'Section through Suspended Timber Floor (and DPM)'),
                    )
                }
                break;
            
            case 'susConcreteOnly':
                if (includeGrt) {
                    GRT.push(
                        new Paragraph({
                            text: "For concrete suspended ground floors, the DPM is generally laid across the concrete beam / block surface, with a layer of insulation (e.g. 'Celotex) and screed layer over as per the figure below. We could not corrobotate the presence of a DPM material beneath the suspended timber floor due to the non-intrusive nature of the survey.",
                            spacing,
                            style: "basic",
                        })
                    );
                }
                if (includeImages) {
                    GRT.push(
                        await imageParagraph(concSusFloorDpmDetail, spacing, 385, 245, 'Concrete Suspended Floor Detail (with DPM)'),
                    )
                }
                break;

            default:
                break;
        }

        return GRT.filter(v => !!v);
    }

    const structuralDamp = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };

        const introText = [
            new Paragraph({
                text: '5(E). STRUCTURAL DAMP',
                heading: HeadingLevel.HEADING_1,
            }),
            includeGrt ? new Paragraph({
                text: 'Structural dampness is the presence of unwanted moisture in the structure of a building either the result of intrusion from outside (rising and / or penetrating damp) or condensation generated from within the structure.',
                spacing,
                style: "basic",
            }) : null,
            includeGrt ? new Paragraph({
                text: 'A high proportion of damp problems in buildings are caused by condensation, rain penetration or rising damp, see the figure below.',
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(dampPenetrationDetail, spacing, 385, 465, 'Detail showing some of the causes of damp penetration') : null,
            includeGrt ? new Paragraph({
                text: `Rain Penetration (also known as "penetrating damp" is a common form of dampness in buildings. It can occur through walls, roofs, or through openings (e.g. window reveals).`,
                spacing,
                style: "basic",
            }) : null,
            includeGrt ? new Paragraph({
                text: `Water will often penetrate the outer envelope of a building and appear inside. Common defects include:`,
                spacing,
                style: "basic",
            }) : null,
            includeGrt ? new Paragraph({
                style: "basic",
                indent: {
                    left: convertInchesToTwip(0.25),
                },
                children: [
                    ...[   
                        'Roof defects such as faulty flashing, cracked or missing tiles, damage to fascia / soffit boards.',
                        'Missing or defective / leaking roof drainage (gutters and / or downpipes).',
                        'Chimney defects (including leadwork, masonry, mortar joints, chimney pots, horizontal crown).',
                        'Faults in the brickwork or masonry such as missing or cracked pointing. Porous bricks or stones.',
                        'Missing or defective mastic around windows and doors. Damaged or missing dpc around door / window openings.',
                        'Blocked weep holes / air bricks.',
                        'Missing or defective trays in cavity walls.'
                    ].map((item, i) => {
                        return new TextRun({
                            text: `- ${item}`,
                            break: i > 0 ? 1 : 0,
                        })
                    })]
            }) : null,
            includeGrt ? new Paragraph({
                text: `An inspection of potential damp in the roof void space and through external walls is discussed in ‘Internal Condition’, which follows later in the report. For the case of potential rising damp, this would be prevented by the presence of an adequate damp proof course (DPC) near the base of the load-bearing walls (e.g. reinforced felt, flexible bitumen, 'blue' engineering brick, slates) and the presence of a Damp Proof Membrane (DPM) to concrete ground bearing slabs, suspended timber or ground floors.`,
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v);

        const dpcType = data.filters.find(filter => dpcFilters.includes(filter));
        let tempData = {...data.sections['Structural Dampness'].subsections['Structural Dampness'].buildingItems};
        let dpcText = Object.entries(tempData).map(item => {
            return formatDampRatingAndFreeTextBuildingItem(item, spacing, dpcType, 'DPC', '')
        }).reduce((acc, val) => [...acc, ...val], []);

        const dpmType = data.filters.find(filter => dpmFilters.includes(filter));
        const dpmGrt = await formatDpmGrt(spacing, dpmType);
        tempData = {...data.sections['Structural Dampness'].subsections['Structural Dampness'].buildingItems};
        let dpmText = Object.entries(tempData).map(item => {
            return formatDampRatingAndFreeTextBuildingItem(item, spacing, dpmType, 'DPM', '')
        }).reduce((acc, val) => [...acc, ...val], []);
        dpmText.splice(1, 0, ...dpmGrt);

        const outroText = [
            includeGrt ? new Paragraph({
                text: "Typical examples of rising damp damage are shown in the images below. Rising damp tends to create 'tide marks' at the base of walls, which will damage internal wall and floor finishes.",
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(risingDampFinishes, spacing, 385, 308, 'Rising Damp (damage to wall finishes)') : null,
            includeImages ? await imageParagraph(risingDampTideMarks, spacing, 385, 308, "Rising Damp ('tide marks')") : null,
            includeGrt ? new Paragraph({
                text: 'The presence of a suitable DPC and DPM (both lapped together) would be required to prevent the ingress of moisture via rising damp migrating through the load-bearing wall constructions and ground floor slabs and entering the internal living space. A typical dpc and dpm construction detail is shown below.',
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(dampProofCourse, spacing, 385, 308, "Damp Proof Course (load-bearing walls) and Damp Proof Membrane (ground floor) Construction Detail") : null,
            includeGrt ? new Paragraph({
                text: "Condition Rating NI as the DPC and DPM (if present) could not be inspected due to the non-intrusive nature of our survey. However, further discussion on the presence of damp (within the internal roof void space and also to the internal living space) is discussed later in the report - see 'Internal Condition'",
                spacing,
                style: "basic",
            }) : null,
            includeGrt ? new Paragraph({
                text: "Our approach for assessing damp is to check for any obvious means of rainwater penetration into the roof void space and also examine the potential for rainwater to migrate through the external wall thickness and damage internal walls/ceilings/floors. This includes (but not limited to) checking the condition of the external roof profile for missing roof tiles, flashing, roofing felt, leaking chimney structure(s). For damp through walls we assess the condition of masonry wall surfaces, mortar joints, masonry and render where applicable. The condition of the roof drainage (gutters and downpipes) should also remain in a serviceable condition to fully capture rainwater discharge from the roof surface and not discharging rainwater down the face of the external walls (which would otherwise encourage damp internally). For rising damp we check the height of external ground levels (i.e. to ensure they don't 'bridge' over the dpc). Internal wall, ceiling and floor finishes also provide an indication whether damp is present.",
                spacing,
                style: "basic",
            }) : null,
            includeImages ? await imageParagraph(dampMeter, spacing, 385, 185, "‘Damp’ Meter used by Surveyors") : null,
            includeGrt ? new Paragraph({
                children: [
                    new TextRun({
                        text: 'We carry out a three stage approach for assessing the presence and severity of damp internally:'
                    }),
                    new TextRun({
                        text: "1). Are there any visual signs of damp being present (e.g. 'tide' marks to base of walls, blemishes / damaged to wall, ceiling or floor surfaces)?",
                        break: 1
                    }),
                    new TextRun({
                        text: "2). Does the area suspected to be damp feel wet to touch?",
                        break: 1
                    }),
                    new TextRun({
                        text: "3). What is the damp meter test result > low (green reading > no action required), medium (yellow reading > monitoring and possible repairs needed) or high (red reading > repairs expected).",
                        break: 1
                    }),
                    new TextRun({
                        text: "We do not rely solely on the results of the damp meter, as their readings can be unreliable and produce incorrect results. For example, in solid walls the damp meter may produce a high (red) reading but the wall feel and looks dry (N.B this may occur due to the damp meter picking up a high salt content in the wall / mortar construction, increasing the electrical conductance of water, and not related to damp/moisture at all). In this situation it would be incorrect to recommend damp repairs as the wall does not have a high moisture content. There is also a British Standard (BS 5250:2011) which recommends one exercises caution when using damp meters to assess moisture content is some building materials). Our three stage approach provides a sensible assessment on whether damp is indeed present and what level of remedial repair works are necessary. Please refer to the 'Internal Condition' of our report which follows and presents our assessment of damp for this property.",
                        break: 2
                    }),
                ],
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v);
        
        const dampText =  {
            properties: {},
            children: [
                ...introText,
                ...dpcText,
                ...dpmText,
                ...outroText,      
            ]
        };

        return dampText;
    
    }


    const roofVoid = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const rvData = JSON.parse(JSON.stringify(data.sections['Roof Void (Internal)'].subsections['Roof Void']));
        const roofVoidAvailable = data.filters.includes('roofVoidAvailable'); //rvData.buildingItems['Roof Void Access'].defects['Is the roof void available for inspection?'].answer;
        const roofVoidConverted = data.filters.includes('roofVoidConverted'); //rvData.buildingItems['Roof Void Access'].defects['Is the roof void converted?'].answer;
        const timberInspectable = data.filters.includes('timberInspectable'); //rvData.buildingItems['Roof Void Access'].defects['Is the roof void converted?'].answer;
        const GRT1 = 'Our inspection of the roof void space is generally conducted by our engineer at the position of the loft hatch, in particular for roof void spaces that have no floor boarding present. Combined with the lack of a fixed light source in the roof void in most cases, it makes the roof void inspection hazardous and so we have to be mindful of the safety of our engineer and also to potential for damage to the ceiling surfaces below (we do use hand held lighting to aid our inspection). Where floor boards are present then our engineer is able to perform a closer examination of the timber frameworks through the wider access available. We do endeavour to inspect as much of the roof timber frameworks as possible to confirm any defects present.'
        // // console.log('timber inspectable? : ',timberInspectable)
        const introText = [
            new Paragraph({
                text: '5(F). ROOF VOID (INTERNAL)',
                heading: HeadingLevel.HEADING_1,
            }),
            includeGrt ? new Paragraph({
                text: GRT1,
                spacing,
                style: "basic",
            }) : null,
            !roofVoidAvailable ? new Paragraph({
                text: 'The roof void space was unavailable for inspection and so we could not assess any of the structural timber frameworks. ',
                spacing,
                style: "basic",
            }) : null,
            includeGrt && roofVoidConverted && timberInspectable ? new Paragraph({
                style: "basic",
                children: [
                    // new TextRun({
                    //     text: `Our inspection of the roof void space is generally conducted by our engineer at the position of the loft hatch, in particular for roof void spaces that have no floor boarding present. Combined with the lack of a fixed light source in the roof void in most cases, it makes the roof void inspection hazardous and so we have to be mindful of the safety of our engineer and also to potential for damage to the ceiling surfaces below (we do use hand held lighting to aid our inspection). Where floor boards are present then our engineer is able to perform a closer examination of the timber frameworks through the wider access available. We do endeavour to inspect as much of the roof timber frameworks as possible to confirm any defects present.`
                    // }),
                    new TextRun({
                        text: `The roof void was largely converted and so the majority of the roof timber frameworks were unavailable for inspection due to the presence of boarding. Some limited examination was possible to those areas of the roof void that have not been converted, e.g. those 'dead-void' areas near the eaves level where head height is negligible.`,
                        // break: 2
                    }),
                ],
                spacing
            }) : null,
            includeGrt && roofVoidConverted && !timberInspectable ? new Paragraph({
                style: "basic",
                text: "The roof void was largely converted and so the roof timber frameworks were unavailable for inspection due to the presence of boarding. Although a number of 'dead void' areas would be present near thre eaves level, where head-height is negligible, there was no formal access so even a limited examination of the roof timber frameworks was not possible.",
                spacing
            }) : null,
        ].filter(v => !!v);

        let bracingText = '';
        const timberTrussDefect = rvData.buildingItems['Timber Truss Frameworks'].defects['Is there diagonal roof bracing evident (Y/N?)'];
        switch(parseInt(timberTrussDefect.answer)) {
            case 0:
                bracingText = timberTrussDefect.MBRT1;
                break;
            case 1:
                bracingText = timberTrussDefect.MBRT2;
                break;
            case 2:
                bracingText = timberTrussDefect.MBRT3;
                break;
            default:
                break;
        }

        const configIntroText = new TextRun({
            text: `The layout and configuration of the internal roof structure can be seen in the photos below.`
        })

        const roofConfigText = [
            data.filters.includes('timberTruss') && includeGrt ? new Paragraph({
                style: "basic",
                children: [
                    configIntroText,
                    new TextRun({
                        text: `The roof structure consists of timber truss frameworks spaced at approximately 500-600 centres along the length of the roof, fixed to the top of the load-bearing masonry walls via a timber wall plate.`,
                        break: 2
                    }),
                ],
                spacing
            }) : null,
            data.filters.includes('timberTruss') ? new Paragraph({
                text: bracingText,
                spacing,
                style: "basic",
            }) : null,
            data.filters.includes('primSecHorizPurlins') ? new Paragraph({
                children: [
                    configIntroText,
                    new TextRun({
                        text: `The roof structure consists of primary and secondary timber rafters, supported at the top of the load-bearing walls (either fixed to a timber wall plate or bearing directly/fixed to the top of the walls) and extending up to the ridge level where they abut the rafters from the opposite side of the roof. Horizontal timber purlins were also present, which provide intermediary support to the secondary rafters and distributing roof loading to primary timber frameworks and / or load-bearing walls. Lateral restraint is provided by the compatibility between the primary / secondary rafters and horizontal purlins. Load-bearing walls (if present) within the roof void space also tie in with the roof frameworks to ensure distribution of vertical/lateral loading further into the structure and finally down to foundation level.`,
                        break: 2
                    }),
                ],
                spacing,
                style: "basic",
            }) : null,
            data.filters.includes('raftersHorizPurlins') ? new Paragraph({
                children: [
                    configIntroText,
                    new TextRun({
                        text: `The roof structure consists of single sized timber rafters, supported at the top of the load-bearing walls (either fixed to a timber wall plate or bearing directly/fixed to the top of the walls) and extending up to the ridge level where they abut the rafters from the opposite side of the roof. Horizontal timber purlins were also present, which provide intermediary support. Lateral restraint is provided by the compatibility between the rafters and horizontal purlins. Load-bearing walls within the roof void space also tie in with the roof frameworks to ensure distribution of vertical/lateral loading further into the structure and finally down to foundation level.`,
                        break: 2
                    }),
                ],
                spacing,
                style: "basic",
            }) : null,
            data.filters.includes('raftersOnly') ? new Paragraph({
                children: [
                    configIntroText,
                    new TextRun({
                        text: `The roof structure consists of single sized timber rafters, supported at the top of the load-bearing walls (either fixed to a timber wall plate or bearing directly/fixed to the top of the walls) and extending up to the ridge level where they abut the rafters from the opposite side of the roof. Horizontal timber purlins were nort visible although would provide intermediary support. Lateral restraint is provided by the compatibility between the rafters and horizontal purlins. Load-bearing walls within the roof void space also tie in with the roof frameworks to ensure distribution of vertical/lateral loading further into the structure and finally down to foundation level.`,
                        break: 2
                    }),
                ],
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v);

        const frameworkItems = [
            'Roof Frameworks Configuration',
            'Roof Frameworks Deformation',
            'Roof Frameworks Rot/Decay',
            'Condensation Damage to Timbers',
        ]

        const frameWorkDefectText = timberInspectable ? Object
            .entries({...rvData.buildingItems})
            .filter(entry => frameworkItems.includes(entry[0]))
            .map(item => formatBuildingItemOutput({item, spacing}))
            .reduce((acc, val) => [...acc, ...val], []) : [];

        const feltPresent = data.filters.includes('feltPresent');
        let feltText = '';
        const feltDefect = feltPresent
            ? rvData.buildingItems['Roofing Felt'].defects['Any defects to the roofing felt (i.e. no tearing, holes, deterioration)?'] 
            : rvData.buildingItems['Roofing Felt'].defects['No roofing felt present: Evidence of rainwater ingress?'];
        console.log('FELT DEFECT', feltDefect);
        switch((feltDefect?.answer?.toString())) {
            case '0':
                feltText = feltDefect.MBRT1;
                break;
            case '1':
            case 'No':
                // // console.log('defect: ', feltDefect)
                feltText = feltDefect.MBRT2;
                break;
            case '2':
            case 'Yes':
                feltText = feltDefect.MBRT3;
                break;
            default:
                break;
        }
        console.log('FELT TEXT', feltText);
        const color = feltDefect.flag ? '991d17': defaultTextColor;
        const feltDefectText = timberInspectable ? formatDefectContent(feltText, [feltDefect.defect, feltDefect], color, spacing) : [];

        if (feltDefectText.length && timberInspectable) {
            feltDefectText.unshift(new Paragraph({
                children: [
                    new TextRun({
                        text: 'Roofing Felt',
                        underline: {type: "single"}
                    }),
                ],
                spacing,
                style: "basic",
            }));
        }

        const ventilationText = timberInspectable ? [
            new Paragraph({
                children: [
                    new TextRun({
                        text: 'Roof Ventilation',
                        underline: {type: "single"}
                    }),
                ],
                spacing,
                style: "basic",
            }),
            includeGrt ? new Paragraph({
                children: [
                    new TextRun({
                        text: 'A further issue to note is that Building Regulations require a permanent and passive free flow of air is provided to the roof void to prevent the onset of defects developing. Dry rot for example can occur and attack the structural timber if moisture in the roof space (lack of ventilation, water penetration / leakage etc) is combined with other conditions (e.g. humid temperatures).',
                        break: 1
                    }),
                    new TextRun({
                        text: 'Dry rot typically occurs in timber where the moisture content exceeds 20%. This moisture can be exacerbated by the lack of a free passage of air into the roof void area, which is evident in this case (i.e. no roof ventilation tiles are present).',
                        break: 2
                    }),
                    new TextRun({
                        text: 'Dry rot affects mostly softwoods and often causes extensive damage. Remedial works can be very expensive. Dry rot is sensitive to high temperatures (over 25oC) and drying, and is therefore rarely found on exposed timbers or in situations where fluctuating conditions are likely (such as well ventilated roof spaces or sub-floors). It is able to grow through bricks and mortar but requires timber to feed on. Strands can transport moisture from damp areas, allowing the spread of fungus to dry wood in unventilated conditions. The fungal characteristics are silk white sheets or cotton wool-like mycelium, white to grey strands (sometimes as thick as a pencil), yellow or red fruiting body with white or grey edges and profuse reddish brown dust spores. Dry rot can cause widespread structural damage.',
                        break: 2
                    }),
                    new TextRun({
                        text: 'Decayed wood has a dull brown colour, typically with deep cracks across the grain. It is light in weight, crumbles between the fingers and there is no skin of sound wood.',
                        break: 2
                    }),
                ],
                spacing,
                style: "basic",
            }) : null
        ].filter(v => !!v) : [];

        const formatRoofItems = (itemName, skipHeader = false) => {
            return Object
                .entries({...rvData.buildingItems})
                .filter(entry => entry[0] === itemName)
                .map(item => formatRoofRatingYesNoAndFreeTextBuildingItem(item, spacing, '', skipHeader))
                .reduce((acc, val) => [...acc, ...val], []);
        }

        const ventilationDefectText = timberInspectable ? formatRoofItems('Roof Ventilation', true) : [];

        const gableWallsDefectText = timberInspectable ? formatRoofItems('Gable Wall(s)') : [];
        
        const partyWallsDefectText = timberInspectable ? formatRoofItems('Party Wall(s)') : [];
        const fireStopDetailSection = await imageParagraph(fireStopDetail, spacing, 385, 308, 'Modern Day Fire Stop Detail'); //TODO - update for new image para format
        if (includeImages) {
            partyWallsDefectText.push(fireStopDetailSection);
        }

        const hasChimneys = !!data.filters.find(filter => filter.includes('chimney'))
        const chimneyDefectText = hasChimneys && timberInspectable ? formatRoofItems('Chimney Stack / Breast') : [];
           
        const genDefectText = timberInspectable ? formatRoofItems('General') : [];

        const roofVoidText = {
            properties: {},
            children: [
                ...introText,
                ...roofConfigText,
                ...frameWorkDefectText,
                ...feltDefectText,
                ...ventilationText,
                ...ventilationDefectText,
                ...gableWallsDefectText,
                ...partyWallsDefectText,
                ...chimneyDefectText,
                ...genDefectText
            ].filter(v => !!v),
        };

        return roofVoidText;
    }

    const firstFloor = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const includeWindows = !!data.filters.includes('windows');
        dampMeterReadings = data.sections['First Floor Rooms'].subsections['Damp'].buildingItems['Penetrating Damp'].defects['Wall surfaces damp meter readings?'].answer?.toLowerCase();
        console.log('DAMP METER READINGS', dampMeterReadings);
        const GRT1 = 'Ceiling finishes, e.g. plaster-board and skim, are nail fixed to the underside of the structural timber frameworks (horizontal beam members) within the roof void. The condition of the ceiling finishes can provide an insight into the condition / performance of the timber beams within the roof. Certain defects present to the ceiling finishes could suggest structural issues with the roof timber frameworks above. Typically, plaster ceiling cracks have two causes (i) movement / settlement of the property (ii) temperature and / or moisture fluctuations in the attic space, which result in the expansion or contraction of framing members and resultant cracking to ceilings. Clearly any cracks associated with building movement are more serious compared to those cracks caused by expsansion / contraction of the roof structure and will require different methods of repair to the building. Please see our findings below regards the condition of the ceiling finishes.'
        // // console.log('timber inspectable? : ',timberInspectable)
        const introText = [
            new Paragraph({
                text: '5(G). FIRST FLOOR ROOMS',
                heading: HeadingLevel.HEADING_1,
            }),
            includeGrt ? new Paragraph({
                text: GRT1,
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v);

        const ceilingData = JSON.parse(JSON.stringify(data.sections['First Floor Rooms'].subsections['Ceilings'].buildingItems));
        //const GRT2 = 'Ceiling finishes, e.g. plaster-board and skim, are nail fixed to the underside of the structural timber frameworks (horizontal beam members) within the roof void. The condition of the ceiling finishes can provide an insight into the condition / performance of the timber beams within the roof. Certain defects present to the ceiling finishes could suggest structural issues with the roof timber frameworks above. Typically, plaster ceiling cracks have two causes (i) movement / settlement of the property (ii) temperature and / or moisture fluctuations in the attic space, which result in the expansion or contraction of framing members and resultant cracking to ceilings. Clearly any cracks associated with building movement are more serious compared to those cracks caused by expsansion / contraction of the roof structure and will require different methods of repair to the building. Please see our findings below regards the condition of the ceiling finishes.'
        const ceilingText = Object.entries(ceilingData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        
        const wallsData = JSON.parse(JSON.stringify(data.sections['First Floor Rooms'].subsections['Internal Walls'].buildingItems));
        const GRT3 = 'An examination of the internal wall panels has been conducted to confirm the presence of any defects that may otherwise indicate potential structural issues with the load-bearing walls. Of particular importance is the presence of any structural cracks, wall panel misalignment / bowing or other deterioration that could impact the load-bearing capacity / strength of the walls. This check includes the internal face of all external (main) load-bearing peripheral walls and also internal (single skin) masonry partition walls where present. Internal stud-partition walls (where present) will not be load-bearing but have been included in our check for completeness.'
        const wallsText = Object.entries(wallsData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        if (includeGrt) {
            wallsText.unshift(new Paragraph({
                text: GRT3,
                spacing,
                style: "basic",
            }));
        }
            
        const floorData = JSON.parse(JSON.stringify(data.sections['First Floor Rooms'].subsections['Floors'].buildingItems));
        const floorText = Object.entries(floorData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);

        const windowsData = JSON.parse(JSON.stringify(data.sections['First Floor Rooms'].subsections['Windows'].buildingItems));
        const GRT4 = includeWindows ?
            'Window condition and alignment are checked as part of our survey for the purposes of assessing whether any building movement may have occurred and consequential \'skewing\' of window openings is present. We also check the conformance of the window frameworks to aid passive ventilation into the property to control and negate condensation build up. Our survey also includes the condition of window seals and condensation between glazing units, any broken / damaged glazing units and also the condition of the hinges / locking mechanism.'
            : 'Window condition and alignment are checked as part of our survey, but only for the purposes of assessing whether any building movement may have occurred and consequential \'skewing\' of window openings is present. We also check the conformance of the window frameworks to aid passive ventilation into the property to control and negate condensation build up. Our survey does not address the actual condition of the windows in terms of their acoustic or thermal performance nor the adequacy of hinges / locking mechanisms. Any defects to the windows that have not been caused by structural movement of the property or are not associated with passive ventilation are out of scope of this report.'
        //const GRT4 = 'Window condition and alignment are checked as part of our survey for the purposes of assessing whether any building movement may have occurred and consequential 'skewing' of window openings is present. We also check the conformance of the window frameworks to aid passive ventilation into the property to control and negate condensation build up. Our survey also includes the condition of window seals and condensation between glazing units, any broekn / damaged glazing units and also the condition of the hinges / locking mechanism.'
        const windowsText = Object.entries(windowsData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        if (includeGrt) {
            windowsText.unshift(new Paragraph({
                text: GRT4,
                spacing,
                style: "basic",
            }));
        }
        windowsText.unshift(new Paragraph({
            children: [
                new TextRun({
                    text: 'Windows / Ventilation',
                    underline: {type: "single"}
                }),
            ],
            //text: GRT4,
            spacing,
            style: "basic",
        }));
            
        const dampData = JSON.parse(JSON.stringify(data.sections['First Floor Rooms'].subsections['Damp'].buildingItems));
        const GRT5 = 'Our inspection has included a check on the presence of penetrating damp to ceiling and wall finishes. For ceilings that are supported via the roof timber frameworks above, any indication of penetrating damp to ceiling finishes would generally have developed either by (i) rainwater leakage into the roof void due to breaches in the roof tile coverings, defective flashing / valley sections, external guttering / absence of roofing felt over the top of the load-bearing walls or rainwater migration via the chimney, if one is present or (ii) internal pipe leaks within the roof void space. For load-bearing walls, penetrating damp can develop and damage internal wall finishes for different reasons. In solid wall construction, external rainwater can migrate through the full thickness of the wall due to the absence of a formal cavity (which would otherwise cut off any moisture migration). This is more of an issue for south-westerly facing wall panels that are open to the elements with no clear protection (e.g. from tree lines or neighbouring properties). For penetrating damp to occur through cavity wall constructions, there has to be a means where moisture can travel / bridge across the cavity and migrate to the internal walls face. This can develop for several reasons (i) the presence of cavity wall insulation (ii) wall ties installed upside down, so the drip formation does not work effectively, enabling moisture to migrate along the tie (iii) wall ties installed at an angle (iv) blockages in the cavity creating a bridge. Rainwater can also migrate into the top of the load-bearing walls if roofing felt is absent or does not extend into guttering as required by building regulations.'
        const dampText = Object.entries(dampData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        if (includeGrt) {
            dampText.unshift(new Paragraph({
                text: GRT5,
                spacing,
                style: "basic",
            }));
        }
        dampText.unshift(new Paragraph({
            children: [
                new TextRun({
                    text: 'Damp',
                    underline: {type: "single"}
                }),
            ],
            //text: GRT4,
            spacing,
            style: "basic",
        }));
       

        const firstFloorText = {
            properties: {},
            children: [
                ...introText,
                ...ceilingText,
                ...wallsText,
                ...floorText,
                ...windowsText,
                ...dampText,
                
            ].filter(v => !!v),
        };

        return firstFloorText;
    }

    const groundFloor = async () => {
        const spacing = {
            before: 300,
        };
        const textConfig2 = {
            //color: "000000",
        };
        const includeWindows = !!data.filters.includes('windows');
        dampMeterReadings = data.sections['Ground Floor Rooms'].subsections['Damp'].buildingItems['Penetrating Damp'].defects['Wall surfaces damp meter readings?'].answer?.toLowerCase();

        const GRT1 = 'Ceiling finishes, e.g. plaster-board and skim, are nail fixed to the underside of the structural timber floor beams above. The condition of the ceiling finishes can provide an insight into the condition / performance of the timber floor beams (in addition to examination of the floor surface above). Certain defects present to the ceiling finishes could suggest structural issues with the timber beams. Typically, plaster ceiling cracks to ground floor rooms have two causes (i) movement / settlement of the property (ii) flexure of the first floor beam frameworks due to lack of stiffness, e.g noggins, resulting in sponginess / vibration of the floor when loaded. Ground floor ceilings will not tend to crack due to temperature and / or moisture fluctuations as there will be little difference in thermal gradient between the ground and first floor rooms (compared to the difference which occurs between the first floor rooms and the attic. / loft space. Clearly any cracks associated with building movement are more serious compared to those cracks caused by flexure of the floor beams above and will require different methods of repair to the building. Please see our findings below regards the condition of the ceiling finishes.'
        // // console.log('timber inspectable? : ',timberInspectable)
        const introText = [
            new Paragraph({
                text: '5(H). GROUND FLOOR ROOMS',
                heading: HeadingLevel.HEADING_1,
            }),
            includeGrt ? new Paragraph({
                text: GRT1,
                spacing,
                style: "basic",
            }) : null,
        ].filter(v => !!v);

        const ceilingData = JSON.parse(JSON.stringify(data.sections['Ground Floor Rooms'].subsections['Ceilings'].buildingItems));
        //const GRT2 = 'Ceiling finishes, e.g. plaster-board and skim, are nail fixed to the underside of the structural timber frameworks (horizontal beam members) within the roof void. The condition of the ceiling finishes can provide an insight into the condition / performance of the timber beams within the roof. Certain defects present to the ceiling finishes could suggest structural issues with the roof timber frameworks above. Typically, plaster ceiling cracks have two causes (i) movement / settlement of the property (ii) temperature and / or moisture fluctuations in the attic space, which result in the expansion or contraction of framing members and resultant cracking to ceilings. Clearly any cracks associated with building movement are more serious compared to those cracks caused by expsansion / contraction of the roof structure and will require different methods of repair to the building. Please see our findings below regards the condition of the ceiling finishes.'
        const ceilingText = Object.entries(ceilingData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        
        const wallsData = JSON.parse(JSON.stringify(data.sections['Ground Floor Rooms'].subsections['Internal Walls'].buildingItems));
        const GRT3 = 'An examination of the internal wall panels has been conducted to confirm the presence of any defects that may otherwise indicate potential structural issues with the load-bearing walls. Of particular importance is the presence of any structural cracks, wall panel misalignment / bowing or other deterioration that could impact the load-bearing capacity / strength of the walls. This check includes the internal face of all external (main) load-bearing peripheral walls and also internal (single skin) masonry partition walls where present. Internal stud-partition walls (where present) will not be load-bearing but have been included in our check for completeness.'
        const wallsText = Object.entries(wallsData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        if (includeGrt) {
            wallsText.unshift(new Paragraph({
                text: GRT3,
                spacing,
                style: "basic",
            }));
        }
            
        const floorData = JSON.parse(JSON.stringify(data.sections['Ground Floor Rooms'].subsections['Floors'].buildingItems));
        const floorText = Object.entries(floorData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);

        const windowsData = JSON.parse(JSON.stringify(data.sections['Ground Floor Rooms'].subsections['Windows'].buildingItems));
        const GRT4 = includeWindows ?
            'Window condition and alignment are checked as part of our survey for the purposes of assessing whether any building movement may have occurred and consequential \'skewing\' of window openings is present. We also check the conformance of the window frameworks to aid passive ventilation into the property to control and negate condensation build up. Our survey also includes the condition of window seals and condensation between glazing units, any broken / damaged glazing units and also the condition of the hinges / locking mechanism.'
            : 'Window condition and alignment are checked as part of our survey, but only for the purposes of assessing whether any building movement may have occurred and consequential \'skewing\' of window openings is present. We also check the conformance of the window frameworks to aid passive ventilation into the property to control and negate condensation build up. Our survey does not address the actual condition of the windows in terms of their acoustic or thermal performance nor the adequacy of hinges / locking mechanisms. Any defects to the windows that have not been caused by structural movement of the property or are not associated with passive ventilation are out of scope of this report.'
        //const GRT4 = 'Window condition and alignment are checked as part of our survey for the purposes of assessing whether any building movement may have occurred and consequential 'skewing' of window openings is present. We also check the conformance of the window frameworks to aid passive ventilation into the property to control and negate condensation build up. Our survey also includes the condition of window seals and condensation between glazing units, any broekn / damaged glazing units and also the condition of the hinges / locking mechanism.'
        const windowsText = Object.entries(windowsData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);

        if (includeGrt) {
            windowsText.unshift(new Paragraph({
                text: GRT4,
                spacing,
                style: "basic",
            }));
        }
        windowsText.unshift(new Paragraph({
            children: [
                new TextRun({
                    text: 'Windows / Ventilation',
                    underline: {type: "single"}
                }),
            ],
            //text: GRT4,
            spacing,
            style: "basic",
        }));

            
        const dampData = JSON.parse(JSON.stringify(data.sections['Ground Floor Rooms'].subsections['Damp'].buildingItems));
        const GRT5 = 'Our inspection has included a check on the presence of penetrating damp to ceiling and wall finishes. For ceilings that are supported via the floor beam frameworks above, any indication of penetrating damp to ceiling finishes would generally have developed via internal pipe leaks within the floor space. For load-bearing walls, penetrating damp can develop and damage internal wall finishes for different reasons. In solid wall construction, external rainwater can migrate through the full thickness of the wall due to the absence of a formal cavity (which would otherwise cut off any moisture migration). This is more of an issue for south-westerly facing wall panels that are open to the elements with no clear protection (e.g. from tree lines or neighbouring properties). For penetrating damp to occur through cavity wall constructions, there has to be a means where moisture can travel / bridge across the cavity and migrate to the internal walls face. This can develop for several reasons (i) the presence of cavity wall insulation (ii) wall ties installed upside down, so the drip formation does not work effectively, enabling moisture to migrate along the tie (iii) wall ties installed at an angle (iv) blockages in the cavity creating a bridge. Rainwater can also migrate into the top of the load-bearing walls if roofing felt is absent or does not extend into guttering as required by building regulations.'
        const dampText = Object.entries(dampData).map(item => formatBuildingItemOutput({item, spacing})).reduce((acc, val) => [...acc, ...val], []);
        if (includeGrt) {
            dampText.unshift(new Paragraph({
                text: GRT5,
                spacing,
                style: "basic",
            }));
        }
        dampText.unshift(new Paragraph({
            children: [
                new TextRun({
                    text: 'Damp',
                    underline: {type: "single"}
                }),
            ],
            //text: GRT4,
            spacing,
            style: "basic",
        }));
        console.log('damptext : ',dampText)

        const groundFloorText = {
            properties: {},
            children: [
                ...introText,
                ...ceilingText,
                ...wallsText,
                ...floorText,
                ...windowsText,
                ...dampText,
                
            ].filter(v => !!v),
        };

        return groundFloorText;
    }


    const conclusions = async () => {
        const content = [
            new Paragraph({
                text: '6. CONCLUSIONS',
                heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
                text: `Having completed a structural survey to ${address} and following inspection / assessment of the roof profile and structural timber frameworks, load-bearing wall condition and alignment, ground and suspended floors, foundation performance and assessment of damp in the property we conclude the following (N.B. based on a non-intrusive inspection): `,
                spacing,
                style: "basic",
            }),

        ].filter(v => !!v);


        if (!!level2Defects.length) {
            content.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'The Condition Rating ',
                        }),
                        new TextRun({
                            text: '2',
                            highlight: "yellow",
                        }),
                        new TextRun({
                            text: ' defects that will require remedial works to prevent further more problematic degradation occurring are:',
                        }),
                    ],
                    spacing,
                    style: "basic",
                }),
                ...formatConclusions(JSON.parse(JSON.stringify(level2Defects)), spacing)
            );
        }

        if (!!level3Defects.length) {
            content.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'The Condition Rating ',
                        }),
                        new TextRun({
                            text: '3',
                            highlight: "red",
                        }),
                        new TextRun({
                            text: ' defects which are considered to be more urgent and requiring remedial works in a more timely manner are:',
                        }),
                    ],
                    spacing,
                    style: "basic",
                }),
                ...formatConclusions(JSON.parse(JSON.stringify(level3Defects)), spacing)
            );
        }

        const conclusionText = {
            properties: {},
            children: [
                ...content,
            ]
        };
        return conclusionText;
    }

    const recommendations = async () => {
        const content = [
            new Paragraph({
                text: '7. RECOMMENDATIONS',
                heading: HeadingLevel.HEADING_1,
            }),
        ].filter(v => !!v);

        if (!!level2Defects.length) {
            content.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'The Condition Rating ',
                        }),
                        new TextRun({
                            text: '2',
                            highlight: "yellow",
                        }),
                        new TextRun({
                            text: ' defects that will require remedial works to prevent further more problematic degradation occurring are:',
                        }),
                    ],
                    spacing,
                    style: "basic",
                }),
                ...formatRecommendations(JSON.parse(JSON.stringify(level2Defects)), spacing)
            );
        }

        if (!!level3Defects.length) {
            content.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: 'The Condition Rating ',
                        }),
                        new TextRun({
                            text: '3',
                            highlight: "red",
                        }),
                        new TextRun({
                            text: ' defects which are considered to be more urgent and requiring remedial works in a more timely manner are:',
                        }),
                    ],
                    spacing,
                    style: "basic",
                }),
                ...formatRecommendations(JSON.parse(JSON.stringify(level3Defects)), spacing)
            );
        }

        const recommendationsText = {
            properties: {},
            children: content,
        };
        return recommendationsText;
    }

    const repairCosts = async () => {
        const text = [
            new Paragraph({
                text: '8. GUIDE TO REPAIR COSTS',
                heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
                text: 'This section details the 30 most common repairs. The only way to establish the cost of repairs accurately is to obtain estimates from reputable contractors. If you are buying the property, it is essential that you do so before exchanging contracts when you commit yourself to buying at the agreed price.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Prices for repairs vary for many reasons - there are historical variations; the prices of materials fluctuate; the size of the work required can make a job more or less attractive to a builder as can his current workload. No repair is identical to another. For all these reasons this is a best rough guide.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'The prices are drawn from figures collated and published by the Royal Institute of Chartered Surveyors in their BMI Building Maintenance Price Book. Prices are updated quarterly.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'The published figures are given by a unit - e.g. square metre, or per metre length and come from figures given by experienced estimators all over the country. Remember to add on call out charges.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'To help you visualise the sums involved, there are some typical costs which you could expect for a three bedroom semi detached house (all costs are approximate)',
                spacing,
                style: "basic",
            }),
        ];

        const threeColumnTableRow = (text1, text2, text3, col1Width, i) => {
            return new TableRow({
                height: {
                    value: 500,
                    rule: HeightRule.ATLEAST
                },
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: text1,
                                bold: (i === 0),
                                break: (i === 0) ? 2 : 0,
                            })],
                            style: "basic",
                        })],
                        width: {
                            size: col1Width,
                            type: WidthType.PERCENTAGE
                        },
                        borders: noBorders,
                        
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: text2,
                                bold: (i === 0),
                                break: (i === 0) ? 1 : 0,
                            })],
                            style: "basic",
                        })],
                        width: {
                            size: (100 - col1Width) / 2,
                            type: WidthType.PERCENTAGE
                        },
                        borders: noBorders
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: text3,
                                bold: (i === 0),
                                break: (i === 0) ? 1 : 0,
                            })],
                            style: "basic",
                        })],
                        width: {
                            size: (100 - col1Width) / 2,
                            type: WidthType.PERCENTAGE
                        },
                        borders: noBorders
                    }),
                ],
            })
        }

        const roofCosts = [
            ["Roof", 'Unit', 'Cost £'],
            ["Repair pitched roof - tiles", 'sq.m', '22.00'],
            ["                    - slates", 'sq.m', '31.00'],
            ["Replace ridge or hip tiles", 'per m', '30.00'],
            ["Refelt & batten using current tiles/slates", 'sq.m', '44.00'],
            ["Repair area of damaged felt (flat roof) ", 'sq.m', '26.00'],
            ["Repair flat roof including roof boarding", 'sq.m', '45.00'],
            ["Repair damaged chimney pot and repoint (900mm high)", 'each', '136.00'],
        ].map((text, i) => threeColumnTableRow(text[0], text[1], text[2], 60, i))

        const roofTable = new Table({
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: roofCosts,
        });

        const roofText = [
            new Paragraph({
                text: 'Sample : to strip and relay a tiled pitched roof with existing tiles, allow at least £2400',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Sample : to strip and relay a tiled pitched roof with new tiles, allow at least £4700',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Sample : to renew a small flat roof extension, allow at least £1200',
                spacing,
                style: "basic",
            }),
            // new Paragraph({
            //     text: 'N.B. No costs could be provided for the installation of steel straps to the wall plates and gable ends as this is not a common cost price assessed by the building industry. The author suggests a detailed quote is obtained to cater for the works as recommended in Section 7.',
            //     spacing,
            //     style: "basic",
            // }),
        ];

        const wallCosts = [
            ['Walls (masonry)', ' ', ' '],
            ["Repoint soft mortar in brickwork", 'sq.m', '12.00'],
            ["Repoint stonework", 'sq.m', '27.00'],
            ["Form new door openings in cavity wall", 'each', '312.00'],
            ["Form new window openings in cavity wall", 'each', '250.00'],
            ["Fill in window opening", 'each', '174.00'],
            ["Insert damp proof course (excludes replastering)", 'm', '49.00'],
            ["Provide air brick to cavity wall", 'each', '45.00'],
            ["Apply renovating plaster to internal walls ", 'sq.m', '21.00'],
            ["Apply cement/lime/sand render to external walls", 'sq.m', '19.00'],
            ["Repairing/renovating brick / blockwork - cutting out Decayed, defective or cracked work and replacing with New common bricks PC £240/1000 in gauged mortar (1:1:6) :", ' ', ' '],
            ["Half brick thick", 'sq.m', '126.00'],
            ["One brick thick", 'sq.m', '247.00'],
            ["One and a half brick thick", 'sq.m', '354.00'],
            ["Two brick thick", 'sq.m', '457.00'],
        ].map((text, i) => threeColumnTableRow(text[0], text[1], text[2], 60, i))

        const wallTable = new Table({
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: wallCosts,
        });

        const ceilingCosts = [
            ['Ceilings', ' ', ' '],
            ["Repair plaster cracks", 'per m', '5.00'],
            ["Fix 12.5mm plasterboard", 'sq.m', '28.00'],
            ["Plaster skim new plasterboard", 'sq.m', '6.00'],
           
        ].map((text, i) => threeColumnTableRow(text[0], text[1], text[2], 60, i))

        const ceilingTable = new Table({
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: ceilingCosts,
        });

        const ceilingText = [
            new Paragraph({
                text: 'Sample : renew a bedroom ceiling with plasterboard, allow at least £600',
                spacing,
                style: "basic",
            }),
        ];

        const floorCosts = [
            ['Floors', ' ', ' '],
            ["Renew floor with tongue and groove boards", 'sq.m', '43.00'],
            ["Renew floor with chipboard", 'sq.m', '20.00'],    
        ].map((text, i) => threeColumnTableRow(text[0], text[1], text[2], 60, i))

        const floorText = [
            new Paragraph({
                text: 'Sample : to treat floors against woodworm throughout, allow at least £1200',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Sample : to treat floors (including roof space) against woodworm throughout, allow at least £1,750',
                spacing,
                style: "basic",
            }),
        ];

        const floorTable = new Table({
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: floorCosts,
        });

        const repairCostsText = {
            properties: {},
            children: [
                ...text,
                roofTable,
                ...roofText,
                wallTable,
                ceilingTable,
                ...ceilingText,
                floorTable,
                ...floorText,
            ].filter(v => !!v),
        };

        return repairCostsText;
/*


*/
    }
    const whatToDoNext = async () => {
        const text = [
            new Paragraph({
                text: '9. WHAT TO DO NOW',
                heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
                children: [new TextRun({
                    text: 'Getting estimates',
                    bold: true,
                })],
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'The cost of repairs may influence the amount you are prepared to pay for the property. Before you make a legal commitment to buy the property, you should get obtain reports and estimates for all the repairs and further investigations the surveyor may have identified.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'You should obtain at least two estimates from experienced contractors who are properly insured.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'You should also:',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: '1. Ask them for references from people they have worked for',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: '2. Describe in writing exactly what you want them to do',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: '3. Get the contractors to put the estimates in writing',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Some repairs will need contractors with specialist skills and who are members of regulated organisations (for example electricians, gas engineers, plumbers and so on). Some work may also require you to obtain Building Regulation permission or Planning Permission from your local authority. You can obtain more advice on these from the Government website at www.direct.gov.uk/en/HomeandCommunity/Planning/Index.htm.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                children: [new TextRun({
                    text: 'Further investigations',
                    bold: true,
                })],
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'If the surveyor was concerned about the condition of a hidden part of the building they may recommend that further investigations should be carried out to discover the true extent of the problem.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                children: [new TextRun({
                    text: 'Who you should use for these further investigations',
                    bold: true,
                })],
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'You should ask a suitable qualified and experienced person (SQEP) to undertake the works. ',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                children: [new TextRun({
                    text: 'What will the further investigations involve?',
                    bold: true,
                })],
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'This will depend on the type of problem but, to do this properly, parts of the property may have to be disturbed. In some cases, the cost of the investigation may be high.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                children: [new TextRun({
                    text: 'When to do the work',
                    bold: true,
                })],
                spacing,
                style: "basic",
            }),
            new Table({
                rows: [
                    ['1.', 'Condition Rating 2'],
                    [' ', 'Repairs should be undertaken soon. Exactly when will depend on the type of problem but it usually does not have to be done straight away. Many repairs could wait weeks or months, giving you time to organise suitable reports and estimates.'],
                    ['2.', 'Condition Rating 3'],
                    [' ', 'Repairs should be undertaken as soon as possible. The speed of your response will depend on the nature of the problem. For example, repairs to a badly leaking roof need to be carried out within a matter of hours, while other less important critical repairs could wait for a few days.'],
                ].map((text, i) => twoColumnTableRow(text[0], text[1], 15)),
            }),
            new Paragraph({
                children: [new TextRun({
                    text: 'Warning',
                    bold: true,
                })],
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'Although repairs of elements with a Condition Rating 2 are not considered urgent, if they are not dealt with they may develop into more serious repairs. Flat roofs or gutters are typical examples. These can quickly get worse without warning and result in serious leaks.',
                spacing,
                style: "basic",
            }),
            new Paragraph({
                text: 'As a result, you should regularly check elements with a Condition Rating 2 to ensure they are not getting worse.',
                spacing,
                style: "basic",
            }),
        ];

        const whatToDoNextText = {
            properties: {},
            children: [
                ...text
            ].filter(v => !!v),
        };

        return whatToDoNextText;
    }

    const appendix = async () => {
        const diagram = await imageParagraph(houseDiagram, spacing, 600, 400, '');
        const text = [
            new Paragraph({
                text: 'Appendix A - Typical House Diagram',
                heading: HeadingLevel.HEADING_1,
            }),
            diagram
        ]

        const appendixContent = {
            properties: {},
            children: [
                ...text
            ]
        };
        console.log('d:',diagram)

        return appendixContent;
    }
    try {
        const tp = selectedSections.includes('Title page') ? await titlePage() : null;
        const toc = selectedSections.includes('Table of contents') ? await tableOfContents() : null;
        const guide = selectedSections.includes('Guide for clients') ? await guideForClients() : null;
        const er = selectedSections.includes('External roof') ? await externalRoof() : null;
        const ew = selectedSections.includes('External walls') ? await externalWalls() : null;
        const vent = selectedSections.includes('Ventilation') ? await ventilation() : null;
        const found = selectedSections.includes('Foundations') ? await foundations() : null;
        const damp = selectedSections.includes('Structural damp') ? await structuralDamp() : null;
        const rv = selectedSections.includes('Roof void') ? await roofVoid() : null;
        const ffr = selectedSections.includes('First floor rooms') ? await firstFloor() : null;
        const gfr = selectedSections.includes('Ground floor rooms') ? await groundFloor() : null;
        const es = selectedSections.includes('Executive summary') ? await executiveSummary() : null;
        const intro = selectedSections.includes('Introduction') ? await introduction() : null;
        const sd = selectedSections.includes('Survey details') ? await surveyDetails() : null;
        const pd = selectedSections.includes('Property details') ? await propertyDetails() : null;
        const conc = selectedSections.includes('Conclusions') ? await conclusions() : null;
        const rec = selectedSections.includes('Recommendations') ? await recommendations() : null;
        const repair = selectedSections.includes('Repair costs') ? await repairCosts() : null;
        const next = selectedSections.includes('What to do next') ? await whatToDoNext() : null;
        const appx = selectedSections.includes('Appendix') ? await appendix() : null;

        const doc = new Document({
            background: {
                //color: "ffffff",
            },
            styles: {
                paragraphStyles: [
                    {
                        id: "basic",
                        name: "basic",
                        basedOn: "Normal",
                        next: "Normal",
                        run: {
                            size: 24
                        },
                    },
                ]
            },
            sections: [
                selectedSections.includes('Title page') ? tp : null,
                selectedSections.includes('Table of contents') ? toc : null,
                selectedSections.includes('Executive summary') ? es : null,
                selectedSections.includes('Introduction') ? intro : null,
                selectedSections.includes('Survey details') ? sd : null,
                selectedSections.includes('Property details') ? pd : null,
                selectedSections.includes('Guide for clients') ? guide : null,
                selectedSections.includes('External roof') ? er : null,
                selectedSections.includes('External walls') ? ew : null,
                selectedSections.includes('Ventilation') ? vent : null,
                selectedSections.includes('Foundations') ? found : null,
                selectedSections.includes('Structural damp') ? damp : null,
                selectedSections.includes('Roof void') ? rv : null,
                selectedSections.includes('First floor rooms') ? ffr : null,
                selectedSections.includes('Ground floor rooms') ? gfr : null,
                selectedSections.includes('Conclusions') ? conc : null,
                selectedSections.includes('Recommendations') ? rec : null,
                selectedSections.includes('Repair costs') ? repair : null,
                selectedSections.includes('What to do next') ? next : null,
                selectedSections.includes('Appendix') ? appx : null,
                //await generateImageTests()
            ].filter(v => !!v),
        });
    
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${data.info.name}.docx`);
        });
    } catch (e) {
        window.alert('Failed to generate report: ' + e.message)
    }
    
}

    

export default ReportGenerator;