import { useContext } from 'react';
import { Navbar } from 'react-bootstrap';
import { Download, Save2, Save2Fill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/context';
import logo from '../images/ssuk-logo.svg';
import { saveAs } from 'file-saver';
const { Stack } = require("react-bootstrap");

const Header = ({name, completion}) => {
  const [state, dispatch] = useContext(AppContext);

  const handleSave = () => {
    const data = {...state};
    const dataToSave = JSON.stringify(data);
    const file = new File([dataToSave], "save.ssuk", {type: "application/json;charset=utf-8"});
    const fileName = data.info.name;
    const dateNow = new Date().toDateString().slice(4);
    saveAs(file, `SSUK - ${fileName} - ${dateNow}.ssuk`);
  }

  return (
    <Navbar fixed="top" className="p-3" collapseOnSelect expand="xs" bg="" variant="light">
      <Navbar.Brand href="#" as={Link} to="/surveys">
        <img className="ssuk-logo" src={logo} alt="SS-UK Logo" />
      </Navbar.Brand>
      <div className="d-flex align-items-center pe-1">
        <div className="d-flex flex-column align-items-end pe-2">
          {name && (
              <div className="survey-name">
                {name}
              </div>
          )}
          {(completion > -1) && (
            <div className="completion-indicator">
              {completion}%
            </div>
          )}
          {!name && (
            <div className="survey-name">
              v1.2
            </div>
          )}
        </div>
        {name && (
          <Download size={42} onClick={handleSave} className={"save-icon"}/>
        )}
      </div>
    </Navbar>
  );
};

export default Header;
