import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ContextProvider } from './context/context';
import Auth from './pages/auth';
import SurveyMain from './pages/survey-main';
import Surveys from './pages/surveys';

//import { useLocation } from 'react-router';
//import { history } from 'history';
// interface IMyProps {
//   data: string,
// }

const App = () => {
  // const [state, setState] = useState("Testing!");
  // const [view, setView] = useState("home");
  // const location = useLocation();
  const auth = window.localStorage.getItem("ssuk_auth_2") || null;
  // const [auth, setAuth] = useState(authDetails || false);

  return (
    <>
      <ContextProvider>
        <Routes>
          
          <Route index element={auth ? <Surveys /> : <Auth />} />
          <Route path="/auth" element={<Auth />} />
          {/* <Route path="/home" element={<Home view={view} info={state}/>} /> */}
          <Route path="/surveys" element={<Surveys />} />
          <Route path="/survey-main" element={<SurveyMain />} />
        </Routes>
      </ContextProvider>
    </>
  );
};

export default App;
