import '../index.css';
import React, { useRef } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal } from 'react-bootstrap';
import { Clipboard, Download, Save, Trash, Upload } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import { AppContext } from '../context/context';
import * as ss from '../service/storage.service';
import { saveAs } from 'file-saver';
import { mapExcelRowsToSurvey } from '../service/survey-mapper';

const Surveys = () => {
  const [state, dispatch] = useContext(AppContext);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [surveyName, setSurveyName] = useState(null);
  const [prefill, setPrefill] = useState('none');
  const [surveyToDelete, setSurveyToDelete] = useState({name: null, id: null});
  const [savedData, setSavedData] = useState(ss.getSavedSurveyInfo());
  const btnRef = useRef();
  const navigate = useNavigate();
  const auth = window.localStorage.getItem('ssuk_auth_2')


  useEffect(() => {
    if (!auth) {
      navigate("/auth");
    }
  }, []);

  const handleCloseNew = () => {
    setShowNewModal(false);
    setSurveyName(null);
  };

  const handleShowNew = () => setShowNewModal(true);

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setSurveyToDelete({name: null, id: null});
  }

  const handleShowDelete = () => setShowDeleteModal(true);

  const loadNew = () => {
    const freshData = mapExcelRowsToSurvey((prefill !== 'none'), prefill);
    // // console.log("payload", freshData);
    freshData.info.name = surveyName;
    dispatch({ type: "LOAD_SURVEY", payload: { data: freshData } });
    navigate("/survey-main");
  };

  const loadSaved = (id) => {
    const data = ss.loadSavedSurvey(id);
    if (data) {
      dispatch({ type: "LOAD_SURVEY", payload: { data } });
      navigate("/survey-main");
    }
  };

  const selectFile = async (type) => {
    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        let freader = new FileReader();
        freader.onload = () => resolve(freader.result);
        freader.readAsText(file);
    })}

    let input = document.createElement('input');
    input.type = 'file';
    input.accept = type;
    input.onchange = async _this => {
      let files = Array.from(input.files);
      const file = await readFile(files[0]);
      const data = JSON.parse(file);
      // // console.log(data);
      btnRef.current.blur();
      if (type === '.ssuk') {
        addFileToActive(data);
      } else {
        loadBackUp(data);
      }
    };
    input.onclose = _this => {
      btnRef.current.blur();
    }
    input.onabort = _this => {
      btnRef.current.blur();
    }
    input.click();
    btnRef.current.blur();
  }

  const saveBackup = () => {
    const data = ss.getFromLS('ssuk_state');
    const dataToSave = JSON.stringify(data);
    const file = new File([dataToSave], "save.json", {type: "application/json;charset=utf-8"});
    const fileName = `backup(${data.length} surveys)`;
    const dateNow = new Date().toDateString().slice(4);
    saveAs(file, `SSUK - ${fileName} - ${dateNow}.ssukbackup`);
    btnRef.current.blur();
  }

  const restoreFromBackup = async () => { // refactor with shared file input logic with loadSaved()
    if (savedData?.length > 3) {
      btnRef.current.blur();
      handleShowNew(); // need custom message/modal for loading backup
    } else {
      selectFile('.ssukbackup');
      // load state - add method to reducer
    }
  }

  const loadBackUp = (backUpState) => {
      ss.sendToLS('ssuk_state', backUpState);
      // // console.log('backup state:', backUpState);
      setSavedData(ss.getSavedSurveyInfo());
  }

  const loadFromFile = async () => {
    if (savedData?.length > 2) {
      btnRef.current.blur();
      handleShowNew();
    } else {
      selectFile('.ssuk');
      // add to active surveys
    }
  }

  const addFileToActive = (survey) => {
    // // console.log('add survey:', survey);
    ss.addSurveyFromFile(survey);
    setSavedData(ss.getSavedSurveyInfo());
    loadSaved(survey.info.id);
  }

  const deleteSaved = (name, id) => {
    // console.log('select delete survey: ', name, id)
    setSurveyToDelete({name, id});
    handleShowDelete();
  };

  const confirmDeleteSaved = () => {
    // console.log('survey to delete: ', surveyToDelete)
    ss.deleteSavedSurvey(surveyToDelete.id);
    setSavedData(ss.getSavedSurveyInfo());
    handleCloseDelete();
    // // console.log("savedData", savedData);
  };

  const handleName = (event) => {
    // // console.log('set name: ', event.target.value)
    if (event.target.name === 'prefill') {
      setPrefill(event.target.value);
    } else if (event.target.name === 'survey-name') {
      setSurveyName(event.target.value);
    }
  }

  return (
    <>
      <Modal show={showNewModal} onHide={handleCloseNew} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>{savedData?.length < 3 ? 'New survey' : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {savedData?.length < 3 && (
          <Form className="mb-5" onChange={handleName}>
            <Form.Label htmlFor="survey-name">Please enter a name for the survey</Form.Label>
            <Form.Control
              type="name"
              id="survey-name"
              name="survey-name"
              aria-describedby="survey-name"
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            />
            <div className='mt-5'></div>
            <Form.Label>Prefill rating questions</Form.Label>
            <Form.Control
              id={`prefill`}
              defaultValue={prefill}
              as="select"
              name={`prefill`}
            >
              {['none', 'Rating 1', 'Rating 2', 'Rating 3', 'Randomise'].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
        </Form.Control>
          </Form>
        )}
        {savedData?.length > 2 && (
          <>
            <p>You cannot have more than 3 active surveys.</p>
            <p>Please delete an existing survey before adding another.</p>
          </>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNew}>
            Close
          </Button>
          {savedData?.length < 3 && (
            <Button variant="primary" disabled={!surveyName} onClick={loadNew}>
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDelete} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Delete survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will permanently delete <b>{surveyToDelete.name}</b> and all data within it.</p>  
          <p>Are you sure you wish to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDeleteSaved}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Header />
      
      <div className="d-flex w-100 h-100 justify-content-center">
        <div className="d-flex w-100 justify-content-between mt-4 flex-column surveys-wrapper">
          <div className="d-flex align-items-center justify-content-center  w-100">
            <div className="d-flex justify-content-center flex-column surveys-list p-3 p-sm-5 pt-5 pb-5 mt-5">
              <h3 className="text-center">Active surveys</h3>

              {savedData?.length > 0 &&
                savedData.map((item, index) => {
                  return (
                    <div key={'wrap-' + index} className="d-flex  mt-4">
                      <Button
                        key={index}
                        variant="outline-primary"
                        onClick={() => loadSaved(item.id)}
                        className="w-100 me-3 d-flex survey-button"
                        style={{
                          background: `linear-gradient(90deg, #2125290d ${item?.completion}%, transparent ${item?.completion}%)`,
                        }}
                      >
                        <Container className="d-flex w-100  align-items-center justify-content-between">
                          <Col sm={6} className="text-start lh-sm">
                            {item.name}
                          </Col>
                          <Col sm={2} className="text-center">
                            {item.dateCreated
                              .slice(0, 10)
                              .split("-")
                              .reverse()
                              .join("/")}
                          </Col>
                          <Col sm={4} className="text-end">
                            {parseInt(item.completion)}%
                          </Col>
                        </Container>
                      </Button>
                    
                      <Button
                        key={'delete' + index}
                        variant="outline-primary"
                        onClick={() => deleteSaved(item.name, item.id)}
                        className="d-flex survey-button align-items-center justify-content-center"
                      >
                        <Trash size={25}/>
                      </Button>
                    </div>
                  );
                })
              }
              {!savedData?.length && (
                <div className="text-center mt-5 w-100">No active surveys found</div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center p-3 p-sm-5 mb-5 pb-5 pt-0 justify-content-center position-relative">
              <Button  onClick={() => loadFromFile()} variant="outline-primary" className="w-100 p-2">
                <Upload className="upload-icon" size={25}/>
                <b>Load saved survey</b>
              </Button>
              <div className="d-flex w-100 mt-4">
                <Button ref={btnRef}  onClick={() => saveBackup()} variant="outline-primary" className="w-100 small p-2 me-2">
                  <Save className="upload-icon" size={25}/>
                  <b>Backup active surveys</b>
                </Button>

                <Button onClick={() => restoreFromBackup()} variant="outline-primary" className="w-100 small p-2 ms-2">
                  <Save className="upload-icon" style={{'transform': "rotate(180deg)"}} size={25}/>
                  <b>Restore from backup</b>
                </Button>
              </div>
          </div>
          <div className="d-flex flex-column align-items-center p-3 p-sm-5 pt-5 mb-3 pb-5 justify-content-center position-relative">
              <Button onClick={() => handleShowNew()} className="w-100 p-2">
                <Clipboard className="upload-icon" size={25}/>
                <b>New survey</b>
              </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Surveys;
