import { saveAs } from 'file-saver';

export const getFromLS = (itemName) => {
    try {
        return JSON.parse(window.localStorage.getItem(itemName))
    } catch (e) {
        return null
    }
    
}

export const sendToLS = (itemName, item) => {
    // // console.log('setting ls: ', itemName, item)
    return window.localStorage.setItem(itemName, JSON.stringify(item))
}

export const loadSavedState = () => {
    return getFromLS('ssuk_state');
}

export const saveLog = (action) => {
    // // console.log('new log action: ', action)
    let logs = getFromLS('ssuk_logs');
    if (!logs) {
        logs = [];
    }
    // // console.log('logs total: ', logs?.length);
    // // console.log('logs from ls: ', logs);
    const now = new Date();
    logs.push({
        time: now,
        type: 'action',
        action
    });

    if (logs.length > 100) {
        logs.splice(0, logs.length - 100);
    }

    try {
        // // console.log('log length after new: ', logs.length)
        // // console.log('logs after new: ', logs)
        sendToLS('ssuk_logs', logs);
        // // console.log('check saved logs: ', getFromLS('ssuk_logs'))
    } catch (e) {
        logs.push(e);
        window.alert('Failed to save log: ' + e.message + ' - please save the error report to file.');
        saveFile(logs);
        //write error and logs to report file?
    }
}

export const loadSavedSurvey = (id) => {
    const savedSurveys = getFromLS('ssuk_state');
    // // console.log('saved surveys in loadSavedSurvey: ', savedSurveys)
    try {
        let surveyToLoad = savedSurveys.find(survey => survey.info.id === id);
        //// // console.log('loading survey: ', surveyToLoad)
        return surveyToLoad;
    } catch (e) {
        window.alert('Failed to load survey: ' + e.message);
        let logs = getFromLS('ssuk_logs');
        logs.push(e)
        saveFile(logs);
        return null;
    }
}

export const deleteSavedSurvey = (id) => {
    const savedSurveys = getFromLS('ssuk_state');
    try {
        // console.log('search for matching survey: ', savedSurveys);
        let indexToDelete = savedSurveys.findIndex(survey => survey.info.id === id);
        // console.log('matching survey index: ', indexToDelete)
        if (indexToDelete > -1) {
            savedSurveys.splice(indexToDelete, 1);
            // console.log('savedSurveys after: ', savedSurveys)
            // // // console.log('survey deleted, saving new state: ', savedSurveys)
            sendToLS('ssuk_state', savedSurveys)
        } else {
            throw new Error('Failed to sync with saved data');
        }
        
    } catch (e) {
        window.alert('Failed to delete survey: ' + e.message);
        let logs = getFromLS('ssuk_logs');
        logs.push(e)
        saveFile(logs);
    }
}

export const getSavedSurveyInfo = () => {
    const savedSurveys = getFromLS('ssuk_state');
    const info = [];
    if (savedSurveys?.length && savedSurveys[0].info) {
        savedSurveys.forEach(survey => info.push(survey.info));
    } 
    // // console.log('get saved survey info: ', info);
    return info;
}

export const saveSurvey = (activeSurvey) => {
    // insert validation for activeSurvey before saving, to avoid writing with bad data.
    try {
        let savedSurveys = getFromLS('ssuk_state') ?? [];
        const i = savedSurveys?.findIndex((survey) => survey.info.id === activeSurvey.info.id);
        if (i > -1) {
            savedSurveys[i] = activeSurvey;
        } else {
            savedSurveys.push(activeSurvey)
        }
        sendToLS('ssuk_state', savedSurveys);
    } catch (e) {
        window.alert('Failed to save survey: ' + e.message);
        let logs = getFromLS('ssuk_logs');
        logs.push(e)
        saveFile(logs);
    }
}

export const addSurveyFromFile = (survey) => {
    // // console.log('adding survey in service: ', survey)
    // insert validation for survey before saving, to avoid writing with bad data.
    try {
        let savedSurveys = getFromLS('ssuk_state') ?? [];
        savedSurveys.push(survey);
        sendToLS('ssuk_state', savedSurveys);
        return true
    } catch (e) {
        window.alert('Failed to add survey: ' + e.message);
        let logs = getFromLS('ssuk_logs');
        logs.push(e)
        saveFile(logs);
        return false
    }
}

const saveFile = (data) => {

    let file = new File([JSON.stringify(data)], `errorLogs${new Date()}.txt`, {type: "text/plain;charset=utf-8"});
    saveAs(file);
}